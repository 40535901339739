import './centered.scss'

import { Column, Grid } from 'layout/grid'
import { Bg } from 'layout/partials/bg'
import { Theme } from 'layout/theme'
import React from 'react'
import { ImageType, WysiwygType } from 'types/basictypes'
import { styler } from 'util/style'

const styles = styler('centered')

export type CenteredType = {
	background?: ImageType
	overlay?: number
	text: WysiwygType
	darkText?: boolean
}

export const Centered: React.FC<CenteredType> = data => {
	const { background, overlay, text, darkText = false } = data

	return (
		<div className={styles().mod({ background: background, darkText: darkText })}>
			{background && <Bg image={background} overlay={overlay || 50} />}
			<Theme.Container>
				<div className={styles('content')}>
					{text && <Theme.Wysiwyg mod={'inherit'}>{text}</Theme.Wysiwyg>}
				</div>
			</Theme.Container>{' '}
		</div>
	)
}
