import './producthero.scss'

import {Icon} from 'assets/icon'
import {BlocksContext} from 'blocks/blockscontext'
import {HeroBreadcrumbs, HeroTitle} from 'blocks/hero/hero'
import {useSubsiteForUrl} from 'blocks/hero/hero_graph_subsites'
import {ProductContext} from 'layout/pages/product/product'
import {BgFixed} from 'layout/partials/bg'
import {Image} from 'layout/partials/image'
import {Theme} from 'layout/theme'
import React, {useContext} from 'react'
import {ImageType, WysiwygType} from 'types/basictypes'
import {Link} from 'util/link'
import {styler} from 'util/style'

const styles = styler('producthero', ['hero', 'image', 'content', 'filters'])

export type ProductHeroType = {
	background?: ImageType
} & ProductHeroFiltersType

type ProductHeroFiltersType = {
	filters?: Array<{label?: string; value?: string}>
}

type ProductHeroImageType = {
	image: ImageType
}

type ProductHeroContentType = {
	title: string
	description?: WysiwygType
}

export const ProductHero: React.FC<ProductHeroType> = (data) => {
	const {background, filters} = data
	const nofilters = !filters || filters.length === 0

	return (
		<div className={styles()}>
			<ProductHeroHero background={background} nofilters={nofilters} />
			<ProductHeroFilters filters={filters} />
		</div>
	)
}

const ProductHeroHero: React.FC<{
	background?: ImageType
	nofilters?: boolean
}> = ({background, nofilters}) => {
	const {pageTitle, url} = useContext(BlocksContext)

	const hasBg = !!background
	const subsite = useSubsiteForUrl(url)

	const productContext = useContext(ProductContext)
	if (!productContext) return null
	const {description, image} = productContext

	return (
		<div
			className={styles
				.hero()
				.mod('producthero')
				.mod({image: hasBg})
				.mod({nofilters})}
		>
			{hasBg && <BgFixed image={background} />}
			{!subsite && <HeroBreadcrumbs pageTitle={pageTitle} />}
			<Theme.Container>
				<div className={styles('row')}>
					<ProductHeroImage image={image} />
					<ProductHeroContent title={pageTitle} description={description} />
				</div>
			</Theme.Container>
		</div>
	)
}

const ProductHeroImage: React.FC<ProductHeroImageType> = ({image}) => (
	<div className={styles.image()}>
		{image && (
			<div className={styles.image('container')}>
				<Image
					aspectRatio="free"
					image={{
						...image,
						objectFit: 'contain'
					}}
				/>
			</div>
		)}
	</div>
)

const ProductHeroContent: React.FC<ProductHeroContentType> = ({
	title,
	description
}) => {
	const {parents} = useContext(BlocksContext)
	const parent = parents[0]

	return (
		<div className={styles.content()}>
			<div className={styles.content('back')}>
				<Link to={parent?.url} className={styles.content('back-link')}>
					<div className={styles.content('back-link-icon')}>
						<Icon icon="ArrowLeft" />
					</div>
					<p className={styles.content('back-link-text')}>Show all</p>
				</Link>
			</div>
			<HeroTitle>{title}</HeroTitle>
			{description && (
				<div className={styles.content('description')}>
					<Theme.Wysiwyg>{description}</Theme.Wysiwyg>
				</div>
			)}
		</div>
	)
}

const ProductHeroFilters: React.FC<ProductHeroFiltersType> = ({filters}) => {
	if (!filters || filters.length === 0) return null
	if (filters[0] && !filters[0].label && !filters[0].value) return null

	return (
		<div className={styles.filters()}>
			<Theme.Container>
				<div className={styles.filters('row')}>
					{filters.map((filter, i) => (
						<div key={i} className={styles.filters('row-item')}>
							<p className={styles.filters('row-item-filter')}>
								{filter.label}
							</p>
							<p className={styles.filters('row-item-label')}>{filter.value}</p>
						</div>
					))}
				</div>
			</Theme.Container>
		</div>
	)
}
