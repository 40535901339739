import './list.scss'

import {graphql} from 'gatsby'
import {Theme} from 'layout/theme'
import React from 'react'
import {WysiwygType} from 'types/basictypes'
import {styler} from 'util/style'
import {Trusted} from 'util/trusted'

const styles = styler('list')

export type ListType = {
	title: WysiwygType
	items: Array<WysiwygType>
}

export const List: React.FC<ListType> = data => {
	const {title, items} = data
	if (!items || items.length === 0) return null

	return (
		<div className={styles()}>
			<Theme.Container>
				{title && (
					<Theme.H2 className={styles('title')} mod="blueline">
						{title}
					</Theme.H2>
				)}
				<div className={styles('items')}>
					{items.map((item, i) => (
						<div key={i} className={styles('items-item')}>
							<div className={styles('items-item-content')}>
								<span className={styles('items-item-content-checkmark')} />
								<span className={styles('items-item-content-text')}>
									<Theme.TrustedText>{item}</Theme.TrustedText>
								</span>
							</div>
						</div>
					))}
				</div>
			</Theme.Container>
		</div>
	)
}
