import './iframeblock.scss'

import {Theme} from 'layout/theme'
import React, {useState, useEffect} from 'react'
import {slugify} from 'util/slugify'
import {styler} from 'util/style'
import {Trusted} from 'util/trusted'
import {minWidth} from '@material-ui/system'
import {t} from 'util/i18'

const styles = styler('iframeblock')

export type IframeblockType = {
	title?: string
	fullwidth?: boolean
	aspectratio?: number
	embed_code: string
	minwidth?: number
}

export const IframeBlock: React.FC<IframeblockType> = data => {
	const {title, fullwidth, aspectratio, embed_code, minwidth} = data
	const [visible, setVisible] = useState(false)
	useEffect(() => {
		setVisible(!minWidth || window.innerWidth > minwidth)
	})

	const ratioStyle = {
		paddingBottom: aspectratio ? `${aspectratio}%` : `0`
	}

	if (!embed_code) return null
	if (!visible)
		return (
			<div className={styles()}>
				<Theme.Container>
					<p>{t('iframe.invisible')}</p>
				</Theme.Container>
			</div>
		)

	return (
		<div className={styles().mod({fullwidth})}>
			<Theme.Container>
				{title && (
					<Theme.H2
						id={slugify(title)}
						className={styles('title')}
						mod="blueline"
					>
						{title}
					</Theme.H2>
				)}
			</Theme.Container>
			{fullwidth ? (
				<div
					className={styles('iframecontainer').mod(
						aspectratio && 'aspectratio'
					)}
					style={ratioStyle}
				>
					<Trusted>{embed_code}</Trusted>
				</div>
			) : (
				<Theme.Container>
					<div className={styles('iframecontainer')} style={ratioStyle}>
						<Trusted>{embed_code}</Trusted>
					</div>
				</Theme.Container>
			)}
		</div>
	)
}
