export const nl = {
	read_more: 'Lees meer',
	agenda: {
		no_results: 'Er zijn momenteel geen evenementen gepland op onze agenda'
	},
	presslabel: 'Persbericht',
	iconproject: {
		read_more: 'Lees meer'
	},
	jobs: {
		periods: {
			past_day: 'Past day',
			past_3days: 'Past 3 days',
			past_week: 'Past week',
			past_month: 'Past month'
		},
		filters: {
			categories: 'Category',
			job_title: 'Title',
			region: 'Location',
			employment_type: 'Employment Types',
			employer: 'Employer',
			type: 'Position type',
			promotor: 'Promotor',
			csr: 'CSR-label',
			competence: 'Competence',
			marketsegments: 'Market segments',
			date_posted: 'Date Posted'
		}
	},
	readingroom: {
		searchbar: 'Zoek op onderwerp of thema',
		showmore: 'Onderwerpen',
		showless: 'Toon minder',
		readmore: 'TOON MEER',
		noResultsTitle: 'Jammer!',
		noResultsPart1: 'We vinden niets terug met de trefwoorden ',
		noResultsPart2:
			'. Probeer het gerust nog eens, bijvoorbeeld met een wat algemenere term. Of klik op ‘onderwerpen’ om te filteren per thema.',
		extraCategory: {
			name: 'Over Imec',
			items: [
				{
					type: 'tag' as const,
					name: 'Algemeen'
				},
				{
					type: 'pagetype' as const,
					pagetype: 'press'
				},
				{
					type: 'tag' as const,
					name: 'Partner gerelateerd'
				}
			]
		},
		textualTags: ['Partner gerelateerd'],
		types: {
			press: 'Persberichten',
			case: 'Cases'
		},
		popular: {title: 'Meest gelezen'}
	},
	header: {
		request: 'Vraag meer informatie aan',
		back: 'Terug naar overzicht'
	},
	footer: {
		follow: 'Volg imec op:',
		partof: 'is deel van'
	},
	article: {
		back: 'Naar Reading Room',
		short: 'In het kort',
		readmore: 'Lees meer'
	},
	press: {
		readmore: 'Lees meer'
	},
	sidebar: {
		tags: 'Meer over de volgende onderwerpen',
		date: 'Gepubliceerd op'
	},
	form: {
		firstname: 'Voornaam',
		lastname: 'Achternaam',
		email: 'E-mailadres',
		phone: 'Telefoonnummer',

		company: 'Bedrijf/organisatie',
		address: 'Adres bedrijf',
		zipcode: 'Zip code',
		city: 'Stad',

		message: 'Uw bericht',
		submit: 'Verstuur',
		thanks: {
			title: 'Bedankt!',
			text: 'Het formulier werd succesvol verzonden.'
		}
	},
	request: {
		title: 'Hoe kunnen we u helpen?',
		identification: 'Stuur ons uw vraag',
		topics: 'Ik ben op zoek naar',
		contact: 'Neem contact met ons op'
	},
	iconoverview: {
		startyear: 'Beginjaar',
		market: 'Markt',
		projectType: 'Research project type',
		status: 'status',
		readmore: 'lees meer'
	},
	filters: {
		clear: 'verwijder filters'
	},
	downloads: {
		title: 'Download',
		text: 'Download file'
	},
	iframe: {
		invisible:
			'Deze content is enkel zichtbaar op de desktop versie van de website.'
	},
	researchproject: {
		about: 'Over het project'
	},
	job: {
		apply: 'Apply'
	},
	words: {
		show_all: 'Toon alle'
	}
}
