import { ProductsSearchModel } from 'blocks/products/models/ProductsSearchModel'
import { ProductsTermKey } from 'blocks/products/models/ProductsTermModel'
import memoizeGetters from 'memoize-getters'
import { ImageType, WysiwygType } from 'types/basictypes'
import { getImageFromField } from 'util/drupal'

export type ProductModelState = {
	title: string,
	url: string,
	description: WysiwygType,
	id: number,
	filters: Array<number>,
	field_product_image: any
}

export class ProductModel {

	constructor(readonly search: ProductsSearchModel, private state: ProductModelState) { }

	get title(): string {
		return this.state.title
	}

	get url(): string {
		return this.state.url
	}

	get image(): ImageType {
		return getImageFromField(this.state.field_product_image)
	}

	get description(): WysiwygType {
		return this.state.description
	}

	get termsSet(): Set<ProductsTermKey> {
		return new Set(this.state.filters.map(id => `${id}`))
	}

	get isValid(): boolean {
		if (this.search.activeFilters.length === 0) return true
		return !this.search.activeFilters.find(filter => !filter.validateProduct(this))
	}
}
memoizeGetters(ProductModel)