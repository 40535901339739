import {Link as InternalLink} from 'gatsby'
import scrollTo from 'gatsby-plugin-smoothscroll'
import React, {forwardRef, HTMLProps, Ref} from 'react'
import parse from 'url-parse'
import {RedirectsMapping, useRedirects} from 'util/redirects'
import {ClassName} from './style'
import {useLocation} from './uselocation'

export type LinkProps = {to?: string} & Omit<
	| HTMLProps<HTMLAnchorElement>
	| ({as: 'button'} & HTMLProps<HTMLButtonElement>),
	'className'
> & {
		className?: ClassName | string
	}

export const checkIsExternal = (hostname, locationHostname) => {
	return hostname !== locationHostname
}

export const checkIsFile = (pathname) => {
	return pathname.split('/').pop().includes('.')
}

export const processInternalLink = (
	redirects: RedirectsMapping,
	href: string
) => {
	let result = href

	if (!href.startsWith('/en') && !href.startsWith('/nl') && !href.startsWith('#') && !href.startsWith('/drupal') && !href.startsWith('/sites')) {
		result = `/${process.env.GATSBY_DRUPAL_LANG}${href}`
	}

	for (let i = 0; i < 5; i++) {
		if (!redirects[result]) break
		result = redirects[result]
	}

	return result
}

export const Link: React.FC<LinkProps> = forwardRef(
	(props, ref: Ref<HTMLAnchorElement>) => {
		const {
			location: {hostname}
		} = useLocation()
		const redirects = useRedirects()

		const {children, as, to, ...rest} = props
		const fullHref = !to || typeof to === 'string' ? to : (to as any).url
		const parsed = parse(fullHref)
		const isExternal = checkIsExternal(parsed.hostname, hostname)
		let href = isExternal
			? fullHref
			: processInternalLink(
					redirects,
					parsed.pathname + parsed.query + parsed.hash
			  )
		const isFile = checkIsFile(parsed.pathname)
		const isAnchor =
			href && (href.startsWith('internal:#') || href.startsWith('#'))
		const Tag: any =
			as ||
			(href && to
				? isFile || isExternal || isAnchor
					? 'a'
					: InternalLink
				: 'a')

		let attrs: any = {}

		if (isAnchor) {
			attrs = {
				onClick: () => {
					scrollTo(to ? to.replace('internal:', '') : null)
				}
			}
		} else if (isFile || isExternal) {
			if (href) {
				attrs = {
					href,
					ref,
					target: '_blank',
					rel: isExternal ? 'external nofollow noopener' : null
				}
			}
		} else {
			attrs = {to: href, innerref: ref, activeclassname: 'is-active'}
		}

		return (
			<Tag {...attrs} {...rest}>
				{children}
			</Tag>
		)
	}
)
