import './faq.scss'

import {Icon} from 'assets/icon'
import {Theme} from 'layout/theme'
import {useState} from 'react'
import AnimateHeight from 'react-animate-height'
import {WysiwygType} from 'types/basictypes'
import {slugify} from 'util/slugify'
import {styler} from 'util/style'

const styles = styler('faq', ['item'])

export type FaqType = {
	title?: string
	items: Array<FaqItemType>
}

type FaqItemType = {
	label: WysiwygType
	description: WysiwygType
}

export const Faq: React.FC<FaqType> = (data) => {
	const {title, items} = data
	if (!items || items.length === 0) return null

	return (
		<div className={styles()}>
			<Theme.Container>
				{title && (
					<Theme.H2
						className={styles('title')}
						mod={['blueline']}
						id={slugify(title)}
					>
						{title}
					</Theme.H2>
				)}
				<div className={styles('items')}>
					{items.map((item, i) => (
						<FaqItem {...item} key={i} />
					))}
				</div>
			</Theme.Container>
		</div>
	)
}

const FaqItem: React.FC<FaqItemType> = ({label, description}) => {
	const [isOpen, setOpen] = useState(true)
	if (!label) return null

	return (
		<div className={styles.item()}>
			<a onClick={() => setOpen(!isOpen)} className={styles.item('question')}>
				<div className={styles.item('question-text')}>
					<Theme.Wysiwyg>{label}</Theme.Wysiwyg>
				</div>
				<div className={styles.item('question-icon').is({open: isOpen})}>
					<Icon icon="ChevronRight" />
				</div>
			</a>
			{description && (
				<AnimateHeight height={isOpen ? 'auto' : 0}>
					<div className={styles.item('answer')}>
						<Theme.Wysiwyg>{description}</Theme.Wysiwyg>
					</div>
				</AnimateHeight>
			)}
		</div>
	)
}
