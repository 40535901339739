import './overview.scss'

import {Column, Grid} from 'layout/grid'
import {Cardpreview} from 'layout/preview/cardpreview'
import {Textpreview} from 'layout/preview/textpreview'
import {Theme} from 'layout/theme'
import React from 'react'
import {ImageType, LinkType, WysiwygType} from 'types/basictypes'
import {Mod, styler} from 'util/style'

const styles = styler('overview')

export type OverviewType = {
	title?: string
	bgwhite?: boolean
	columns?: number
	items: Array<OverviewItemType>
} & OverviewDisplaysType

type OverviewDisplaysType = {
	display_square?: boolean
	display_textual?: boolean
	display_padding?: boolean
}

export type OverviewItemType = {
	link?: LinkType
	image?: ImageType
	text?: WysiwygType
}

export const Overview: React.FC<OverviewType> = (data) => {
	const {
		title,
		bgwhite = false,
		columns = 3,
		items,
		display_square,
		display_textual = false,
		display_padding = false
	} = data
	if (!items || items.length === 0) return null

	return (
		<div className={styles().mod({bgwhite}).mod(`columns${columns}`)}>
			<Theme.Container>
				{title && (
					<div className={styles('title')}>
						<Theme.H2 mod={['blueline']}>{title}</Theme.H2>
					</div>
				)}
				<Grid
					columns={1}
					xs={2}
					s={2}
					{...OverviewColumns({columns})}
					gap={[30, 75]}
				>
					{items.map((item, i) => (
						<Column key={i}>
							<OverviewItem
								columns={columns}
								item={item}
								display_square={display_square}
								display_textual={display_textual}
								display_padding={display_padding}
							/>
						</Column>
					))}
				</Grid>
			</Theme.Container>
		</div>
	)
}

const OverviewColumns = ({columns}) => {
	switch (true) {
		case columns === 2:
			return {m: 2, l: 2}
		case columns === 3:
			return {m: 2, l: 3}
		default:
			return {m: 2, l: 4}
	}
}

const OverviewItem: React.FC<
	{
		columns?: number
		item: OverviewItemType
	} & OverviewDisplaysType
> = ({columns, item, display_square, display_textual, display_padding}) => {
	switch (true) {
		case display_textual:
			return <Textpreview text={item.text} link={item.link} />
		default:
			return (
				<Cardpreview
					url={item.link?.url}
					image={item.image}
					text={item.text}
					text_mod={columns === 4 && ['small', 'full']}
					display_square={display_square}
					display_padding={display_padding}
				/>
			)
	}
}
