import './calendar.scss'

import {Link} from 'gatsby'
import {BgFixed} from 'layout/partials/bg'
import {Theme} from 'layout/theme'
import React from 'react'
import {ImageType} from 'types/basictypes'
import {styler} from 'util/style'
import {useStaticCalendarQuery} from 'blocks/calendar/calendar_static_items'
import {useLinks} from 'util/links'

const styles = styler('calendar', ['highlighted', 'events'])

export type CalendarHighlightedType = {
	highlighted_event: CalendarEventType
	highlighted_image: ImageType
}

export type CalendarEventType = {
	title: string
	date: string
	endDate?: string
	url: string
}

export type CalendarType = {
	title: string
	highlighted: CalendarEventType
	highlighted_image: ImageType
}

export const Calendar: React.FC<CalendarType> = data => {
	const {highlighted, highlighted_image, title} = data
	let events = useStaticCalendarQuery()

	if (highlighted) events = events.splice(0, 3)

	const {calendar} = useLinks()

	return (
		<div
			className={styles().mod({
				no_highlight: !highlighted
			})}
		>
			<Theme.Container>
				<Link to={calendar.url}>
					<Theme.H2 mod={['blueline', 'arrow']}>
						{title || calendar.title}
					</Theme.H2>
				</Link>

				<div className={styles('block')}>
					<CalendarHighlighted
						highlighted_event={highlighted}
						highlighted_image={highlighted_image}
					/>
					<CalendarEvents events={events} />
				</div>
			</Theme.Container>
		</div>
	)
}

export const CalendarHighlighted: React.FC<CalendarHighlightedType> = data => {
	const {highlighted_event, highlighted_image} = data
	if (!highlighted_event) return null

	return (
		<Link to={highlighted_event.url} className={styles.highlighted()}>
			<div className={styles.highlighted('content')}>
				<Theme.H5 className={styles.highlighted('content-date')}>
					{highlighted_event.date}
				</Theme.H5>
				<Theme.H4 mod="light">{highlighted_event.title}</Theme.H4>
			</div>
			<div className={styles.highlighted('image')}>
				<div className={styles.highlighted('image-imgcontainer')}>
					<BgFixed
						image={highlighted_image}
						hideOverlay={true}
						className={styles.highlighted('image-imgcontainer-bg')}
					/>
				</div>
			</div>
		</Link>
	)
}

export const CalendarEvents: React.FC<{
	events: Array<CalendarEventType>
}> = data => {
	const {events} = data
	if (!events || events.length === 0) return null

	return (
		<div className={styles.events()}>
			{events.map((event, i) => (
				<Link key={i} to={event.url || ''} className={styles.events('item')}>
					{event.date && (
						<div className={styles.events('item-date')}>{event.date}</div>
					)}
					{event.endDate && (
						<div className={styles.events('item-date-end')}>
							{' '}
							- {event.endDate}
						</div>
					)}
					<p className={styles.events('item-title')}>{event.title}</p>
				</Link>
			))}
		</div>
	)
}
