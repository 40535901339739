import './specifications.scss'

import {Theme} from 'layout/theme'
import {useState} from 'react'
import AnimateHeight from 'react-animate-height'
import {WysiwygType} from 'types/basictypes'
import {styler} from 'util/style'

const styles = styler('specifications', ['item'])

export type SpecificationsType = {
	items: Array<SpecificationsItemType>
}

type SpecificationsItemType = {
	label: string
	value: WysiwygType
}

export const Specifications: React.FC<SpecificationsType> = (data) => {
	const {items} = data
	const title = 'Specifications'
	const [isOpen, setOpen] = useState(true)
	if (!items || items.length === 0) return null

	const firstHalft = items.slice(
		0,
		items.length === 1 ? 1 : Math.floor(items.length / 2)
	)
	const secondHalf =
		items.length === 1
			? []
			: items.slice(Math.floor(items.length / 2), items.length)

	return (
		<div className={styles()}>
			<Theme.Container>
				{title && (
					<div className={styles('title')}>
						<a onClick={() => setOpen(!isOpen)}>
							<Theme.H2
								className={styles('title-text').is({open: isOpen})}
								mod={['blueline', 'toggle']}
							>
								{title}
							</Theme.H2>
						</a>
					</div>
				)}
				<AnimateHeight height={isOpen ? 'auto' : 0}>
					<div className={styles('items')}>
						<div className={styles('items-col')}>
							{firstHalft.map((item) => (
								<SpecificationsItem key={item.label} {...item} />
							))}
						</div>
						{secondHalf && secondHalf.length !== 0 && (
							<div className={styles('items-col')}>
								{secondHalf.map((item) => (
									<SpecificationsItem key={item.label} {...item} />
								))}
							</div>
						)}
					</div>
				</AnimateHeight>
			</Theme.Container>
		</div>
	)
}

const SpecificationsItem: React.FC<SpecificationsItemType> = ({
	label,
	value
}) => {
	if (!label && !value) return null

	return (
		<div className={styles.item()}>
			<div className={styles.item('row')}>
				<div className={styles.item('label')}>{label && <p>{label}</p>}</div>
				<div className={styles.item('value')}>
					{value && <Theme.Wysiwyg>{value}</Theme.Wysiwyg>}
				</div>
			</div>
		</div>
	)
}
