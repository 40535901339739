import './request.scss'

import {Icon} from 'assets/icon'
import {FieldAttributes, Form, Formik, useField} from 'formik'
import {Column, Grid} from 'layout/grid'
import {Theme} from 'layout/theme'
import React from 'react'
import {t} from 'util/i18'
import {useLinks} from 'util/links'
import {styler} from 'util/style'
import {useLocation, useUrlParams} from 'util/uselocation'
import {RequestStaticData, useStaticRequestQuery} from './request_graph'

const styles = styler('request', ['form', 'select'])

export type RequestType = {}

type RequestFormData = {
	identification: string
	topic: string
}

export const Request: React.FC<RequestType> = () => {
	const {contact} = useLinks()
	const {
		params: {identification, topic},
		createUrl
	} = useUrlParams()
	const {navigate} = useLocation()

	return (
		<div className={styles()}>
			<Theme.Container>
				<h3 className={styles('title')}>{t('request.title')}</h3>
				<Formik<RequestFormData>
					initialValues={{
						identification: '',
						topic: ''
					}}
					onSubmit={(values) => {
						const url = createUrl(values, contact?.url)
						navigate(url)
					}}
				>
					<Form>
						<RequestForm />
						<div className={styles('contact')}>
							<button className={styles('contact-link')} type="submit">
								<p className={styles('contact-link-text')}>
									{t('request.contact')}
								</p>
								<div className={styles('contact-link-circle')}>
									<span className={styles('contact-link-circle-icon')}>
										<Icon icon="ArrowRight" />
									</span>
								</div>
							</button>
						</div>
					</Form>
				</Formik>
			</Theme.Container>
		</div>
	)
}

export const RequestForm: React.FC = () => {
	const data: RequestStaticData = useStaticRequestQuery()

	return (
		<div className={styles.form()}>
			<Grid columns={1} s={1} m={2} l={2} align="top">
				<Column className={styles('identification')}>
					<p className={styles('identification-title')}>
						{t('request.identification')}
					</p>
					<RequestSelect options={data.identifications} name="identification" />
				</Column>
				<Column className={styles('topic')}>
					<p className={styles('topic-title')}>{t('request.topics')}</p>
					<RequestSelect options={data.topics} name="topic" />
				</Column>
			</Grid>
		</div>
	)
}

export const RequestSelect: React.FC<{
	options: Array<string>
	name: string
}> = ({options, name}) => {
	const [field] = useField({
		name,
		type: 'select',
		required: true
	} as FieldAttributes<any>)

	return (
		<select className={styles.select()} {...field} required>
			<option />
			{options.map((item, i) => (
				<option value={item} key={i}>
					{item}
				</option>
			))}
		</select>
	)
}
