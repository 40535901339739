import './searchresults.scss'
import React from 'react'
import {Theme} from 'layout/theme'
import {t} from 'util/i18'
import {useReadingroomSearchParams} from 'blocks/readingroom/readingroom'
import {styler} from 'util/style'
import {Loader} from 'layout/loader'

const styles = styler('searchresults', ['empty', 'loader', 'more'])

export const SearchresultsEmpty: React.FC = () => {
  const { params: { query } } = useReadingroomSearchParams()

  return (
    <div className={styles.empty()}>
      <Theme.Container>
        <Theme.H3 className={styles.empty('title')}>
          {t('readingroom.noResultsTitle')}
        </Theme.H3>
        <p className={styles.empty('text')}>
          {t('readingroom.noResultsPart1')} <b>{query}</b>
          {t('readingroom.noResultsPart2')}
        </p>
      </Theme.Container>
    </div>
  )
}

export const SearchresultsLoader: React.FC = () => {

  return (
    <div className={styles.loader()}>
      <Loader />
    </div>
  )
}

export const SearchresultsMore: React.FC<{
  onClick: () => void
}> = ({ onClick, children }) => {
  return (
    <div className={styles.more()}>
      <div className={styles.more('button')} onClick={onClick}>
        {children || t('readingroom.readmore')}
      </div>
    </div>
  )
}
