import './headernav.scss'

import {Icon} from 'assets/icon'
import {Column, Grid} from 'layout/grid'
import {Theme} from 'layout/theme'
import React from 'react'
import {Link} from 'util/link'
import {styler} from 'util/style'
import {HeaderLinkType} from './header'

const styles = styler('headernav', ['dropdown'])

export const HeaderNav: React.FC<{
	links: Array<HeaderLinkType>
	setHoveringItem: (item: HeaderLinkType) => void
}> = ({links, setHoveringItem}) => {
	if (!links || links.length === 0) return null

	return (
		<nav className={styles()}>
			{links
				.filter(link => !!link)
				.map((link, i) => (
					<div
						key={i}
						className={styles('item')}
						onMouseOver={() => setHoveringItem(link)}
					>
						<Link className={styles('item-link')} to={link.url}>
							<span className={styles('item-link-text')}>{link.title}</span>
							{link.children && link.children.length !== 0 && (
								<span className={styles('item-link-icon')}>
									<Icon icon="ChevronDown" />
								</span>
							)}
						</Link>
					</div>
				))}
		</nav>
	)
}

export const HeaderNavDropdown: React.FC<{
	item: HeaderLinkType,
	isOpen: boolean
}> = ({item: {url, title, children}, isOpen}) => {
	if (!children || children.length == 0) return null
	return (
		<div className={styles.dropdown.is({open: isOpen})} id={title}>
			<Theme.OuterContainer>
				<Theme.Container>
					<Grid s={1} m={2} columns={3} gap={[30, 30]}>
						{children.map((child, i) => (
							<Column key={i}>
								<div className={styles.dropdown('item')}>
									<Link className={styles.dropdown('item-link')} to={child.url}>
										<span className={styles.dropdown('item-link-text')}>
											{child.title}
										</span>
										<span className={styles.dropdown('item-link-icon')}>
											<Icon icon="ArrowRight" />
										</span>
									</Link>
									{child && child.description && (
										<div className={styles.dropdown('item-description')}>
											{child.description}
										</div>
									)}
								</div>
							</Column>
						))}
					</Grid>
				</Theme.Container>
			</Theme.OuterContainer>
		</div>
	)
}
