import {JobFilterKey, JobFilterModel, JobFilterState, jobRegularFilterKeys} from 'blocks/jobs/models/JobFilterModel'
import {JobModel, JobState} from 'blocks/jobs/models/JobModel'
import memoizeGetters from 'memoize-getters'
import {JobTermModel} from 'blocks/jobs/models/JobTermModel'
import {DatePostedFilterModel} from 'blocks/jobs/models/filters/DatePostedFilterModel'
import {TaxonomyFilterModel, RegularJobTermState} from 'blocks/jobs/models/filters/TaxonomyFilterModel'
import {JobsData} from 'blocks/jobs/jobs'
import {SearchParams} from 'util/uselocation'

export type JobsQueryState = {
	category: JobsCategory
	query: string
	terms: Array<string>
	filters: Record<JobFilterKey, RegularJobTermState[]>
	jobs: Array<JobState>
	bookmarked: Array<number>
}

export type JobsCategory = 'career' | 'academic' | 'bookmarked'

export class JobsSearchModel {
	state: JobsQueryState

	constructor(state: JobsQueryState) {
		this.state = state
	}

	static createFromParams(data: JobsData, params: SearchParams){
		return new JobsSearchModel({
			category: params.type as JobsCategory,
			query: params.query,
			terms: params.filters,
			filters: data.types,
			jobs: data.items,
			bookmarked: JSON.parse(localStorage.bookmarked_jobs || '[]')
		})
	}

	get query(): string {
		return this.state.query
	}

	get category(): JobsCategory {
		if(!this.state.category) return 'career'
		return this.state.category
	}

	get numberOfSelectedTerms(): number {
		return this.selectedTerms.length
	}

	get selectedTerms(): Array<JobTermModel> {
		return this.terms.filter(tag => tag.selected)
	}

	get terms(): Array<JobTermModel> {
		let result = []
		this.filters.forEach(filter => {
			result = [...result, ...filter.terms]
		})
		return result
	}

	get bookmarked(): Array<JobModel> {
		return this.allJobs.filter(job => job.isBookmarked)
	}

	get filters(): Array<JobFilterModel> {
		switch (this.category) {
			case 'career':
				const careerKeys: JobFilterKey[] = ['type', 'job_title', 'region', 'date_posted', 'employment_type', 'employer']
				return careerKeys.map(key =>  this.allFilters.find(filter => filter.key === key))

			case 'academic':
				const academicKeys: JobFilterKey[] = ['employment_type', 'categories', 'region', 'date_posted', 'csr', 'promotor', 'competence']
				return academicKeys.map(key =>  this.allFilters.find(filter => filter.key === key))

			case 'bookmarked':
				return []

			default:
				console.warn(`Unknown category: ${this.category}`)
				return []
		}
	}

	get allRegularFilters(): Array<JobFilterModel> {
		return jobRegularFilterKeys.map(key => {
			const state: JobFilterState = {key, terms: this.state.filters[key]}
			return new TaxonomyFilterModel(this, state)
		})
	}

	get allFilters(): Array<JobFilterModel> {
		return [
			...this.allRegularFilters,
			new DatePostedFilterModel(this)
		]
	}

	get jobs(): Array<JobModel> {
		if (this.category === 'bookmarked') return this.bookmarked
		return this.allJobs.filter(job => job.isValid)
	}

	get allJobs(): Array<JobModel> {
		return this.state.jobs.map(state => new JobModel(this, state))
	}

	// ----- Update state ----- //
	update(newState: Partial<JobsQueryState>): JobsSearchModel {
		return new JobsSearchModel({
			...this.state,
			...newState
		})
	}

	clear() {
		return this.update({
			terms: []
		})
	}

	setCategory(category: JobsCategory) {
		return this.update({
			category
		})
	}

	setQuery(query: string) {
		return this.update({
			query
		})
	}
}
memoizeGetters(JobsSearchModel)
