import fetch from 'unfetch'
import useSWR from 'swr/esm/use-swr'
import { useState } from 'react'

export const fetcher = url => {
  if (!url) return null
  return fetch(url).then(r => r.json())
}

export const useFetcher = url => {
  const [prevData, setPrevData] = useState()
  const { data } = useSWR(url, fetcher, {
    onSuccess: data => setPrevData(data)
  })
  return {
    data: data || prevData,
    isLoading: !data
  }
}
