import './footernav.scss'

import {
	FootercategoriesType,
	FooterlinkType
} from 'layout/partials/footer/footer'
import {Theme} from 'layout/theme'
import React from 'react'
import {LinkType} from 'types/basictypes'
import {Link} from 'util/link'
import {styler} from 'util/style'

const styles = styler('footernav', ['categories', 'links', 'link', 'other'])

export const Footernav: React.FC<{
	footerCategories: FootercategoriesType
	footerMenu: FooterlinkType
}> = (data) => {
	const {footerCategories, footerMenu} = data

	return (
		<div className={styles()}>
			<div className={styles('row')}>
				<FooternavCategories {...footerCategories} />
				<FooternavOther {...footerMenu} />
			</div>
		</div>
	)
}

export const FooternavCategories: React.FC<FootercategoriesType> = ({
	categories
}) => {
	if (!categories || categories.length === 0) return null

	return (
		<div className={styles.categories()}>
			{categories.map((category, i) => {
				if (!category.title) return null
				return (
					<div key={i} className={styles.categories('item')}>
						<Theme.H4 className={styles.categories('item-title')} mod="medium">
							<Link to={category.titleUrl}>{category.title}</Link>
						</Theme.H4>
						{category.subtitle && (
							<p className={styles.categories('item-description')}>
								{category.subtitle}
							</p>
						)}
						<FooternavLinks links={category.links} />
					</div>
				)
			})}
		</div>
	)
}

export const FooternavOther: React.FC<FooterlinkType> = ({links}) => {
	return (
		<div className={styles.other()}>
			<Theme.H5 className={styles.other('item-title')}>Meer imec</Theme.H5>
			<FooternavLinks links={links} />
		</div>
	)
}

export const FooternavLinks: React.FC<{links: Array<LinkType>}> = ({links}) => {
	if (!links || links.length === 0) return null

	return (
		<div className={styles.links()}>
			{links.map((link, i) => (
				<div key={i} className={styles.links('item')}>
					<FooternavLink key={i} url={link.url} title={link.title} />
				</div>
			))}
		</div>
	)
}

export const FooternavLink: React.FC<{
	url: string
	title: string
}> = ({url, title}) => {
	if (!url || !title) return null

	return (
		<Link to={url} className={styles.link()}>
			{title}
		</Link>
	)
}
