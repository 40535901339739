import './imagepreview.scss'
import {Image} from 'layout/partials/image'
import {Tags} from 'layout/partials/tags'
import {Theme} from 'layout/theme'
import React from 'react'
import {Link} from 'util/link'
import {styler} from 'util/style'
import {PreviewType} from 'layout/preview/previewtype'

const styles = styler('imagepreview')

export const Imagepreview: React.FC<PreviewType> = data => {
	const {url, portrait_image, label, tags, title, date} = data

	return (
		<div className={styles()}>
			<div className={styles('imgcontainer')}>
				<Link to={url ? url : ''} className={styles('imgcontainer-image')}>
					<Image image={portrait_image} aspectRatio={375/700} />
				</Link>
				<div className={styles('imgcontainer-overlay')} />
				{label && (
					<p className={styles('imgcontainer-label')}>{label}</p>
				)}
				{tags && tags.length !== 0 && (
					<div className={styles('imgcontainer-tags')}>
						<Tags tags={tags} />
					</div>
				)}
			</div>
			<Link to={url} className={styles('content')}>
				<Theme.H5 className={styles('content-title')} mod="light">
					{title}
				</Theme.H5>
				{date && <p className={styles('content-date')}>{date}</p>}
			</Link>
		</div>
	)
}
