import './filtertag.scss'

import React from 'react'
import {Mod, styler} from 'util/style'
import {Icon} from 'assets/icon'

const styles = styler('filtertag')

export const FilterTag: React.FC<{
	onClick: () => void
	locked?: boolean
	inactive?: boolean
	passive?: boolean
	mod?: Mod<'white'>
}> = ({ onClick, locked, inactive, passive, mod, children}) => {

	return (
		<div onClick={onClick} className={styles().is({locked, inactive, passive}).mod(mod)}>
			<div className={styles('content')}>
				{children}
			</div>
			<span className={styles('icon')}>
				<Icon icon="Close" />
			</span>
		</div>
	)
}
