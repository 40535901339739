import './highlightpreview.scss'

import {Link} from 'gatsby'
import {Column, Grid} from 'layout/grid'
import {BgFixed} from 'layout/partials/bg'
import {Tags, TagsType} from 'layout/partials/tags'
import {Theme} from 'layout/theme'
import React from 'react'
import {ImageType, WysiwygType} from 'types/basictypes'
import {styler} from 'util/style'

const styles = styler('highlightpreview', ['image', 'content'])

export type HighlightpreviewType = HighlightpreviewImageType &
	HighlightpreviewContentType

export type HighlightpreviewImageType = {
	url?: string
	image?: ImageType
	label?: string
}

export type HighlightpreviewContentType = {
	url?: string
	title: string
	date?: string
	description?: WysiwygType
} & TagsType

export const Highlightpreview: React.FC<HighlightpreviewType> = data => {
	const {url, image, label, tags, title, date, description} = data

	return (
		<div className={styles()}>
			<Grid columns={1} m={2} l={12}>
				<Column l={9}>
					<HighlightpreviewImage url={url} image={image} label={label} />
				</Column>
				<Column l={3}>
					<HighlightpreviewContent
						url={url}
						tags={tags}
						title={title}
						date={date}
						description={description}
					/>
				</Column>
			</Grid>
		</div>
	)
}

export const HighlightpreviewImage: React.FC<HighlightpreviewImageType> = data => {
	const {url, image, label} = data

	return (
		<Link to={url ? url : ''} className={styles.image()}>
			<BgFixed image={image} className={styles.image('bg')} />
			{label && <p className={styles.image('label')}>{label}</p>}
		</Link>
	)
}

export const HighlightpreviewContent: React.FC<HighlightpreviewContentType> = data => {
	const {url, tags, title, date, description} = data

	return (
		<div className={styles.content()}>
			{tags && tags.length !== 0 && (
				<div className={styles.content('tags')}>
					<Tags tags={tags} />
				</div>
			)}
			{url && (
				<Link to={url}>
					<h2 className={styles.content('title')}>{title}</h2>
					{date && <p className={styles.content('date')}>{date}</p>}
					{description && (
						<div className={styles.content('text')}>
							<Theme.Wysiwyg>{description}</Theme.Wysiwyg>
						</div>
					)}
				</Link>
			)}
		</div>
	)
}
