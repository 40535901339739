import {graphql, useStaticQuery} from 'gatsby'
import {getImageFromField} from 'util/drupal'
import {ImageType, LinkType} from 'types/basictypes'

export type SubsiteModel = {
  tid: number
  name: string
  url: string
  logo_dark: ImageType
  logo_light: ImageType
  roots: Array<SubsiteRootModel>
}

export type SubsiteRootModel = {
  name: string
  tid: number
  url: string
  children: Array<LinkType>
}

let subsites: Array<SubsiteModel> = null
export const useStaticSubsitesQuery = (): Array<SubsiteModel> => {
  const data = useStaticQuery(graphql`
		query HeroStatic {
			allTaxonomyTermSubsites {
				nodes {
				  drupal_internal__tid
					name
					field_active
					fields {
					  page {
					    url
					  }
					}
					relationships {
						parent {
						  drupal_internal__tid
						}
						field_logo_dark {
							...ImageMeta
							relationships {
								field_media_image {
									localFile {
										image: childImageSharp {
											fixed(height: 150, quality: 100) {
												...GatsbyImageSharpFixed
											}
										}
									}
								}
							}
						}
						field_logo_light {
							...ImageMeta
							relationships {
								field_media_image {
									localFile {
										image: childImageSharp {
											fixed(height: 150, quality: 100) {
												...GatsbyImageSharpFixed
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	`)
  if (subsites) return subsites

  const nodes = data.allTaxonomyTermSubsites.nodes

  subsites = nodes.filter(node => node.relationships.parent.length === 0).map(node => {
    return {
      name: node.name,
      tid: node.drupal_internal__tid,
      url: node.fields?.page?.url,
      roots: [],
      logo_dark: getImageFromField(node.relationships.field_logo_dark),
      logo_light: getImageFromField(node.relationships.field_logo_light)
    }
  })

  const allRoots = []
  nodes.forEach(node => {
    const parentID = node.relationships.parent[0]?.drupal_internal__tid
    const parent = subsites.find(subsite => subsite.tid === parentID)
    if(parent) {
      const root: SubsiteRootModel = {
        name: node.name,
        tid: node.drupal_internal__tid,
        url: node.fields?.page?.url,
        children: []
      }
      parent.roots.push(root)
      allRoots.push(root)
    }
  })

  nodes.forEach(node => {
    const parentID = node.relationships.parent[0]?.drupal_internal__tid
    const root = allRoots.find(root => root.tid === parentID)
    if(root) {
      root.children.push({
        title: node.name,
        url: node.fields?.page?.url
      })
    }
  })

  return subsites
}

export const useSubsiteForUrl = (url: string): SubsiteModel => {
  const subsites = useStaticSubsitesQuery()
  return subsites.find(subsite =>
    subsite.url == url ||
    subsite.roots.find(root =>
      root.url === url ||
      root.children.find(child => child.url === url)
    )
  )
}
