import './links.scss'

import {Theme} from 'layout/theme'
import React from 'react'
import {LinkType, WysiwygType} from 'types/basictypes'
import {styler} from 'util/style'
import {Link} from 'util/link'

const styles = styler('links')

export type LinksType = {
	text?: WysiwygType
	links: Array<LinkType>
	cta_link?: LinkType
}

export const Links: React.FC<LinksType> = data => {
	const { text, links, cta_link } = data

	return (
		<div className={styles()}>
			<Theme.Container>
				{text && (
					<div className={styles('text')}>
						<Theme.Wysiwyg>{text}</Theme.Wysiwyg>
					</div>
				)}
				{links && links.length !== 0 && (
					<div className={styles('items')}>
						{links.map((link, i) => (
							<div key={i} className={styles('items-item')}>
								<div className={styles('items-item-link')}>
									<Link
										to={link.url}
										className={styles('items-item-link-label')}
									>
										{link.title}
									</Link>
								</div>
							</div>
						))}
					</div>
				)}
				{cta_link && (
					<div className={styles('link')}>
						<Theme.Link to={cta_link.url}>
							<Theme.LinkText>{cta_link.title}</Theme.LinkText>
							<Theme.LinkIcon />
						</Theme.Link>
					</div>
				)}
			</Theme.Container>
		</div>
	)
}
