import './image.scss'

import GatsbyImage, {
	GatsbyImageWithIEPolyfillProps as GatsbyImageProps
} from 'gatsby-image/withIEPolyfill'
import React from 'react'
import {ImageType} from 'types/basictypes'
import {styler} from 'util/style'

const styles = styler('image')

export const Image: React.FC<{
	image: ImageType
	aspectRatio: number | 'free'
	avoidStretching?: boolean
	className?: string
}> = (data) => {
	const {className, aspectRatio, image} = data
	if (!image) return null

	if (image.preview) {
		const previewAspectRatio =
			aspectRatio === 'free' ? image.meta.aspectRatio : aspectRatio
		return (
			<div
				className={styles().with(className)}
				style={{
					maxWidth: image.meta?.width
				}}
			>
				<div
					style={{
						width: '100%',
						backgroundImage: `url(${image.meta.publicURL})`,
						backgroundPosition: 'center center',
						backgroundSize: 'cover',
						height: 'inherit',
						paddingBottom: (1 / previewAspectRatio) * 100 + '%'
					}}
				/>
			</div>
		)
	}

	if (image.fluid) {
		return (
			<div
				className={styles().with(className)}
				style={{
					maxWidth: image.meta?.width
				}}
			>
				<GatsbyImage {...(image as any)} loading="auto" />
			</div>
		)
	}

	if (Array.isArray(image.fixed)) {
		throw 'Directly use GatsbyImage if you want art directed fixed images'
	}

	const ratio = image.fixed.width / image.fixed.height
	return (
		<div
			className={styles().with(className)}
			style={{
				display: 'flex',
				maxWidth: image.meta?.width
			}}
		>
			<GatsbyImage
				{...(image as any)}
				objectFit="cover"
				objectPosition="50% 50%"
				loading="auto"
				style={{
					width: '100%',
					height: 'inherit',
					paddingBottom: (1 / ratio) * 100 + '%'
				}}
			/>
		</div>
	)
}
