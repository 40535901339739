import './products.scss'
import {ProductsFilterModel} from 'blocks/products/models/ProductsFilterModel'
import {ProductsSearchModel, ProductsSearchState} from 'blocks/products/models/ProductsSearchModel'
import {ProductsTermModel} from 'blocks/products/models/ProductsTermModel'
import {Form, Formik} from 'formik'
import {FormField} from 'layout/form/form'
import {Column, Grid} from 'layout/grid'
import {Cardpreview} from 'layout/preview/cardpreview'
import {SearchresultsLoader} from 'layout/search/searchresults'
import {Theme} from 'layout/theme'
import React, {createContext, useEffect, useState} from 'react'
import AnimateHeight from 'react-animate-height'
import {FormikChanges} from 'util/formikchanges'
import {styler} from 'util/style'
import {useSearchParams} from 'util/uselocation'

const styles = styler('products', [
	'filters',
	'filterscategory',
	'filterssubcategory',
	'item'
])

export type ProductsType = {
	producttype: 'camera'
}

export type ProductsContextType = {
	search: ProductsSearchModel
	updateSearch: (newState: ProductsSearchModel) => void
}

export const ProductsContext = createContext<ProductsContextType>(null)
let data: ProductsSearchState = null

export const Products: React.FC<ProductsType> = ({producttype}) => {
	const {params, updateParams} = useSearchParams()
	const [search, setSearch] = useState<ProductsSearchModel>(() => {
		if (!data) return null
		return new ProductsSearchModel({
			...data,
			terms: params.terms.map((term) => '' + term)
		})
	})
	const updateSearch = (newSearch: ProductsSearchModel) => {
		setSearch(newSearch)
		updateParams({terms: newSearch.state.terms.map((term) => parseInt(term))})
	}

	useEffect(() => {
		if (search) return
		// @ts-ignore
		import(`./../../../public/${producttype}.json`).then((res) => {
			data = res.default
			const search = new ProductsSearchModel({
				...data,
				terms: params.terms.map((term) => '' + term)
			})
			setSearch(search)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (!search) {
		return <SearchresultsLoader />
	}

	return (
		<div className={styles()}>
			<Theme.Container>
				<Grid columns={1} xs={1} s={1} m={12} l={12}>
					<Column m={3} l={3}>
						<ProductsFilters search={search} updateSearch={updateSearch} />
					</Column>
					<Column m={9} l={9}>
						<div className={styles('content')}>
							<Grid columns={1} xs={2} s={2} m={2} l={3} gap={[15, 50]}>
								{search.products.map((item) => (
									<Column key={item.url}>
										<Cardpreview
											url={item.url}
											image={item.image}
											text={item.description}
											text_mod={['small', 'full']}
											display_square={true}
											display_fallback={false}
											display_padding={true}
										/>
									</Column>
								))}
							</Grid>
						</div>
					</Column>
				</Grid>
			</Theme.Container>
		</div>
	)
}

const ProductsFilters: React.FC<{
	search: ProductsSearchModel
	updateSearch: (search: ProductsSearchModel) => void
}> = ({search, updateSearch}) => {
	const values: Record<string, boolean> = {}
	search.state.terms.forEach((term) => {
		values[term] = true
	})

	return (
		<div className={styles.filters()}>
			<Formik
				initialValues={values}
				onSubmit={() => alert('TODO: filter products')}
			>
				<Form>
					<FormikChanges
						onChange={({values, setValues}) => {
							const terms = Object.keys(values).filter((key) => values[key])
							updateSearch(search.setTerms(terms))
						}}
					/>
					{search.filters.map((filter) => (
						<ProductsFiltersCategory key={filter.key} filter={filter} />
					))}
				</Form>
			</Formik>
		</div>
	)
}

const ProductsFiltersCategory: React.FC<{filter: ProductsFilterModel}> = ({
	filter
}) => {
	const [isOpen, setOpen] = useState(true)

	return (
		<div className={styles.filterscategory()}>
			<Theme.H5
				className={styles.filterscategory('title').is({open: isOpen})}
				mod="toggle"
			>
				<a onClick={() => setOpen(!isOpen)}>{filter.title}</a>
			</Theme.H5>
			<AnimateHeight height={isOpen ? 'auto' : 0}>
				<div className={styles.filterscategory('items')}>
					{filter.terms.map((term) => (
						<div className={styles.filterscategory('items-item')} key={term.id}>
							<ProductsFiltersSubcategory term={term} />
						</div>
					))}
				</div>
			</AnimateHeight>
		</div>
	)
}

const ProductsFiltersSubcategory: React.FC<{term: ProductsTermModel}> = ({
	term
}) => {
	return (
		<div className={styles.filterssubcategory()}>
			<FormField
				key={term.id}
				type="checkbox"
				name={term.key}
				label={term.label}
			/>
			<AnimateHeight height={term.isActive ? 'auto' : 0}>
				{term.children.length > 0 && (
					<div className={styles.filterscategory('items-item-subcategories')}>
						{term.children.map((subcategory) => (
							<FormField
								key={subcategory.id}
								type="checkbox"
								name={subcategory.key}
								label={subcategory.label}
							/>
						))}
					</div>
				)}
			</AnimateHeight>
		</div>
	)
}
