import { navigate } from '@reach/router'
import { FunctionComponent, useEffect, useRef } from 'react'
import { styler } from './style'
import { checkIsExternal, checkIsFile, processInternalLink } from './link'
import parse from 'url-parse'
import { useLocation } from './uselocation'
import { useRedirects, RedirectsMapping } from 'util/redirects'

const click = (hostname, redirects: RedirectsMapping, e) => {
	if (
		e.defaultPrevented ||
		e.button !== 0 ||
		e.altKey ||
		e.metaKey ||
		e.ctrlKey ||
		e.shiftKey ||
		e.currentTarget.target === '_blank' ||
		checkIsExternal(e.currentTarget.hostname, hostname) ||
		e.target.protocol === 'mailto:'
	)
		return
	e.preventDefault()
	const href = e.currentTarget.getAttribute('href')

	navigate(processInternalLink(redirects, href))
}

const removeQueryParams = url =>
	url.indexOf('?') > 0 ? url.substring(0, url.indexOf('?')) : url

const styles = styler('trusted')

export const Trusted: FunctionComponent<{
	children: string
}> = ({ children, ...props }) => {
	const { location: { hostname } } = useLocation()
	const redirects = useRedirects()

	const dom = useRef<HTMLDivElement>()

	useEffect(() => {
		const clear = []
		const listener = click.bind(null, hostname, redirects)
		dom.current.querySelectorAll('a').forEach(link => {
			const href = link.getAttribute('href')
			if (!href) return
			const hrefWithoutQuery = removeQueryParams(href)
			const parsed = parse(href)
			if (
				checkIsFile(hrefWithoutQuery) ||
				checkIsExternal(parsed.hostname, hostname)
			) {
				link.setAttribute('target', '_blank')
				link.setAttribute('rel', 'external nofollow noopener')
			}
			link.addEventListener('click', listener)
			clear.push(() => link.removeEventListener('click', listener))
		})
		return () => {
			clear.forEach(f => f())
		}
	})

	return (
		<div
			ref={dom}
			className={styles().mergeProps(props)}
			dangerouslySetInnerHTML={{
				__html: Array.isArray(children) ? children[0] : children
			}}
		/>
	)
}
