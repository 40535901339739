import './relatedpreview.scss'

import {BgFixed} from 'layout/partials/bg'
import {Tags} from 'layout/partials/tags'
import {Theme} from 'layout/theme'
import React from 'react'
import {Link} from 'util/link'
import {styler} from 'util/style'
import {PreviewType} from 'layout/preview/previewtype'

const styles = styler('relatedpreview')

export const Relatedpreview: React.FC<PreviewType> = data => {
	const {title, tags, image, url, label} = data

	return (
		<div className={styles()}>
			<Link to={url} className={styles('image')}>
				<BgFixed image={image} className={styles('image-bg')} mod="gradient" />
			</Link>
			{label && <p className={styles('label')}>{label}</p>}
			<div className={styles('content')}>
				{tags && tags.length !== 0 && (
					<div className={styles('content-tags')}>
						<Tags tags={tags} />
					</div>
				)}
				<Link to={url}>
					<Theme.H5 className={styles('content-title')}>{title}</Theme.H5>
				</Link>
			</div>
		</div>
	)
}
