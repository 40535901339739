import './headermobilenav.scss'

import {Icon} from 'assets/icon'
import React, {useState} from 'react'
import {t} from 'util/i18'
import {Link} from 'util/link'
import {styler} from 'util/style'
import {HeaderLinkType} from './header'

const styles = styler('headermobilenav', ['links', 'submenu'])

export const HeaderMobilenav: React.FC<{
	links: Array<HeaderLinkType>
	onCloseMenu: () => void
}> = ({links, onCloseMenu}) => {
	const [submenu, setSubmenu] = useState<HeaderLinkType>(null)

	return (
		<div className={styles()}>
			<HeaderMobilenavContent
				links={links}
				submenu={submenu}
				setSubmenu={setSubmenu}
			/>
			<a className={styles('close')} onClick={() => onCloseMenu()}>
				<span className={styles('close-icon')}>
					<Icon icon="Close" />
				</span>
			</a>
		</div>
	)
}

export const HeaderMobilenavContent: React.FC<{
	links: Array<HeaderLinkType>
	submenu: HeaderLinkType
	setSubmenu: (HeaderLinkType) => void
}> = ({links, submenu, setSubmenu}) => {
	if (!submenu)
		return <HeaderMobilenavLinks links={links} setSubmenu={setSubmenu} />

	return (
		<HeaderMobilenavSubmenu
			item={submenu}
			goBack={() => setSubmenu(null)}
		/>
	)
}

export const HeaderMobilenavLinks: React.FC<{
	links: Array<HeaderLinkType>
	setSubmenu: (HeaderLinkType) => void
}> = ({links, setSubmenu}) => {
	if (!links || links.length === 0) return null

	return (
		<div className={styles.links()}>
			{links.map((link, i) => {
				if (link.children && link.children.length !== 0) {
					return (
						<div key={i} className={styles.links('item')}>
							<a
								onClick={() => setSubmenu(link)}
								className={styles.links('item-link')}
							>
								<span className={styles.links('item-link-text')}>
									{link.title}
								</span>
								<span className={styles.links('item-link-icon')}>
									<Icon icon="ChevronRight" />
								</span>
							</a>
						</div>
					)
				}

				return (
					<div key={i} className={styles.links('item')}>
						<Link to={link.url} className={styles.links('item-link')}>
							<span className={styles.links('item-link-text')}>
								{link.title}
							</span>
						</Link>
					</div>
				)
			})}
		</div>
	)
}

export const HeaderMobilenavSubmenu: React.FC<{
	item: HeaderLinkType,
	goBack: () => void
}> = ({item: {url, title, children}, goBack}) => {
	if (!children || children.length === 0) return null

	return (
		<div className={styles.submenu()}>
			<a className={styles.submenu('back')} onClick={() => goBack()}>
				<span className={styles.submenu('back-icon')}>
					<Icon icon="ArrowLeft" />
				</span>
				<span className={styles.submenu('back-text')}>{t('header.back')}</span>
			</a>
			<Link to={url} className={styles.submenu('link')}>
				<span className={styles.submenu('link-text')}>{title}</span>
				<span className={styles.submenu('link-icon')}>
					<Icon icon="ArrowRight" />
				</span>
			</Link>
			<div className={styles.submenu('divider')} />
			<div className={styles.submenu('items')}>
				{children.map((child, i) => (
					<div key={i} className={styles.submenu('item')}>
						<Link to={child.url} className={styles.submenu('item-link')}>
							<span className={styles.submenu('item-link-text')}>
								{child.title}
							</span>
							<span className={styles.submenu('item-link-icon')}>
								<Icon icon="ArrowRight" />
							</span>
						</Link>
						<div className={styles.submenu('item-description')}>
							{child.description}
						</div>
					</div>
				))}
			</div>
		</div>
	)
}
