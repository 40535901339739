import './footer.scss'

import logoImg from 'assets/images/imec-logo.svg'
import {graphql, useStaticQuery} from 'gatsby'
import {Theme} from 'layout/theme'
import React, {Fragment} from 'react'
import {LinkType} from 'types/basictypes'
import {useTranslation} from 'util/i18'
import {Link} from 'util/link'
import {styler} from 'util/style'
import {useStaticFooterQuery} from './footer_graph'
import {Footernav} from './footernav'
import {Sociallink} from './sociallink'

const styles = styler('footer', ['social', 'bottom', 'links'])

export type FooterType = {
	social: FooterlinkType
	categories: FootercategoriesType
	menu: FooterlinkType
	links: FooterlinkType
}

export type FooterlinkType = {
	links: Array<LinkType>
}

export type FootercategoriesType = {
	categories: Array<{
		title: string
		titleUrl: string
		subtitle: string
		links: Array<LinkType>
	}>
}

export const Footer = () => {
	const footer: FooterType = useStaticFooterQuery()

	return (
		<footer className={styles()}>
			<Theme.OuterContainer>
				<div className={styles('content')}>
					<FooterSocial {...(footer && footer.social)} />
					<Footernav
						footerCategories={footer && footer.categories}
						footerMenu={footer && footer.menu}
					/>
					<FooterBottom {...(footer && footer.links)} />
				</div>
			</Theme.OuterContainer>
		</footer>
	)
}

export const FooterSocial: React.FC<FooterlinkType> = ({links}) => {
	const {
		footer: {follow}
	} = useTranslation()
	if (!links || links.length === 0) return null

	return (
		<div className={styles.social()}>
			<p className={styles.social('text')}>{follow}</p>
			<div className={styles.social('icons')}>
				{links.map((link, i) => (
					<Sociallink key={i} type={link.title} url={link.url} mod="rounded" />
				))}
			</div>
		</div>
	)
}

export const FooterBottom: React.FC<FooterlinkType> = ({links}) => {
	const {
		footer: {partof}
	} = useTranslation()
	const envProjectKey = process.env.GATSBY_PROJECT_KEY
	const envProjectName = process.env.GATSBY_PROJECT_NAME
	const subsite = envProjectKey === 'hyperspectral'

	return (
		<div className={styles.bottom()}>
			<div className={styles.bottom('row')}>
				<div className={styles.bottom('row-left')}>
					<FooterLinks links={links} />
				</div>
				{subsite && (
					<div className={styles.bottom('row-right')}>
						<p className={styles.bottom('row-right-text')}>
							{envProjectName + ' ' + partof}
						</p>
						<Link
							to={'https://imec-int.com'}
							className={styles.bottom('row-right-logo')}
						>
							<img
								src={logoImg}
								alt="imec logo"
								className={styles.bottom('row-right-logo-img')}
							/>
						</Link>
					</div>
				)}
			</div>
		</div>
	)
}

export const FooterLinks: React.FC<FooterlinkType> = ({links}) => {
	if (!links || links.length === 0) return null

	return (
		<div className={styles.links()}>
			{links.map((link, i) => {
				return (
					<Fragment key={i}>
						{i > 0 && <span className={styles.links('spacer')}>|</span>}
						<Link className={styles.links('item')} to={link.url}>
							{link.title}
						</Link>
					</Fragment>
				)
			})}
		</div>
	)
}
