import './news.scss'

import {Carousel, useCarousel} from '@codeurs/carousel'
import {Icon} from 'assets/icon'
import {useStaticNewsQuery} from 'blocks/news/news_graph'
import {Link} from 'gatsby'
import {CarouselControls} from 'layout/carousel/carouselcontrols'
import {Imagepreview} from 'layout/preview/imagepreview'
import {Theme} from 'layout/theme'
import React from 'react'
import {CarouselType} from 'types/basictypes'
import {t} from 'util/i18'
import {styler} from 'util/style'
import {usePathname} from 'util/uselocation'
import {PreviewType} from 'layout/preview/previewtype'
import {useLinks} from 'util/links'

const styles = styler('news', ['content', 'contentextra'])

export type NewsType = {
	title?: string
}

export const News: React.FC<NewsType> = data => {
	const {title} = data
	const carousel = useCarousel()
	const items = useStaticNewsQuery()

	const pathname = usePathname()
	const {news} = useLinks()

	const visibleItems = items.filter(item => item.url !== pathname).slice(0, 12)

	return (
		<div className={styles()}>
			<Theme.Container>
				<div className={styles('top')}>
					<div className={styles('top-title')}>
						<Link to={news.url}>
							<Theme.H2 mod={['blueline', 'arrow']}>
								{title || news.title}
							</Theme.H2>
						</Link>
					</div>
					<div className={styles('top-nav')}>
						<CarouselControls carousel={carousel} />
					</div>
				</div>
			</Theme.Container>
			<NewsContent carousel={carousel} items={visibleItems} />
		</div>
	)
}

export const NewsContent: React.FC<{
	carousel: CarouselType
	items: Array<PreviewType>
}> = data => {
	const {carousel, items} = data
	if (!items || items.length === 0) return null

	return (
		<div className={styles.content()}>
			<Theme.Container>
				<div className={styles.content('items')}>
					<Carousel {...carousel} full>
						{items.map((item, i) => (
							<div
								key={i}
								className={styles
									.content('items-item')
									.is({active: carousel.isActive(i)})}
							>
								<Imagepreview {...item} date={item.display_date} />
							</div>
						))}
						<div className={styles.content('items-item')}>
							<NewsContentExtra />
						</div>
					</Carousel>
				</div>
			</Theme.Container>
		</div>
	)
}

export const NewsContentExtra: React.FC = () => {
	const {press} = useLinks()

	return (
		<Link to={press.url} className={styles.contentextra()}>
			<div className={styles.contentextra('content')}>
				<div className={styles.contentextra('content-icon')}>
					<Icon icon="Book" />
				</div>
				<Theme.H5 className={styles.contentextra('content-title')} mod="light">
					{t('article.readmore')}
				</Theme.H5>
			</div>
		</Link>
	)
}
