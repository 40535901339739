import './offices.scss'

import {Icon} from 'assets/icon'
import {Theme} from 'layout/theme'
import React, {useEffect, useState} from 'react'
import AnimateHeight from 'react-animate-height'
import {WysiwygType} from 'types/basictypes'
import {styler} from 'util/style'

const styles = styler('offices', ['item'])

export type OfficesType = {
	items: Array<{
		title: string
		text?: WysiwygType
	}>
}

export const Offices: React.FC<OfficesType> = ({items}) => {
	const [isDesktop, setIsDesktop] = useState<any>(true)
	if (!items || items.length === 0) return null

	useEffect(() => {
		setIsDesktop(window.innerWidth >= 1024 ? true : false)
	})

	return (
		<div className={styles()}>
			<div className={styles('row')}>
				<div className={styles('left')}>
					<OfficesItem
						mod="full"
						isDesktop={isDesktop}
						defaultVisible={false}
						{...items[0]}
					/>
				</div>
				<div className={styles('right')}>
					{items.slice(1, items.length).map((office, i) => (
						<OfficesItem
							key={i}
							{...office}
							isDesktop={isDesktop}
							defaultVisible={false}
						/>
					))}
				</div>
			</div>
		</div>
	)
}

export const OfficesItem: React.FC<{
	title: string
	text?: WysiwygType
	mod?: 'full'
	isDesktop: boolean
	defaultVisible: boolean
}> = data => {
	const {title, text, mod, defaultVisible, isDesktop} = data
	const [isVisible, setVisible] = useState(defaultVisible)

	return (
		<div className={styles.item().mod(mod)}>
			<a className={styles.item('top')} onClick={() => setVisible(!isVisible)}>
				<Theme.H5 className={styles.item('top-title')}>{title}</Theme.H5>
				{!isDesktop && (
					<div className={styles.item('top-toggler')}>
						{isVisible ? <Icon icon="Minus" /> : <Icon icon="Plus" />}
					</div>
				)}
			</a>
			<AnimateHeight height={isDesktop || isVisible ? 'auto' : 0}>
				<div className={styles.item('content')}>
					{text && <Theme.Wysiwyg>{text}</Theme.Wysiwyg>}
				</div>
			</AnimateHeight>
		</div>
	)
}
