import { graphql, useStaticQuery } from 'gatsby'
import { AgendaFragment, AgendaStaticQuery } from 'types/graphtypes'
import { AgendaEventType, AgendaType } from './agenda'

export const getAgendaData: (frag: AgendaFragment) => AgendaType = frag => {
	const { field_agenda_title } = frag

	return {
		title: field_agenda_title
	}
}

export const fragment = graphql`
	fragment Agenda on paragraph__agenda {
		field_agenda_title
	}
`

export const useStaticAgendaQuery = () => {
	const data = useStaticQuery(graphql`
		query AgendaStatic {
			allNodeEvent(
				limit: 50
				sort: {fields: field_date}
				filter: {
					field_hide_from_calendar: {ne: true}
					fields: {active: {eq: true}}
				}
			) {
				events: nodes {
					title
					field_location
					field_date(formatString: "YYYYMMDD")
					field_end_date(formatString: "YYYYMMDD")
					field_hide_description
					field_description {
						processed
					}
					fields {
						slug
					}
					relationships {
						field_blocks {
							... on paragraph__hero {
								id
								field_hero_description {
									processed
								}
							}
						}
					}
				}
			}
		}
	`)
	return getAgendaStaticItems(data)
}

export const getAgendaStaticItems: (
	query: AgendaStaticQuery
) => Array<AgendaEventType> = query => {
	query.allNodeEvent.events.forEach(event => {
		if (event.field_description) {
			event.description = event.field_description.processed
		} else {
			event.relationships.field_blocks.forEach(block => {
				if (
					block.field_hero_description &&
					block.field_hero_description.processed
				) {
					event.description = block.field_hero_description.processed
				}
			})
		}
	})

	return query.allNodeEvent.events.map(item => ({
		title: item.title,
		location: item.field_location,
		date: item.field_date,
		endDate: item.field_end_date,
		slug: item.fields.slug,
		description: item.description,
		hideDescription: item.field_hide_description
	}))
}
