import './readingroomtags.scss'

import {useStaticReadingroomTagsQuery} from 'blocks/readingroom/static/readingroom_graph_tags'
import {FilterTag} from 'layout/search/filtertag'
import React from 'react'
import {useTranslation} from 'util/i18'
import {Link} from 'util/link'
import {styler} from 'util/style'
import {useReadingroomSearchParams} from 'blocks/readingroom/readingroom'
import {useLinks} from 'util/links'

const styles = styler('readingroomtags', ['category'])

export const ReadingroomTags: React.FC = () => {
	const { params, toggleTag, updateParams, createUrl } = useReadingroomSearchParams()
	const allTags = useStaticReadingroomTagsQuery()
	const namesSet = new Set(params.tags)
	const tags = allTags.filter(tag => namesSet.has(tag.name))
	const { readingroom: { types } } = useTranslation()
	const { readingroom } = useLinks()

	return (
		<div className={styles()}>
			<div className={styles('top')}>
				<div className={styles('top-left')}>
					<div className={styles('top-left-tags')}>
						{params.type && <FilterTag onClick={() => updateParams({ ...params, type: null })} key={params.type}>
							{types[params.type]}
						</FilterTag>}
						{tags.map(tag => (
							<FilterTag onClick={() => toggleTag(tag)} key={tag.tid}>
								{tag.full_name}
							</FilterTag>
						))}
					</div>
					{params.tags.length > 0 && (
						<Link to={readingroom.url} className={styles('top-left-clear')}>
							Verwijder filters
						</Link>
					)}
				</div>
			</div>
		</div >
	)
}
