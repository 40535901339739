import './sociallink.scss'

import {Icon} from 'assets/icon'
import React from 'react'
import {Mod, styler} from 'util/style'

const styles = styler('sociallink')

export const Sociallink: React.FC<{
	type: string
	url: string
	mod?: Mod<'rounded' | 'round'>
}> = ({type, url, mod}) => {
	if (!type || !url) return null

	return (
		<a className={styles().mod([type, mod])} href={url} target="_blank">
			<span className={styles('icon')}>
				<SocialLinkIcon type={type} />
			</span>
		</a>
	)
}

export const SocialLinkIcon: React.FC<{type: string}> = ({type}) => {
	switch (type) {
		case 'facebook':
			return <Icon icon="Facebook" />
		case 'flickr':
			return <Icon icon="Flickr" />
		case 'instagram':
			return <Icon icon="Instagram" />
		case 'linkedin':
			return <Icon icon="LinkedIn" />
		case 'twitter':
			return <Icon icon="Twitter" />
		case 'vimeo':
			return <Icon icon="Vimeo" />
		case 'youtube':
			return <Icon icon="YouTube" />
		default:
			return null
	}
}
