import {JobsSearchModel} from 'blocks/jobs/models/JobsSearchModel'
import {JobTermModel} from 'blocks/jobs/models/JobTermModel'
import {JobFilterKey, JobFilterModel, JobFilterState} from 'blocks/jobs/models/JobFilterModel'
import memoizeGetters from 'memoize-getters'
import {JobModel} from 'blocks/jobs/models/JobModel'

export type RegularJobTermState = {
  name: string
  tid: number
  parent: number
}

export class TaxonomyFilterModel extends JobFilterModel {
  state: JobFilterState

  constructor(search: JobsSearchModel, state: JobFilterState) {
    super(search)
    this.state = state
  }

  get searchWithoutThisFilter(): JobsSearchModel {
    return this.search.update({
      terms: this.search.selectedTerms.filter(term => term.filter !== this).map(term => term.id)
    })
  }

  get key(): JobFilterKey {
    return this.state.key
  }

  get terms(): Array<TaxonomyTermModel> {
    return this.state.terms.map(state => new TaxonomyTermModel(this, state))
  }
}

export class TaxonomyTermModel extends JobTermModel<TaxonomyFilterModel> {
  state: RegularJobTermState

  constructor(filter: TaxonomyFilterModel, state: RegularJobTermState) {
    super(filter)
    this.state = state
  }

  get isRelevant(): boolean {
    return !!this.filter.searchWithoutThisFilter.jobs.find(job => this.validateJob(job))
  }

  get tid(): number {
    return this.state.tid
  }

  get id() {
    return `${this.tid}`
  }

  get name(): string {
    return this.state.name
  }

  get hasSelectedParent(): boolean {
    return this.parent && this.parent.selected
  }

  get parent(): TaxonomyTermModel {
    return this.filter.terms.find(term => term.tid === this.state.parent)
  }

  get children(): Array<TaxonomyTermModel> {
    return this.filter.terms.filter(term => term.parent === this)
  }

  validateJob(job: JobModel): boolean {
    if(job.state.terms.find(id => id === this.tid)) return true
    return !!this.children.find(child => child.validateJob(job))
  }

  select(): JobsSearchModel {
    if (this.selected) return this.search
    const childrenIDs = new Set(this.children.map(child => child.id))
    return this.search.update({
      terms: [
        ...this.search.state.terms.filter(id => {
          if(this.parent && this.parent.id === id) return false
          if(childrenIDs.has(id)) return false
          return true
        }),
        this.id
      ]
    })
  }
}

memoizeGetters(TaxonomyFilterModel)
memoizeGetters(TaxonomyTermModel)
