import { useFormikContext } from 'formik'
import React, { useEffect } from 'react'

export type OnChangeType = (values, setValues: (vals) => void) => void

export const useFormikChange = (onChange: OnChangeType) => {
	const { values, setValues } = useFormikContext()
	useEffect(() => {
		onChange({ values }, newValues => {
			if (JSON.stringify(values) !== JSON.stringify(newValues)) {
				setValues(newValues)
			}
		})
	}, [values])
}

export const FormikChanges = ({ onChange }: { onChange: OnChangeType }) => {
	useFormikChange(onChange)
	return null
}
