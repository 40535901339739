import { ProductModel, ProductModelState } from 'blocks/products/models/ProductModel'
import { ProductsFilterModel, ProductsFilterState } from 'blocks/products/models/ProductsFilterModel'
import { ProductsTermKey } from 'blocks/products/models/ProductsTermModel'
import memoizeGetters from 'memoize-getters'

export type ProductsSearchState = {
	filters: Array<ProductsFilterState>
	products: Array<ProductModelState>
	terms: Array<ProductsTermKey>
}

export class ProductsSearchModel {

	constructor(readonly state: ProductsSearchState) { }

	get activeFilters(): Array<ProductsFilterModel> {
		return this.filters.filter(filter => filter.isActive)
	}

	get filters(): Array<ProductsFilterModel> {
		return this.state.filters
			.map(state => new ProductsFilterModel(this, state))
			.sort((a,b) => a.weight - b.weight)
	}

	get products(): Array<ProductModel> {
		return this.allProducts.filter(product => product.isValid)
	}

	get allProducts(): Array<ProductModel> {
		return this.state.products.map(state => new ProductModel(this, state))
	}

	get termsSet(): Set<ProductsTermKey> {
		return new Set(this.state.terms)
	}

	// ----- Update state ----- //
	update(newState: Partial<ProductsSearchState>): ProductsSearchModel {
		return new ProductsSearchModel({
			...this.state,
			...newState
		})
	}

	setTerms(terms: Array<ProductsTermKey>) {
		return this.update({ terms })
	}

	clear() {
		return this.update({ terms: [] })
	}
}
memoizeGetters(ProductsSearchModel)
