import './tags.scss'

import { useReadingroomSearchParams } from 'blocks/readingroom/readingroom'
import { Link } from 'gatsby'
import React from 'react'
import { styler } from 'util/style'

const styles = styler('tags')

export type TagsType = {
	tags?: Array<string>
}

export const Tags: React.FC<TagsType> = data => {
	const { tags } = data
	const { createUrl } = useReadingroomSearchParams()

	if (!tags || tags.length === 0) return null

	return (
		<div className={styles()}>
			{tags.slice(0, 2).map((tag, i) => (
				<div key={i} className={styles('item')}>
					<Link
						to={createUrl({ tags: [tag] })}
						className={styles('item-tag')}
					>
						{tag}
					</Link>
				</div>
			))}
			{tags.length > 2 && <span className={styles('dots')}>...</span>}
		</div>
	)
}
