import './readingroomsearch.scss'

import React, {useState} from 'react'
import {useLocation} from 'util/uselocation'
import {useStaticReadingroomTagsQuery} from 'blocks/readingroom/static/readingroom_graph_tags'
import {t, useTranslation} from 'util/i18'
import {Theme} from 'layout/theme'
import {Column, Grid} from 'layout/grid'
import {SearchbarInput, SearchbarMore, SearchbarToggler} from 'layout/search/searchbar'
import {navigate} from '@reach/router'
import {ReadingroomTags} from 'blocks/readingroom/components/readingroomtags'
import {Subjects, SubjectsColumn, SubjectsTag, SubjectsTitle} from 'layout/search/subjects'
import {useReadingroomSearchParams} from 'blocks/readingroom/readingroom'
import {styler} from 'util/style'

const styles = styler('readingroomsearch')

export const ReadingroomSearch: React.FC<{
  categories: Array<string>
}> = ({categories}) => {
  const [open, setOpen] = useState(false)
  const {location: {pathname}} = useLocation()
  const {params: {query}, updateParams} = useReadingroomSearchParams()

  return <div className={styles()}>
    <Theme.Container>
      <Grid columns={1} s={1} m={12} l={12} align="top">
        <Column className={styles()} m={9} l={9}>
          <SearchbarInput
            onSearch={query => updateParams({query})}
            query={query}
            mod="dark"
          />
        </Column>
        <Column m={3} l={3}>
          <SearchbarToggler
            open={open}
            onToggle={() => setOpen(!open)}
          >
            {open ? t('readingroom.showless') : t('readingroom.showmore')}
          </SearchbarToggler>
        </Column>
      </Grid>
      <ReadingroomTags />
      <SearchbarMore open={open}>
        <ReadingroomsearchSubjects categories={categories} />
      </SearchbarMore>
    </Theme.Container>
  </div>
}

export const ReadingroomsearchSubjects: React.FC<{
  categories: Array<string>
}> = ({categories}) => {
  const {params, setParams, toggleTag} = useReadingroomSearchParams()
  const tags = useStaticReadingroomTagsQuery()
  const categoriesSet = new Set(categories)
  const categoryTags = tags.filter(tag => categoriesSet.has(tag.name))
  const {readingroom: {extraCategory, types}} = useTranslation()

  return <Subjects title="Test">
    {categoryTags.map(tag => (
      <SubjectsColumn key={tag.tid}>
        <SubjectsTitle onClick={() => toggleTag(tag)}>
          {tag.name}
        </SubjectsTitle>
        {tag.children.map(child => (
          <SubjectsTag onClick={() => toggleTag(child)} key={tag.tid}>
            {child.name}
          </SubjectsTag>
        ))}
      </SubjectsColumn>
    ))}
    <SubjectsColumn>
      <SubjectsTitle>{extraCategory.name}</SubjectsTitle>
      {extraCategory.items.map((item, index) => {
        switch (item.type) {
          case 'tag':
            const tag = tags.find(tag => tag.name === item.name)
            return (
              <SubjectsTag onClick={() => toggleTag(tag)} key={index}>
                {item.name}
              </SubjectsTag>
            )
          case 'pagetype':
            const isActive = params.type === item.pagetype
            return (
              <SubjectsTag
                onClick={() => setParams({...params, type: isActive ? null : item.pagetype})}
                key={index}
              >
                {types[item.pagetype]}
              </SubjectsTag>
            )
          default:
            return null
        }
      })}
    </SubjectsColumn>
  </Subjects>
}
