import './blocks2.scss'
import {Theme} from 'layout/theme'
import React from 'react'
import {styler} from 'util/style'
import {WysiwygType} from 'types/basictypes'

const styles = styler('blocks2')

export type Blocks2Type = {
  block_left: WysiwygType
  block_right: WysiwygType
}

export const Blocks2: React.FC<Blocks2Type> = data => {
  const {block_left, block_right} = data

  return (
    <div className={styles()}>
      <Theme.Container>
        <div className={styles('left')}>
          {block_left && <Theme.Wysiwyg>{block_left}</Theme.Wysiwyg>}
        </div>
        <div className={styles('right')}>
          {block_right && <Theme.Wysiwyg>{block_right}</Theme.Wysiwyg>}
        </div>
      </Theme.Container>
    </div>
  )
}
