import './populair.scss'

import {Column, Grid} from 'layout/grid'
import {BgFixed} from 'layout/partials/bg'
import {Tags} from 'layout/partials/tags'
import {PreviewType} from 'layout/preview/previewtype'
import {Theme} from 'layout/theme'
import React, {useState} from 'react'
import {useTranslation} from 'util/i18'
import {Link} from 'util/link'
import {styler} from 'util/style'
import {useInterval} from 'util/useinterval'

const styles = styler('populair', ['imgcontainer'])

export const Populair: React.FC<{
	items: Array<PreviewType>
}> = (data) => {
	const {items} = data
	const [stopHovering, setStopHovering] = useState(false)
	const [isHovered, setHovered] = useState(0)
	const {
		readingroom: {
			popular: {title}
		}
	} = useTranslation()

	if (!items || items.length == 0) return null

	useInterval(() => {
		if (stopHovering) return
		if (items[isHovered + 1]) setHovered(isHovered + 1)
		else setHovered(0)
	}, 7500)

	return (
		<div
			className={styles()}
			onMouseOver={() => setStopHovering(true)}
			onMouseLeave={() => setStopHovering(false)}
		>
			<Theme.Container>
				<h2 className={styles('title')}>{title}</h2>
				<Grid columns={1} xs={1} s={2} m={2} l={2}>
					<Column>
						<div className={styles('images')}>
							{items.map((item, i) => (
								<div
									key={i}
									className={styles.imgcontainer().is({
										open: i === isHovered
									})}
								>
									<div className={styles.imgcontainer('wrapper')}>
										<Link
											to={item.url}
											className={styles.imgcontainer('wrapper-image')}
										>
											<BgFixed image={item.image} />
										</Link>
										<div className={styles.imgcontainer('wrapper-overlay')} />
										{item.tags && item.tags.length !== 0 && (
											<div className={styles.imgcontainer('wrapper-tags')}>
												<Tags tags={item.tags} />
											</div>
										)}
									</div>
								</div>
							))}
						</div>
					</Column>
					<Column>
						<div className={styles('articles')}></div>
						{items.map((item, i) => (
							<Link to={item.url} key={i}>
								<div
									className={styles('articles-item').is({
										open: i === isHovered
									})}
									onMouseOver={() => setHovered(i)}
								>
									<div className={styles('articles-item-top')}>
										{item.label && (
											<div className={styles('articles-item-top-label')}>
												{item.label}
											</div>
										)}
										{item.date && (
											<div className={styles('articles-item-top-date')}>
												{item.date}
											</div>
										)}
									</div>
									{item.title && (
										<div className={styles('articles-item-title')}>
											{item.title}
										</div>
									)}
								</div>
							</Link>
						))}
					</Column>
				</Grid>
			</Theme.Container>
		</div>
	)
}
