import './services.scss'

import {Column, Grid} from 'layout/grid'
import {BgFixed} from 'layout/partials/bg'
import {Cardpreview} from 'layout/preview/cardpreview'
import {Theme} from 'layout/theme'
import React from 'react'
import {ImageType, LinkType, WysiwygType} from 'types/basictypes'
import {styler} from 'util/style'

const styles = styler('services', ['content', 'items'])

export type ServicesType = {
	background_image: ImageType
	text: WysiwygType
	display_right?: boolean
	items: Array<ServicesItemType>
}

export type ServicesItemType = {
	image: ImageType
	text: WysiwygType
	link: LinkType
}

export const Services: React.FC<ServicesType> = (data) => {
	const {background_image, text, display_right = false, items} = data
	const singleItem = items && items.length <= 1

	return (
		<div className={styles().mod({bg: background_image})}>
			{background_image && (
				<BgFixed image={background_image} className={styles('bg_container')} />
			)}
			<Theme.Container>
				<Grid
					columns={1}
					xs={1}
					s={1}
					m={singleItem ? 2 : 1}
					l={singleItem ? 2 : 3}
					gap={[30, 50]}
				>
					<Column m={{order: singleItem && display_right ? 1 : 0}}>
						{text && <ServicesContent text={text} />}
					</Column>
					<Column span={singleItem ? 1 : 2}>
						<div className={background_image && styles('itemscontainer')}>
							<Grid
								columns={1}
								xs={1}
								s={singleItem ? 1 : 2}
								m={singleItem ? 1 : 2}
								l={singleItem ? 6 : 2}
							>
								{items &&
									items.map((item, i) => (
										<Column
											key={i}
											span={singleItem ? 4 : 1}
											offset={singleItem ? 1 : 0}
										>
											<Cardpreview
												url={item.link?.url}
												image={item.image}
												text={item.text}
												display_background_dark={true}
											/>
										</Column>
									))}
							</Grid>
						</div>
					</Column>
				</Grid>
			</Theme.Container>
		</div>
	)
}

export const ServicesContent: React.FC<{
	text: WysiwygType
}> = (data) => {
	const {text} = data

	return (
		<div className={styles.content()}>
			{text && <Theme.Wysiwyg mod="inherit">{text}</Theme.Wysiwyg>}
		</div>
	)
}
