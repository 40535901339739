import {PreviewType} from 'layout/preview/previewtype'

export function filterDoubles(items: Array<PreviewType>) {
  const result = []
  const urls = new Set()

  items.forEach(item => {
    if(urls.has(item.url)) return

    result.push(item)
    urls.add(item.url)
  })

  return result
}
