import './downloads.scss'

import {Icon} from 'assets/icon'
import {Column, Grid} from 'layout/grid'
import {Theme} from 'layout/theme'
import React from 'react'
import {LinkType} from 'types/basictypes'
import {t} from 'util/i18'
import {checkIsFile, Link} from 'util/link'
import {styler} from 'util/style'

const styles = styler('downloads', ['item'])

export type DownloadsType = {
	title?: string
	items: Array<DownloadsItemType>
}

export type DownloadsItemType = {
	type: 'file' | 'link'
} & LinkType

export const Downloads: React.FC<DownloadsType> = (data) => {
	const {title, items} = data
	if (!items || items.length == 0) return null

	return (
		<div className={styles()}>
			<Theme.Container>
				<Theme.H2 className={styles('title')}>
					{title || t('downloads.title')}
				</Theme.H2>
				<div className={styles('content')}>
					<Grid
						columns={1}
						xxs={{gap: [5, 0]}}
						xs={{gap: [15, 0]}}
						s={{gap: [30, 0]}}
						m={{columns: 2, gap: [65, 0]}}
						l={{columns: 2, gap: [135, 0]}}
						gap={[65, 0]}
					>
						{items.map((item, i) => (
							<Column key={i}>
								<DownloadsItem
									type={item.type}
									title={item.title}
									url={item.url}
								/>
							</Column>
						))}
					</Grid>
				</div>
			</Theme.Container>
		</div>
	)
}

const DownloadsItem: React.FC<DownloadsItemType> = (data) => {
	const {type, url, title} = data
	if (!url) return null

	return (
		<div className={styles.item()}>
			<Link to={url} className={styles.item('link')}>
				<div className={styles.item('link-icon')}>
					<Icon icon={type === 'file' ? 'File' : 'ArrowDown'} />
				</div>
				<p className={styles.item('link-text')}>{title}</p>
			</Link>
		</div>
	)
}
