import {styler} from 'util/style'

export type AnchorType = {
	id: string
	title: string //Used in the inpagenavigation block
}

export const Anchor: React.FC<AnchorType> = data => {
	const {id} = data

	return <div id={id}> </div>
}
