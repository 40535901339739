import {JobFilterModel} from 'blocks/jobs/models/JobFilterModel'
import {JobsSearchModel} from 'blocks/jobs/models/JobsSearchModel'
import memoizeGetters from 'memoize-getters'
import {JobModel} from 'blocks/jobs/models/JobModel'

export abstract class JobTermModel<F extends JobFilterModel = JobFilterModel> {
	filter: F

	constructor(filter: F) {
		this.filter = filter
	}

	abstract get id(): string
	abstract validateJob(job: JobModel): boolean

	get search(): JobsSearchModel {
		return this.filter.search
	}

	get isRelevant(): boolean {
		return true
	}

	get selected(): boolean {
		return !!this.filter.search.state.terms.find(tid => tid === this.id)
	}

	// ----- Update state ----- //
	select(): JobsSearchModel {
		if (this.selected) return this.search
		return this.search.update({
			terms: [...this.search.state.terms, this.id]
		})
	}

	unselect(): JobsSearchModel {
		if (!this.selected) return this.search
		return this.search.update({
			terms: this.search.state.terms.filter(tid => tid !== this.id)
		})
	}

	toggle(): JobsSearchModel {
		return this.selected ? this.unselect() : this.select()
	}

}
memoizeGetters(JobTermModel)
