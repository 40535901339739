import './productspreview.scss'

import {Carousel, useCarousel} from '@codeurs/carousel'
import {CarouselNav} from 'layout/carousel/carouselnav'
import {Image} from 'layout/partials/image'
import {PreviewType} from 'layout/preview/previewtype'
import {Theme} from 'layout/theme'
import {Link} from 'util/link'
import {useMediaQuery} from 'util/mediaquery'
import {styler} from 'util/style'

const styles = styler('productspreview', ['items', 'carousel', 'item'])

export type ProductsPreviewType = {
	items: Array<PreviewType>
	cta?: {
		url: string
		title: string
	}
}

export const ProductsPreview: React.FC<ProductsPreviewType> = (data) => {
	const {items, cta} = data
	if (!items || items.length === 0) return null

	return (
		<div className={styles()}>
			<Theme.Container>
				<ProductsPreviewItems items={items} />
				{cta && cta.url && (
					<div className={styles('button')}>
						<Theme.Button to={cta.url} mod="rounded">
							<Theme.ButtonText>{cta.title}</Theme.ButtonText>
							<Theme.ButtonIcon />
						</Theme.Button>
					</div>
				)}
			</Theme.Container>
		</div>
	)
}

const ProductsPreviewItems: React.FC<{items: Array<PreviewType>}> = ({
	items
}) => {
	const tablet = useMediaQuery('(min-width: 600px)')
	const ipadPort = useMediaQuery('(min-width: 768px)')
	const desktop = useMediaQuery('(min-width: 1160px)')

	switch (true) {
		case tablet && items.length <= 2:
		case ipadPort && items.length <= 3:
		case desktop && items.length <= 5:
			return (
				<div className={styles.items()}>
					{items.map((item, i) => (
						<div key={i} className={styles.items('item')}>
							<ProductsPreviewItem {...item} />
						</div>
					))}
				</div>
			)
		default:
			return <ProductsPreviewCarousel items={items} />
	}
}

const ProductsPreviewCarousel: React.FC<{items: Array<PreviewType>}> = ({
	items
}) => {
	const carousel = useCarousel()

	return (
		<div className={styles.carousel()}>
			<Carousel {...carousel}>
				{items.map((item, i) => (
					<div
						key={i}
						className={styles.carousel('item').is({
							active: carousel.isActive(i)
						})}
					>
						<ProductsPreviewItem {...item} />
					</div>
				))}
			</Carousel>
			<CarouselNav carousel={carousel} className={styles.carousel('nav')} />
		</div>
	)
}

const ProductsPreviewItem: React.FC<PreviewType & {mod?: 'carousel'}> = ({
	url,
	image,
	mod
}) => (
	<Link to={url} className={styles.item().mod(mod)}>
		<Image aspectRatio="free" image={image} />
	</Link>
)
