import './loader.scss'

import {styler} from 'util/style'

const styles = styler('loader')

export const Loader = ({...props}) => {
	return (
		<div className={styles().mergeProps(props)}>
			<div className={styles('circle')} />
		</div>
	)
}
