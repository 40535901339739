import './bg.scss'

import GatsbyImage, {GatsbyImageProps} from 'gatsby-image'
import React from 'react'
import {Mod, styler} from 'util/style'
import {ImageType} from 'types/basictypes'

const styles = styler('bg', ['video'])

/**
 * @deprecated
 * Use BgFixed instead
 */
export const Bg: React.FC<{
	className?: string
	image: GatsbyImageProps
	overlay?: number
	mod?: Mod<'cases' | 'gradient' | 'contain'>
}> = data => {
	const {className, image, overlay = 0, mod} = data
	if (!image) return null

	return (
		<div
			className={styles()
				.with(className)
				.mod(mod)}
		>
			<GatsbyImage fluid={image.fluid} loading="auto" />
			<div
				className={styles('overlay')}
				style={{
					opacity: overlay / 100
				}}
			/>
		</div>
	)
}

/**
 * Use this instead of BG
 */
export const BgFixed: React.FC<{
	image: ImageType
	hideOverlay?: boolean
	className?: string
	mod?: Mod<'cases' | 'gradient' | 'contain'>
	mp4?: string
}> = ({image, mp4, hideOverlay, className, mod}) => {
	const showOverlay = !hideOverlay
	return (
		<div
			className={styles()
				.with(className)
				.mod(mod)}
		>
			{image && <GatsbyImage {...image} />}
			{mp4 && <video autoPlay muted loop className={styles('video')}>
				<source src={mp4} type="video/mp4" />
			</video>}
			{showOverlay && <div
				className={styles('overlay')}
				style={{
					opacity: ((image && image.meta?.overlayPercentage) || 50) / 100
				}}
			/>}
		</div>
	)
}
