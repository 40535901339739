import './mousescroll.scss'

import scrollTo from 'gatsby-plugin-smoothscroll'
import React, {HTMLProps} from 'react'
import {styler} from 'util/style'

const styles = styler('mousescroll')

export const Mousescroll: React.FC<HTMLProps<HTMLAnchorElement> & {
	id: string
}> = ({id, ...props}) => (
	<a className={styles().mergeProps(props)} onClick={() => scrollTo(id)} />
)
