import './subjects.scss'

import { Column, Grid } from 'layout/grid'
import React from 'react'
import { styler } from 'util/style'

const styles = styler('subjects', ['column', 'title', 'tag'])

export const Subjects: React.FC<{
	title: string
	children: any[]
}> = ({ title, children }) => {

	return <div className={styles()}>
		<p className={styles('title')}>{title}</p>
		<Grid columns={1} s={2} m={3} l={3}>
			{React.Children.toArray(children).map((child, i) => (
				<Column key={i}>
					{child}
				</Column>
			))}
		</Grid>
	</div>
}

export const SubjectsColumn: React.FC = ({ children }) => {

	return <div className={styles.column()}>
		{children}
	</div>
}

export const SubjectsTitle: React.FC<{
	onClick?: () => void
}> = ({ onClick, children }) => {

	return <div onClick={onClick} className={styles.title()}>
		<div className={styles.title('link')}>
			{children}
		</div>
	</div>
}

export const SubjectsTag: React.FC<{
	onClick: () => void
}> = ({ onClick, children }) => {

	return <div onClick={onClick} className={styles.tag()}>
		<div className={styles.tag('link')}>
			{children}
		</div>
	</div>
}