import {PreviewType} from 'layout/preview/previewtype'
import queryString from 'query-string'

export const READINGROOM_SEARCH_URL = (options: {
	page: number
	type?: string
	query?: string
	tags?: Array<string>
	skip?: Array<PreviewType>
}) => {
	const params = queryString.stringify({
		...options,
		skip: (options.skip || []).map(item => ''+item.node_id)
	}, {arrayFormat: 'bracket'})

	return `${process.env.GATSBY_LUMEN_URL}/api/search-readingroom?${params}`
}

export const SEARCH_URL = (options: {
	page: number,
	query: string
}) => {

	const params = queryString.stringify(options, {arrayFormat: 'bracket'})
	return `${process.env.GATSBY_LUMEN_URL}/api/search?${params}`
}

export const CONTACT_URL = () => {
	return `${process.env.GATSBY_LUMEN_URL}/api/contact`
}

export const JOBALERT_URL = () => {
	return `${process.env.GATSBY_LUMEN_URL}/api/jobalert/subscribe`
}

export const PING_URL = (langcode: string, nodeID: number | string) => {
	if(!process.env.GATSBY_DRUPAL_URL) return null
	return `${process.env.GATSBY_DRUPAL_URL}/api/ping/${langcode}/${nodeID}`
}

export const EDIT_URL = (type: 'node' | 'term', nodeID: number | string) => {
	const lang = process.env.GATSBY_DRUPAL_LANG
	if (type === 'term') {
		return `${process.env.GATSBY_DRUPAL_URL}/${lang}/taxonomy/term/${nodeID}/edit`
	}
	return `${process.env.GATSBY_DRUPAL_URL}/${lang}/node/${nodeID}/edit`
}

export const PREVIEW_URL = (token: string) => {
	return `${process.env.GATSBY_DRUPAL_URL}/api/preview/${process.env.GATSBY_DRUPAL_LANG || 'en'}/${token}`
}

export const DEV_PREVIEW_URL = (nodeID: number | string) => {
	return `${process.env.GATSBY_DRUPAL_URL}/api/devpreview/${process.env.GATSBY_DRUPAL_LANG || 'en'}/${nodeID}`
}

