import './textblock.scss'

import {Theme} from 'layout/theme'
import React from 'react'
import {WysiwygType} from 'types/basictypes'
import {Mod, styler} from 'util/style'

const styles = styler('textblock')

export type TextblockType = {
	text: WysiwygType
	display_dark?: boolean
	mod?: Mod<'gray'>
}

export const Textblock: React.FC<TextblockType> = ({text, mod, display_dark}) => {
	if (!text) return null

	return (
		<div className={styles().is({dark: display_dark}).mod(mod)}>
			<Theme.Container>
				<div className={styles('content')}>
					<Theme.Wysiwyg>{text}</Theme.Wysiwyg>
				</div>
			</Theme.Container>
		</div>
	)
}
