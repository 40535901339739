import './carouselcontrols.scss'

import {Icon} from 'assets/icon'
import React from 'react'
import {CarouselType} from 'types/basictypes'
import {styler} from 'util/style'

const styles = styler('carouselcontrols', ['previous', 'next'])

export const CarouselControls: React.FC<{
	carousel: CarouselType
}> = (data) => {
	const {carousel} = data
	if (!carousel || carousel.total <= 1) return null

	return (
		<div className={styles()}>
			<CarouselControlsPrevious carousel={carousel} />
			<CarouselControlsNext carousel={carousel} />
		</div>
	)
}

export const CarouselControlsPrevious: React.FC<{
	carousel: CarouselType
}> = (data) => {
	const {carousel} = data
	if (!carousel || carousel.total <= 1) return null

	return (
		<a
			className={styles.previous()}
			onClick={() =>
				carousel.hasPrevious()
					? carousel.goPrevious()
					: carousel.goTo(carousel.total - 1)
			}
		>
			<span className={styles.previous('icon')}>
				<Icon icon="ChevronLeft" />
			</span>
		</a>
	)
}

export const CarouselControlsNext: React.FC<{
	carousel: CarouselType
}> = (data) => {
	const {carousel} = data
	if (!carousel || carousel.total <= 1) return null

	return (
		<a
			className={styles.next()}
			onClick={() =>
				carousel.hasNext() ? carousel.goNext() : carousel.goTo(0)
			}
		>
			<span className={styles.next('icon')}>
				<Icon icon="ChevronRight" />
			</span>
		</a>
	)
}
