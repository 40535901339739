import './iconintro.scss'
import React, {useContext} from 'react'
import {Theme} from 'layout/theme'
import {Column, Grid} from 'layout/grid'
import {Image} from 'layout/partials/image'
import {IconContext} from 'layout/pages/researchproject'
import {styler} from 'util/style'
import {WysiwygType} from 'types/basictypes'

const styles = styler('iconintro')

export type IconintroType = {
  description: WysiwygType
}

export const Iconintro: React.FC<IconintroType> = ({description}) => {
  const iconContext = useContext(IconContext)
  if(!iconContext) return null
  const {marketicon} = iconContext

  return (
    <div className={styles()} id="about">
      <Theme.Container>
        <Grid columns={1} s={4} m={4} l={12} align="top">
          <Column s={1} m={1} l={2}>
            <div className={styles('icon')}>
              {marketicon && <Image image={marketicon} aspectRatio={1} />}
            </div>
          </Column>
          <Column s={3} m={3} l={10}>
            <div className={styles('description')}>
              {description && <Theme.Wysiwyg>{description}</Theme.Wysiwyg>}
            </div>
          </Column>
        </Grid>
      </Theme.Container>
    </div>
  )
}
