import './share.scss'

import { Theme } from 'layout/theme'
import React from 'react'
import { styler } from 'util/style'
import { facebookLink, twitterLink, linkedInLink } from 'util/share'
import { useCurrentHref } from 'util/uselocation'
import {Icon} from 'assets/icon'

const styles = styler('share')

export const Share: React.FC<{
	description?: string
	title?: string
	className?: any
}> = data => {
	const { description, title, className } = data
	const links = ['facebook', 'twitter', 'linkedin']

	if (!links || links.length == 0) return null

	return (
		<div className={styles().with(className)}>
			<Theme.Container>
				<p className={styles('text')}>Deel deze pagina op</p>
				<div className={styles('icons')}>
					{links.map((link, i) => (
						<Sharelink
							key={i}
							type={link}
							description={description}
							title={title}
						/>
					))}
				</div>
			</Theme.Container>
		</div>
	)
}

export const Sharelink: React.FC<{
	type: string
	description?: string
	title?: string
}> = data => {
	const { type, description, title } = data

	return (
		<a
			className={styles('link').mod(type)}
			target="_blank"
			{...sharePlatform(type, title, description)}
		>
			<span className={styles('link-icon')}>{SociallinkIcon({ type })}</span>
		</a>
	)
}

function SociallinkIcon({ type }) {
	switch (type) {
		case 'facebook':
			return <Icon icon="Facebook" />
		case 'linkedin':
			return <Icon icon="LinkedIn" />
		case 'twitter':
			return <Icon icon="Twitter" />
		default:
			return null
	}
}

function sharePlatform(type, title, description) {
	if (!type) return null

	const href = useCurrentHref()

	switch (type) {
		case 'facebook':
			return facebookLink(href)
		case 'twitter':
			return twitterLink('', href)
		case 'linkedin':
			return linkedInLink(href)
		default:
			return null
	}
}
