import './icon.scss'

import icons from 'assets/icons'
import React, {HTMLProps} from 'react'
import {styler} from 'util/style'

export type IconKey = keyof typeof icons

const style = styler('icon')

export const Icon: React.FC<{
	icon: IconKey
	inline?: boolean
} & HTMLProps<HTMLDivElement>> = ({icon, inline, ...rest}) => {

	const Icon = icons[icon]
	if(!Icon) return null
	return (
		<div {...rest} className={style().mergeProps(rest).mod({inline})}>
			<Icon />
		</div>
	)
}
