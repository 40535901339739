import './testimonials.scss'

import {Carousel, useCarousel} from '@codeurs/carousel'
import {Icon} from 'assets/icon'
import {
	CarouselControls,
	CarouselControlsNext,
	CarouselControlsPrevious
} from 'layout/carousel/carouselcontrols'
import {CarouselNav} from 'layout/carousel/carouselnav'
import {Bg} from 'layout/partials/bg'
import {Image} from 'layout/partials/image'
import {Theme} from 'layout/theme'
import React, {useState} from 'react'
import {ImageType, LinkType} from 'types/basictypes'
import {styler} from 'util/style'
import {useInterval} from 'util/useinterval'

const styles = styler('testimonials', ['item'])

export type TestimonialsItemType = {
	name: string
	quote: string
	link: LinkType
	avatar_image: ImageType
	bg_image: ImageType
}

export type TestimonialsType = {
	items: Array<TestimonialsItemType>
}

export const Testimonials: React.FC<TestimonialsType> = (data) => {
	const {items} = data
	const carousel = useCarousel()
	const [isTouched, setIsTouched] = useState(false)
	if (!items || items.length === 0) return null

	useInterval(() => {
		if (isTouched) return
		if (carousel.hasNext()) carousel.goNext()
		else carousel.goTo(0)
	}, 7500)

	return (
		<div
			className={styles()}
			onTouchStart={() => setIsTouched(true)}
			onMouseDown={() => setIsTouched(true)}
		>
			{items.map((testimonial, i) => (
				<Bg
					key={i}
					overlay={50}
					image={testimonial.bg_image}
					className={styles('bg').is({active: carousel.isActive(i)})}
				/>
			))}
			<Theme.Container>
				<div className={styles('block')}>
					{carousel?.total! > 1 && (
						<div className={styles('block-previous')}>
							<CarouselControlsPrevious carousel={carousel} />
						</div>
					)}
					<Carousel {...carousel}>
						{items.map((testimonial, i) => (
							<div
								key={i}
								className={styles('block-content').is({
									active: carousel.isActive(i)
								})}
							>
								<TestimonialsItem {...testimonial} />
							</div>
						))}
					</Carousel>
					{carousel?.total! > 1 && (
						<div className={styles('block-next')}>
							<CarouselControlsNext carousel={carousel} />
						</div>
					)}
					<CarouselNav carousel={carousel} className={styles('block-nav')} />
				</div>
			</Theme.Container>
		</div>
	)
}

const TestimonialsItem: React.FC<TestimonialsItemType> = ({
	avatar_image,
	name,
	quote,
	link
}) => {
	return (
		<div className={styles.item()}>
			<div className={styles.item('author')}>
				{avatar_image && (
					<div className={styles.item('author-image')}>
						<Image image={avatar_image} aspectRatio={1} />
					</div>
				)}
				<p className={styles.item('author-name')}>{name}</p>
			</div>
			<div className={styles.item('quote')} title={quote}>
				<Theme.TrustedText>{quote}</Theme.TrustedText>
			</div>
			{link && link.url && (
				<div className={styles.item('link')}>
					<Theme.Link mod="inherit" to={link.url}>
						<Theme.LinkText>{link.title}</Theme.LinkText>
						<Theme.LinkIcon />
					</Theme.Link>
				</div>
			)}
		</div>
	)
}
