import './textpreview.scss'

import {Theme} from 'layout/theme'
import React from 'react'
import {LinkType} from 'types/basictypes'
import {useTranslation} from 'util/i18'
import {styler} from 'util/style'

const styles = styler('textpreview')

export type TextpreviewType = {
	text: string
	link?: LinkType
}

export const Textpreview: React.FC<TextpreviewType> = (data) => {
	const {text, link} = data
	const {read_more} = useTranslation()

	return (
		<div className={styles()}>
			{text && <Theme.Wysiwyg>{text}</Theme.Wysiwyg>}
			{link && link.url && (
				<Theme.Link to={link?.url} className={styles('link')}>
					<Theme.LinkText>{link?.title || read_more}</Theme.LinkText>
					<Theme.LinkIcon />
				</Theme.Link>
			)}
		</div>
	)
}
