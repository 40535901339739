import './relatedjobs.scss'

import {Column, Grid} from 'layout/grid'
import {Theme} from 'layout/theme'
import React, {useContext} from 'react'
import {WysiwygType} from 'types/basictypes'
import {styler} from 'util/style'
import {Link} from 'util/link'
// import {useStaticJobsQuery} from './relatedjobs_graph'
import {BlocksContext} from 'blocks/blockscontext'
import {useLinks} from 'util/links'

const styles = styler('relatedjobs', ['item'])

export type RelatedjobsType = {
	text_left?: WysiwygType
	title_right?: string
}

export type RelatedjobItemType = {
	title: string
	short_description?: WysiwygType
	slug?: string
}

export const Relatedjobs: React.FC<RelatedjobsType> = data => {
	const {text_left, title_right} = data
	const {jobs} = useLinks()
	const {relatedJobs} = useContext(BlocksContext)

	// let jobItems = [...related, ...useStaticJobsQuery()]
	// jobItems = jobItems
	// 	.filter((it1, i) => jobItems.findIndex(it2 => it2.slug === it1.slug) === i)
	// 	.slice(0, 4)

	return (
		<div className={styles()}>
			<Theme.Container>
				<Grid columns={1} xs={1} s={2} m={2} l={2}>
					<Column>
						<Theme.Wysiwyg className={styles('left')}>
							{text_left}
						</Theme.Wysiwyg>
					</Column>
					<Column>
						<div className={styles('right')}>
							<Theme.H4 className={styles('right-title')}>
								{title_right}
							</Theme.H4>
							{relatedJobs.map(item => (
								<RelatedjobsItem
									title={item.title}
									short_description={item.short_description}
									slug={item.slug}
									key={item.slug}
								/>
							))}
							<Theme.Button to={jobs.url} className={styles('right-button')}>
								{jobs.title}
							</Theme.Button>
						</div>
					</Column>
				</Grid>
			</Theme.Container>
		</div>
	)
}

export const RelatedjobsItem: React.FC<RelatedjobItemType> = data => {
	const {title, short_description, slug} = data

	return (
		<Link to={slug ? slug : ''} className={styles.item()}>
			{title && <div className={styles.item('title')}>{title}</div>}
			{short_description && (
				<Theme.Wysiwyg className={styles.item('description')}>
					{short_description}
				</Theme.Wysiwyg>
			)}
		</Link>
	)
}
