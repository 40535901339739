import {globalHistory, navigate} from '@reach/router'
import queryString from 'query-string'
import {useEffect, useState} from 'react'

/**
 * Copy paste from here: https://github.com/reach/router/issues/203#issuecomment-453941158
 * Because gatsby uses the outdated reach router which has no support for hooks (and will be merged into react router)
 */
export const useLocation = () => {
	const initialState = {
		location: globalHistory.location,
		navigate: globalHistory.navigate
	}

	const [state, setState] = useState(initialState)
	useEffect(() => {
		let unmounted = false
		const removeListener = globalHistory.listen(params => {
			const { location } = params
			const newState = Object.assign({}, initialState, { location })
			if (!unmounted) setState(newState)
		})
		return () => {
			unmounted = true
			removeListener()
		}
	}, [initialState])
	return state
}

export type SearchParams = {
	terms: number[],
	tags: string[],
	filters: string[],
	page: number,
	type: string,
	query: string
}

export const useUrlParams = () => {
	const {location} = useLocation()

	const params = queryString.parse(location.search, {
		arrayFormat: 'bracket'
	})

	const createUrl = (params: any, baseUrl?: string) => {
		const paramsString = queryString.stringify(params, {arrayFormat: 'bracket'})
		return (baseUrl || location.pathname) + (paramsString ? '?' + paramsString : '')
	}

	const setParams = (params: any) => {
		navigate(createUrl(params))
	}

	return {
		params,
		createUrl,
		setParams
	}
}

export const useStringUrlParams = () => {
	const {params, ...result} = useUrlParams()
	return {
		params: Object.keys(params).reduce((acc, key) => {
			return {
				...acc,
				[key]: typeof params[key] === 'string' ? params[key] : undefined
			}
		}, {}) as Record<string, string>,
		...result
	}
}

export function useCustomUrlParams<P>(parser: (ParsedQuery) => P, basePath: string) {
	const {params, createUrl, setParams} = useUrlParams()

	return {
		params: parser(params),
		createUrl: (params: Partial<P>) => {
			return createUrl({
				...parser({}),
				...params
			}, basePath)
		},
		updateParams: (newParams: Partial<P>) => {
			return setParams({...params, scrolllock: true, ...newParams})
		},
		setParams: (params: P) => {
			return setParams({scrolllock: true, ...params})
		}
	}
}

export const useSearchParams = () => {
	const {location: {pathname}} = useLocation()

	const result = useCustomUrlParams<SearchParams>(parsed => ({
		page: parseInt(typeof parsed.page === 'string' ? parsed.page : '0') || 0,
		terms: Array.isArray(parsed.terms) ? parsed.terms.map(t => parseInt(t)) : [],
		tags: Array.isArray(parsed.terms) ? parsed.terms : [],
		filters: Array.isArray(parsed.filters) ? parsed.filters : [],
		type: typeof parsed.type === 'string' ? parsed.type : '',
		query: typeof parsed.query === 'string' ? parsed.query : ''
	}), pathname)
	const params = result.params

	return {
		...result,
		termsSet: new Set(result.params.terms),
		toggleTerm: id => {
			if(!id) return
			const termsSet = new Set(params.terms)
			if(termsSet.has(id)) {
				result.updateParams({terms: params.terms.filter(term => term !== id)})
			}
			else {
				result.updateParams({terms: [...params.terms, id]})
			}
		}
	}
}

export const usePathname = () => {
	const {
		location: { pathname }
	} = useLocation()

	return pathname
}

export const useCurrentHref = () => {
	const {
		location: { href }
	} = useLocation()

	return href
}

export const useOrigin = () => {
	const {
		location: { origin }
	} = useLocation()

	return origin
}
