import './profile.scss'

import GatsbyImage, {GatsbyImageProps} from 'gatsby-image'
import {Column, Grid} from 'layout/grid'
import {Theme} from 'layout/theme'
import {styler} from 'util/style'
import {Image} from 'layout/partials/image'

const styles = styler('profile')

export type ProfileType = {
	image?: GatsbyImageProps
	text?: string
}

export const Profile: React.FC<ProfileType> = data => {
	const {image, text} = data

	return (
		<div className={styles()}>
			<Theme.Container>
				{
					<Grid columns={1} s={1} m={2} l={12} align="top">
						<Column l={4}>
							{image && <Image aspectRatio='free' image={image} />}
						</Column>
						<Column l={8}>
							<div className={styles('content')}>
								{text && <Theme.Wysiwyg>{text}</Theme.Wysiwyg>}
							</div>
						</Column>
					</Grid>
				}
			</Theme.Container>
		</div>
	)
}
