import {Block, BlockType} from 'blocks/block'
import {Blocks} from 'blocks/blocks'
import {Layout} from 'layout/layout'
import React, {createContext} from 'react'
import {Mod, styler} from 'util/style'
import {ImageType, WysiwygType} from 'types/basictypes'

const styles = styler('researchproject')

export type IconContextType = {
	marketicon?: ImageType
	description: WysiwygType
}

export const IconContext = createContext<IconContextType>(null)

export type ResearchprojectType = {
	blocks: Array<BlockType>
	description?: WysiwygType
	marketicon?: ImageType
	mod?: Mod<'preview'>
}

export const Researchproject: React.FC<ResearchprojectType> = data => {
	const {blocks, description, marketicon, mod} = data

	return (
		<Layout>
			<div className={styles().mod(mod)}>
				<IconContext.Provider value={{description, marketicon}}>
					<Blocks>
						{blocks.map((block, i) => (
							<Block {...block} blocks={blocks} key={i} pageType={'icon'} />
						))}
					</Blocks>
				</IconContext.Provider>
			</div>
		</Layout>
	)
}
