import './readingroom.scss'
import {Populair} from 'blocks/readingroom/components/populair'
import {useStaticReadingroomItemsQuery} from 'blocks/readingroom/static/readingroom_graph_items'
import {useStaticReadingroomPopulairQuery} from 'blocks/readingroom/static/readingroom_graph_populair'
import {TagModel} from 'blocks/readingroom/static/readingroom_graph_tags'
import {PreviewType} from 'layout/preview/previewtype'
import React from 'react'
import useSWR from 'swr/esm/use-swr'
import {fetcher} from 'util/fetcher'
import {useTranslation} from 'util/i18'
import {styler} from 'util/style'
import {READINGROOM_SEARCH_URL} from 'util/urls'
import {useCustomUrlParams} from 'util/uselocation'

import {Magazine, MagazineItems, MagazineTextualItems,} from 'blocks/readingroom/components/magazine'
import {ReadingroomSearch} from 'blocks/readingroom/components/readingroomsearch'
import {Highlighted} from 'blocks/readingroom/components/highlighted'
import {SearchresultsEmpty, SearchresultsLoader, SearchresultsMore} from 'layout/search/searchresults'
import {useLinks} from 'util/links'

const styles = styler('readingroom', ['static', 'dynamic', 'noresults', 'more', 'loader'])

export type ReadingroomSearchParams = {
	page: number,
	query: string,
	type: string,
	tags: string[]
}

export type ReadingroomType = {
	categories: Array<string>
	highlighted: Array<PreviewType>
}

export const useReadingroomSearchParams = () => {
	const {readingroom} = useLinks()
	const result = useCustomUrlParams<ReadingroomSearchParams>((params) => ({
		page: parseInt(typeof params.page === 'string' ? params.page : '') || 0,
		query: typeof params.query === 'string' ? params.query : '',
		type: typeof params.type === 'string' ? params.type : '',
		tags: Array.isArray(params.tags) ? params.tags : []
	}), readingroom.url)
	const params = result.params

	return {
		...result,
		toggleTag: (tag: TagModel) => {
			if (!tag) return
			if (new Set(params.tags).has(tag.name)) {
				result.updateParams({tags: params.tags.filter(t => t !== tag.name)})
			} else {
				result.updateParams({tags: [...params.tags, tag.name]})
			}
		}
	}
}

export const Readingroom: React.FC<ReadingroomType> = data => {
	const { highlighted, categories } = data
	const { params } = useReadingroomSearchParams()

	const isDynamic = params.type || params.query || params.tags.length

	return (
		<div className={styles()}>
			<ReadingroomSearch
				categories={categories}
			/>
			{isDynamic ?
				<ReadingroomDynamic /> :
				<ReadingroomStatic highlighted={highlighted} />
			}
		</div>
	)
}

export const ReadingroomStatic: React.FC<{
	highlighted: Array<PreviewType>
}> = ({ highlighted }) => {
	const highlightedUrls = new Set(highlighted.map(h => h.url))
	const initialItems = useStaticReadingroomItemsQuery().filter(item => !highlightedUrls.has(item.url))
	const populair = useStaticReadingroomPopulairQuery()
	const { params: {page}, updateParams } = useReadingroomSearchParams()

	const url = page && READINGROOM_SEARCH_URL({ page: page - 1, skip: [...initialItems, ...highlighted] })
	const { data } = useSWR(url, fetcher)

	const allItems = [...initialItems, ...(data?.items || [])]
	const nrOfItems = 12 + page * 12
	const items = allItems.slice(0, nrOfItems)
	const showMoreItems = allItems.length > nrOfItems

	return (
		<Magazine>
			<Highlighted highlighted={highlighted[0]} />
			<MagazineItems items={items.slice(0, 4)} />
			<Populair items={populair} />
			<MagazineItems items={items.slice(4, 12)} />
			<Highlighted highlighted={highlighted[1]} />
			<MagazineItems items={items.slice(12)} />
			{showMoreItems && <SearchresultsMore onClick={() => updateParams({ page: page + 1 })} />}
		</Magazine>
	)
}

export const ReadingroomDynamic: React.FC = () => {
	const { params, updateParams } = useReadingroomSearchParams()

	const url = READINGROOM_SEARCH_URL(params)
	const { data } = useSWR(url, fetcher)
	const { readingroom: { textualTags } } = useTranslation()
	const textual = !!params.tags.find(tag => new Set(textualTags).has(tag))

	if (!data) return <SearchresultsLoader />
	if (!data.items.length) return <SearchresultsEmpty />

	const allItems = data?.items
	const nrOfItems = (params.page + 1) * 12
	const items = allItems.slice(0, nrOfItems)
	const showMore = allItems.length > nrOfItems

	if (textual) {
		return <MagazineTextualItems items={items} />
	}

	return (
		<Magazine>
			<MagazineItems items={items} />
			{showMore && <SearchresultsMore onClick={() => updateParams({ page: params.page + 1})} />}
		</Magazine>
	)
}
