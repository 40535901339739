import './carouselnav.scss'

import React from 'react'
import {CarouselType} from 'types/basictypes'
import {styler} from 'util/style'

const styles = styler('carouselnav')

export const CarouselNav: React.FC<{
	className?: string
	carousel: CarouselType
	items?: Array<any>
}> = (data) => {
	const {className, carousel} = data
	if (Array.from(Array(carousel.total)).length <= 1) return null
	return (
		<div className={styles().with(className)}>
			{Array.from(Array(carousel.total)).map((_, i) => (
				<a
					key={i}
					className={styles('item').is({
						active: carousel.current === i
					})}
					onClick={() => carousel.goTo(i)}
				/>
			))}
		</div>
	)
}
