import './imagetext.scss'

import {Carousel, useCarousel} from '@codeurs/carousel'
import {CarouselNav} from 'layout/carousel/carouselnav'
import {Column, Grid} from 'layout/grid'
import {Image} from 'layout/partials/image'
import {Theme} from 'layout/theme'
import React, {useState} from 'react'
import {ImageType, WysiwygType} from 'types/basictypes'
import {styler} from 'util/style'
import {useInterval} from 'util/useinterval'

const styles = styler('imagetext', ['imgcontainer', 'content'])

export type ImagetextType = {
	images: Array<ImageType>
	image_position: 'left' | 'right'
	text: WysiwygType
}

export const Imagetext: React.FC<ImagetextType> = (data) => {
	const {images, image_position = 'right', text} = data

	return (
		<div className={styles()}>
			<Theme.Container>
				<Grid columns={1} s={2} m={2} l={2} align="middle">
					<Column>
						{image_position === 'right' ? (
							<ImagetextContent text={text} />
						) : (
							<ImagetextImgcontainer images={images} />
						)}
					</Column>
					<Column>
						{image_position === 'right' ? (
							<ImagetextImgcontainer images={images} />
						) : (
							<ImagetextContent text={text} />
						)}
					</Column>
				</Grid>
			</Theme.Container>
		</div>
	)
}

export const ImagetextContent: React.FC<{
	text: WysiwygType
}> = (data) => {
	const {text} = data

	return (
		<div className={styles.content()}>
			{text && <Theme.Wysiwyg>{text}</Theme.Wysiwyg>}
		</div>
	)
}

export const ImagetextImgcontainer: React.FC<{
	images: Array<ImageType>
}> = (data) => {
	const {images} = data
	const carousel = useCarousel()
	const [isTouched, setIsTouched] = useState(false)

	useInterval(() => {
		if (isTouched) return
		if (carousel.hasNext()) carousel.goNext()
		else carousel.goTo(0)
	}, 7500)

	if (images && images.length === 1)
		return <Image image={images[0]} aspectRatio={1} />

	return (
		<div
			className={styles.imgcontainer()}
			onTouchStart={() => setIsTouched(true)}
			onMouseDown={() => setIsTouched(true)}
		>
			<Carousel {...carousel}>
				{images &&
					images.map((image, i) => (
						<div key={i} className={styles.imgcontainer('item')}>
							<Image image={image} aspectRatio={1} />
						</div>
					))}
			</Carousel>
			<div className={styles.imgcontainer('nav')}>
				<CarouselNav carousel={carousel} items={images} />
			</div>
		</div>
	)
}
