import './editcms.scss'

import React, {useContext, useEffect, useState} from 'react'
import { styler } from 'util/style'
import { EDIT_URL } from 'util/urls'
import unfetch from 'unfetch'
import {useUrlParams} from 'util/uselocation'
import {BlocksContext} from 'blocks/blockscontext'

const styles = styler('editcms')

export const EditCMS: React.FC<{
	type: 'node' | 'term',
	id?: string | number
}> = ({ type, id }) => {
	const [isAdmin, updateData] = useState(false)
	const {params: {devpreview}, setParams} = useUrlParams()

	const {url} = useContext(BlocksContext)
	useEffect(() => {
		updateData(!!localStorage.getItem('is_drupal_admin'))
	}, [])

	if (!id) return null
	if (!process.env.GATSBY_ENABLE_EDIT && !isAdmin) return null

	const href = EDIT_URL(type, id)

	return <div className={styles()}>
		<a href={href}  className={styles('button')} target="__blank">
			Edit
		</a>
		{process.env.GATSBY_LIVE_URL && <a href={process.env.GATSBY_LIVE_URL + url}  className={styles('button')} target="__blank">
			View on imec-int
		</a>}
		{process.env.GATSBY_REFRESH_URL && (
			<a className={styles('button')} onClick={() => {
				console.log(process.env.GATSBY_REFRESH_URL)
				unfetch(process.env.GATSBY_REFRESH_URL, {method: 'POST'})
			}}>
			Refresh Data
			</a>
		)}
		{devpreview && (
			<a className={styles('button')} onClick={() => {
				setParams({devpreview: undefined})
			}}>
				Switch to live
			</a>
		)}
		{!devpreview && (
			<a className={styles('button')} onClick={() => {
				setParams({devpreview: 1})
			}}>
				Switch to preview
			</a>
		)}
	</div>
}
