import { Block, BlockType } from 'blocks/block'
import { Blocks } from 'blocks/blocks'
import { Layout } from 'layout/layout'
import React from 'react'
import { Mod } from 'util/style'

export type PageType = {
	blocks: Array<BlockType>
	mod?: Mod<'preview'>
}

export const Page: React.FC<PageType> = data => {
	const { blocks, mod } = data

	const cases = blocks.length && blocks[0].type === 'cases' ? blocks[0] : null
	const filteredBlocks = cases ? blocks.slice(1) : blocks

	return (
		<Layout cases={cases} mod={mod}>
			<Blocks>
				{filteredBlocks.map((block, i) => (
					<Block
						{...block}
						blocks={filteredBlocks}
						key={i}
					/>
				))}
			</Blocks>
		</Layout>
	)
}
