import { ProductsTermModel, ProductsTermsState } from 'blocks/products/models/ProductsTermModel'
import memoizeGetters from 'memoize-getters'
import { ProductsSearchModel } from 'blocks/products/models/ProductsSearchModel'
import { ProductModel } from 'blocks/products/models/ProductModel'

export type ProductsFilterState = {
  name: string
  tid: number
  sort: number
  children: Array<ProductsTermsState>
}

export type ProductsFilterKey = string

export class ProductsFilterModel {

  constructor(readonly search: ProductsSearchModel, private state: ProductsTermsState) { }

  get key(): ProductsFilterKey {
    return `filter${this.state.tid}`
  }

  get weight(): number {
    return this.state.sort
  }

  get isActive(): boolean {
    return !!this.terms.find(term => term.isActive)
  }

  get title(): string {
    return this.state.name
  }

  get terms(): Array<ProductsTermModel> {
    return this.state.children.map(state => new ProductsTermModel(this, state))
  }

  validateProduct(product: ProductModel): boolean {
    return !!this.terms.find(term => term.validateProduct(product))
  }

}
memoizeGetters(ProductsFilterModel)
