import './contactform.scss'

import {Form, Formik} from 'formik'
import {Theme} from 'layout/theme'
import React from 'react'
import {WysiwygType} from 'types/basictypes'
import {t} from 'util/i18'
import {slugify} from 'util/slugify'
import {styler} from 'util/style'
import {FormField, FormRow} from 'layout/form/form'
import fetch from 'unfetch'
import {CONTACT_URL} from 'util/urls'
import {useStringUrlParams, useUrlParams} from 'util/uselocation'
import {navigate} from '@reach/router'
import {useLinks} from 'util/links'
import {RequestForm} from 'blocks/request/request'

const styles = styler('contactform', ['form', 'thanks'])

type FormData = {
	identification?: string
	topic?: string
	firstname?: string
	lastname?: string
	email?: string
	phone?: string
	question?: string
}

export type ContactFormType = {
	company_fields: boolean
	enable_bar: boolean
	title: WysiwygType
}

export const Contactform: React.FC<ContactFormType> = data => {
	const {company_fields, title, enable_bar} = data
	const {params: {origin, contactperson, identification, topic}} = useStringUrlParams()
	const {contactformsubmit} = useLinks()

	return (
		<div className={styles()}>
			<Formik<FormData>
				initialValues={{
					identification,
					topic
				}}
				onSubmit={(values, {setErrors, resetForm}) =>
					fetch(CONTACT_URL(), {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json'
						},
						body: JSON.stringify({...values, origin, contactperson})
					}).then(response => {
						if (response.ok) {
							resetForm()
							navigate(contactformsubmit.url)
						} else {
							return response.json().then(errors => setErrors(errors))
						}
					})
				}
			>
				<Form>
					{enable_bar && <div className={styles('request')}>
						<Theme.Container>
							<h3 className={styles('request-title')}>{title}</h3>
							<RequestForm/>
						</Theme.Container>
					</div>}
					<div className={styles('form')}>
						<Theme.Container>
							{!enable_bar && (
								<Theme.H2
									id={slugify(title)}
									className={styles.form('title')}
									mod="blueline"
								>
									{title}
								</Theme.H2>
							)}
							<ContactformForm title={title} company_fields={company_fields} />
						</Theme.Container>
					</div>
				</Form>
			</Formik>
		</div>
	)
}

export const ContactformForm: React.FC<{
	title?: string
	company_fields: boolean
}> = data => {
	const {title, company_fields} = data
	const {params: {origin, contactperson}} = useUrlParams()
	const {contactformsubmit} = useLinks()

	return (
		<div className={styles.form()}>
				<div className={styles.form('content')}>
						<FormRow>
							<FormField
								mod={{50: true}}
								name="firstname"
								required
								label={t('form.firstname')}
							/>
							<FormField
								mod={{50: true}}
								name="lastname"
								required
								label={t('form.lastname')}
							/>
						</FormRow>
						<FormField
							name="email"
							type="email"
							required
							label={t('form.email')}
						/>
						<FormField
							name="phone"
							type="tel"
							required
							label={t('form.phone')}
						/>
						{company_fields && <>
							<br/>
							<FormField
								name="company"
								required
								label={t('form.company')}
							/>
							<FormField
								name="address"
								required
								label={t('form.address')}
							/>
							<FormRow>
								<FormField
									mod={{50: true}}
									name="zipcode"
									required
									label={t('form.zipcode')}
								/>
								<FormField
									mod={{50: true}}
									name="city"
									required
									label={t('form.city')}
								/>
							</FormRow>
							<br/>
						</>}
						<FormField
							as="textarea"
							component="textarea"
							name="message"
							required
							label={t('form.message')}
						/>
						<div className={styles.form('button')}>
							<Theme.Button as="button" type="submit" mod="rounded">
								<Theme.ButtonText>{t('form.submit')}</Theme.ButtonText>
								<Theme.ButtonIcon />
							</Theme.Button>
						</div>
				</div>
		</div>
	)
}

export const ContactformThanks: React.FC<{
	title?: string
	text?: WysiwygType
}> = data => {
	const {title, text} = data

	return (
		<div className={styles.thanks()}>
			<Theme.Container>
				<Theme.H2 className={styles.thanks('title')}>
					{title ? title : t('form.thanks.title')}
				</Theme.H2>
				<Theme.Wysiwyg>{text ? text : t('form.thanks.text')}</Theme.Wysiwyg>
			</Theme.Container>
		</div>
	)
}
