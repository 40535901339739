import './error.scss'
import React from 'react'
import {WysiwygType} from 'types/basictypes'
import {Theme} from 'layout/theme'
import {styler} from 'util/style'

const styles = styler('error')

export type ErrorType = {
  description: WysiwygType
}

export const Error: React.FC<ErrorType> = data => {
  const { description } = data

  return (
    <div className={styles()}>
      <Theme.Container>
        <Theme.TrustedText>
          {description}
        </Theme.TrustedText>
      </Theme.Container>
    </div>
  )
}
