import './cardpreview.scss'

import {Image} from 'layout/partials/image'
import {Theme} from 'layout/theme'
import React from 'react'
import {ImageType} from 'types/basictypes'
import {Link} from 'util/link'
import {Mod, styler} from 'util/style'

const styles = styler('cardpreview', ['image', 'content'])

export type CardpreviewType = CardpreviewImageType &
	CardpreviewContentType & {
		display_background_dark?: boolean
	}

type CardpreviewImageType = {
	url?: string
	image?: ImageType
	display_square?: boolean
	display_padding?: boolean
	display_fallback?: boolean
}

type CardpreviewContentType = {
	text: string
	text_mod?: Mod<'small' | 'full'>
}

export const Cardpreview: React.FC<CardpreviewType> = (data) => {
	const {
		url,
		image,
		text,
		text_mod,
		display_square = false,
		display_padding = false,
		display_fallback = true,
		display_background_dark = false
	} = data

	return (
		<div className={styles().mod({bgdark: display_background_dark})}>
			<CardpreviewImage
				url={url}
				image={image}
				display_square={display_square}
				display_padding={display_padding}
				display_fallback={display_fallback}
			/>
			<CardpreviewContent text={text} text_mod={text_mod} />
		</div>
	)
}

const CardpreviewImage: React.FC<CardpreviewImageType> = ({
	url,
	image,
	display_square,
	display_padding,
	display_fallback
}) => {
	if (!image) return null

	return (
		<Link
			to={url}
			className={styles
				.image()
				.mod({square: display_square})
				.mod({padding: display_padding})
				.mod({fallback: display_fallback})}
		>
			<Image image={image} aspectRatio={display_square ? 1 : 300 / 130} />
		</Link>
	)
}

const CardpreviewContent: React.FC<CardpreviewContentType> = ({
	text,
	text_mod
}) => {
	if (!text) return null

	return (
		<div className={styles.content().mod(text_mod)}>
			<Theme.Wysiwyg>{text}</Theme.Wysiwyg>
		</div>
	)
}
