import './imageblock.scss'
import {Theme} from 'layout/theme'
import React from 'react'
import {ImageType, LinkType, WysiwygType} from 'types/basictypes'
import {Link} from 'util/link'
import {Mod, styler} from 'util/style'
import {Image} from 'layout/partials/image'

const styles = styler('imageblock')

export type ImageblockType = {
	title?: string
	image: ImageType
	caption?: WysiwygType
	link: LinkType
	size?: Mod<'small' | 'medium' | 'large' | 'fullwidth' | 'leaflet'>
}

export const Imageblock: React.FC<ImageblockType> = data => {
	const {title, image, caption, link, size = 'large'} = data
	if (!image) return null

	return (
		<div className={styles()}>
			<Theme.Container>
				{title && (
					<div className={styles('title')}>
						{title && <Theme.H2 mod={['blueline']}>{title}</Theme.H2>}
					</div>
				)}

				<div className={styles('content').mod(size)}>
					<div className={styles('image')}>
						{link ?
							<Link to={link.url}><Image aspectRatio='free' image={image} /></Link> :
							<Image aspectRatio='free' image={image} />
						}
					</div>
					{caption && (
						<div className={styles('caption')}>
							<Theme.Wysiwyg>{caption}</Theme.Wysiwyg>
						</div>
					)}
				</div>
			</Theme.Container>
		</div>
	)
}
