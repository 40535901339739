import './breadcrumbs.scss'

import {Icon} from 'assets/icon'
import React, {Fragment} from 'react'
import {Link} from 'util/link'
import {Mod, styler} from 'util/style'
import {useLinks} from 'util/links'

const styles = styler('breadcrumbs')

export type ParentType = {
	title?: string
	url?: string
}

export const Breadcrumbs: React.FC<{
	parents: Array<ParentType>
	pageTitle: string
	pageurl?: string
	mod?: Mod<'small'>
}> = data => {
	const {parents, pageTitle, pageurl, mod} = data
	if (!pageTitle) return null

	const reversedParents = parents ? [...parents] : []
	reversedParents.reverse()

	const {home} = useLinks()

	return (
		<div className={styles().mod(mod)}>
			<Link to={home.url} className={styles('icon')}>
				<Icon icon="Home" />
			</Link>

			{reversedParents.map((parent, i) => (
				<Fragment key={i}>
					<span className={styles('divider')}>/</span>
					<Link to={parent.url} className={styles('link')}>
						{parent.title}
					</Link>
				</Fragment>
			))}
			<span className={styles('divider')}>/</span>
			{!pageurl && <span className={styles('link')}>{pageTitle}</span>}
			{pageurl && (
				<Link to={pageurl} className={styles('link')}>
					{pageTitle}
				</Link>
			)}
		</div>
	)
}
