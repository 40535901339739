import { useStaticQuery, graphql } from 'gatsby'
import { FooterlinkType, FootercategoriesType } from './footer'

export const useStaticFooterQuery = () => {
	const footer_data = useStaticQuery(graphql`
		query {
			allTaxonomyTermFooterSocial(sort: {fields: weight, order: ASC}) {
				links: nodes {
					name
					fields {
						field_footer_social_link {
							url
						}
					}
				}
			}
			allTaxonomyTermFooterCategories(sort: {fields: weight, order: ASC}) {
				categories: nodes {
					field_footer_category_title
					field_footer_category_subtitle
					fields {
						# field_footer_category_links {
						# 	title
						# 	url
						# }
						field_link_title {
							url
						}
					}
				}
			}
			allTaxonomyTermFooterMenu(sort: {fields: weight, order: ASC}) {
				links: nodes {
					fields {
						field_footer_menu_link {
							title
							url
						}
					}
				}
			}
			allTaxonomyTermFooterLinks(sort: {fields: weight, order: ASC}) {
				links: nodes {
					name
					fields {
						field_footer_links_link {
							url
						}
					}
				}
			}
		}
	`)

	const social: FooterlinkType = {
		links: footer_data.allTaxonomyTermFooterSocial.links.map(
			item =>
				(item = {
					title: (item.name || '').toLowerCase(),
					url: item && item.fields && item.fields.field_footer_social_link.url
				})
		)
	}

	const categories: FootercategoriesType = {
		categories: footer_data.allTaxonomyTermFooterCategories.categories.map(
			item => ({
				title: item.field_footer_category_title,
				titleUrl: item && item.fields && item.fields.field_link_title.url,
				subtitle: item.field_footer_category_subtitle
				// links: item && item.fields && item.fields.field_footer_category_links
			})
		)
	}

	const menu: FooterlinkType = {
		links: footer_data.allTaxonomyTermFooterMenu.links.map(
			item =>
				(item = {
					title:
						item && item.fields && item.fields.field_footer_menu_link.title,
					url: item && item.fields && item.fields.field_footer_menu_link.url
				})
		)
	}

	const links: FooterlinkType = {
		links: footer_data.allTaxonomyTermFooterLinks.links.map(
			item =>
				(item = {
					title: item.name,
					url: item && item.fields && item.fields.field_footer_links_link.url
				})
		)
	}

	return { social, links, categories, menu }
}
