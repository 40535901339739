import {CalendarStaticQuery} from 'types/graphtypes'
import {CalendarEventType} from 'blocks/calendar/calendar'
import {graphql, useStaticQuery} from 'gatsby'

export const getCalendarStaticItems: (
  query: CalendarStaticQuery
) => Array<CalendarEventType> = query => {
  return query.allNodeEvent.events.map(item => ({
    title: item.title,
    date: item.field_date,
    endDate: item.field_end_date,
    urls: item.fields.slug
  }))
}

export const useStaticCalendarQuery = () => {
  const data = useStaticQuery(graphql`
		query CalendarStatic {
			allNodeEvent(
				limit: 6
				sort: {fields: field_date}
				filter: {
					field_hide_from_calendar: {ne: true}
				}
			) {
				events: nodes {
					title
					field_date(formatString: "D MMM YYYY", locale: "nl")
					field_end_date(formatString: "D MMM YYYY", locale: "nl")
					fields {
						slug
					}
				}
			}
		}
	`)
  return getCalendarStaticItems(data)
}
