import React, {useEffect, useRef, useState} from 'react'

export function useInterval(callback, delay) {
	const savedCallback = useRef(null)

	// Remember the latest callback.
	useEffect(() => {
		savedCallback.current = callback
	}, [callback])

	// Set up the interval.
	useEffect(() => {
		if (!savedCallback) return null
		function tick() {
			savedCallback.current()
		}
		if (delay !== null) {
			const id = setInterval(tick, delay)
			return () => clearInterval(id)
		}
	}, [delay])
}
