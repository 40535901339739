import './videoblock.scss'

import {Theme} from 'layout/theme'
import React from 'react'
import {WysiwygType} from 'types/basictypes'
import {Mod, styler} from 'util/style'
import {Trusted} from 'util/trusted'

const styles = styler('videoblock')

export type VideoblockType = {
	embed_code: string
	caption?: WysiwygType
	size?: Mod<'small' | 'medium' | 'large' | 'fullwidth'>
}

export const VideoBlock: React.FC<VideoblockType> = data => {
	const {embed_code, caption, size = 'large'} = data
	if (!embed_code) return null

	return (
		<div className={styles()}>
			<Theme.Container>
				<div className={styles('content').mod(size)}>
					<div className={styles('iframecontainer')}>
						<Trusted>{embed_code}</Trusted>
					</div>
					{caption && <p className={styles('caption')}>{caption}</p>}
				</div>
			</Theme.Container>
		</div>
	)
}
