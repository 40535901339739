import './cases.scss'

import {Carousel, useCarousel} from '@codeurs/carousel'
import {CarouselNav} from 'layout/carousel/carouselnav'
import {Mousescroll} from 'layout/mousescroll'
import {Bg} from 'layout/partials/bg'
import {Theme} from 'layout/theme'
import React, {useState} from 'react'
import {ImageType, LinkType} from 'types/basictypes'
import {Mod, styler} from 'util/style'
import {useInterval} from 'util/useinterval'

const styles = styler('cases', ['item'])

export type CasesType = {
	items: Array<CasesItemType>
	mod?: Mod<'fullheight'>
}

export type CasesItemType = {
	image: ImageType
	overlay?: number
	title: string
	description?: string
	link: LinkType
}

export const Cases: React.FC<CasesType> = (data) => {
	const {items, mod} = data
	const carousel = useCarousel()
	const [isTouched, setIsTouched] = useState(false)
	if (!items || items.length === 0) return null

	useInterval(() => {
		if (isTouched) return
		if (carousel.hasNext()) carousel.goNext()
		else carousel.goTo(0)
	}, 7500)

	return (
		<div
			className={styles().mod(mod)}
			onTouchStart={() => setIsTouched(true)}
			onMouseDown={() => setIsTouched(true)}
		>
			{items.map((item, i) => (
				<Bg
					key={i}
					mod="cases"
					image={item.image}
					className={styles('bg').is({active: carousel.isActive(i)})}
				/>
			))}
			<Theme.Container>
				<div className={styles('nav')}>
					<CarouselNav carousel={carousel} items={items} />
				</div>
				<Carousel {...carousel} full>
					{items.map((item, i) => (
						<div
							key={i}
							className={styles('slide').is({active: carousel.isActive(i)})}
						>
							<CasesItem {...item} />
						</div>
					))}
				</Carousel>
			</Theme.Container>
			{mod === 'fullheight' && (
				<div className={styles('scroll')}>
					<Theme.Container>
						<Mousescroll id="#scroll" />
					</Theme.Container>
				</div>
			)}
		</div>
	)
}

const CasesItem: React.FC<CasesItemType> = ({title, description, link}) => {
	return (
		<div className={styles.item()}>
			{title && (
				<div className={styles.item('title')}>
					<Theme.H2>{title}</Theme.H2>
				</div>
			)}
			{description && (
				<p className={styles.item('description')}>{description}</p>
			)}
			{link && link.url && (
				<Theme.Link className={styles.item('link')} mod="inherit" to={link.url}>
					<Theme.LinkText>{link.title}</Theme.LinkText>
					<Theme.LinkIcon />
				</Theme.Link>
			)}
		</div>
	)
}
