import { BlockType } from 'blocks/block'
import { Page } from 'layout/pages/page/page'
import React, { createContext } from 'react'
import { ImageType, WysiwygType } from 'types/basictypes'

export type ProductContextType = {
	image?: ImageType
	filters: Array<ProductFilter>
	description: WysiwygType
}

export const ProductContext = createContext<ProductContextType>(null)

export type ProductFilter = {
	filter: string,
	label: string,
	tid: number
}

export type ProductType = {
	blocks: Array<BlockType>
	image: ImageType
	filters: Array<ProductFilter>
	description: WysiwygType
}

export const Product: React.FC<ProductType> = data => {
	const { blocks, description, image, filters } = data

	return (
		<ProductContext.Provider value={{ image, description, filters }}>
			<Page
				blocks={blocks}
			/>
		</ProductContext.Provider>
	)
}
