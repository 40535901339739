import './blocks.scss'

import React from 'react'
import {styler} from 'util/style'

const styles = styler('blocks')

export const Blocks: React.FC<{fullwidth?: boolean}> = ({
	fullwidth,
	children
}) => <div className={styles().mod({fullwidth})}>{children}</div>
