import './form.scss'

import {FieldAttributes, useField} from 'formik'
import React, {HTMLProps, ReactNode, useEffect, useRef, useState} from 'react'
import {Mod, styler} from 'util/style'
import AnimateHeight from 'react-animate-height'

const styles = styler('form', ['warning', 'field', 'row', 'select'])

type InputAttrs =
	| ({component?: undefined} & HTMLProps<HTMLInputElement>)
	| ({component: 'select'} & HTMLProps<HTMLSelectElement>)
	| ({component: 'textarea'} & HTMLProps<HTMLTextAreaElement>)

export type FormField = {
	name: string
	mod?: Mod<'50' | 'background' | 'small' | 'dark'>
	hide_required?: boolean
	help?: ReactNode
	label?: ReactNode
} & Omit<InputAttrs, 'label'>

const fieldThemes = ['textarea', 'select', 'radio', 'checkbox']

export const FormWarning: React.FC<{
	warning?: string
}> = ({warning}) => {

	return <div className={styles.warning()}>
		<AnimateHeight height={warning ? 'auto' : 0} animateOpacity={true}>
			<p className={styles.warning('message')}>{warning}</p>
		</AnimateHeight>
	</div>
}

export const FormField: React.FC<FormField> = props => {
	const {
		children,
		type,
		label,
		name,
		help,
		mod,
		component,
		onFocus,
		onBlur,
		...attrs
	} = props
	const [focus, setFocus] = useState(false)
	const key = component || type
	const theme = fieldThemes.indexOf(key) > -1 ? key : 'text'
	const Tag = component || 'input'
	const [field, meta] = useField({name, type, ...attrs} as FieldAttributes<any>)

	const element = useRef<HTMLElement>()

	useEffect(() => {
		//TODO: add scroll to element
		//if (meta.error) scrollTo(() => 0, element.current)
	}, [meta.error])

	const withValue =
		type === 'checkbox' && !field.value
			? {value: 'true'}
			: theme === 'text' || theme === 'textarea'
			? {value: field.value || ''}
			: {}

	const isAttrs = {
		focus, filled: !!meta.value, error: !!meta.error
	}

	return (
		<div
			className={styles.field
				.mod(mod)
				.mod(theme)
				.is(isAttrs)
			}
		>
			<label className={styles.field('label').is(isAttrs)}>
				<Tag
					ref={element}
					type={type}
					{...(attrs as FieldAttributes<any>)}
					{...field}
					{...withValue}
					onFocus={e => {
						setFocus(true)
						if (onFocus) onFocus(e)
					}}
					onBlur={e => {
						setFocus(false)
						if (onBlur) onBlur(e)
					}}
					className={styles
						.field('label-field')
						.mod({textarea: attrs.as === 'textarea'})
						.is(isAttrs)
					}
				>
					{children}
				</Tag>
				<div className={styles.field('label-text').is(isAttrs)}>
					{label}
					{attrs.required && !attrs.hide_required && <span>&nbsp;*</span>}
				</div>
			</label>
			{meta.error && <div className={styles.field('error')}>{meta.error}</div>}
			{help && <div className={styles.field('help')}>{help}</div>}
		</div>
	)
}

export const FormRow: React.FC = ({children}) => (
	<div className={styles.row()}>{children}</div>
)

export const FormSelect: React.FC<FormField & {
	groups: Array<{
		label: string
		value: string
	}>
}> = data => {
	const {groups, ...attrs} = data
	return (
		<FormField {...attrs} component="select">
			<option hidden disabled selected />
			{groups.map(group => (
				<option key={group.label} value={group.label}>
					{group.value}
				</option>
			))}
		</FormField>
	)
}
