import 'blocks/herohome/herohome.scss'

import {HeroLink} from 'blocks/hero/hero'
import {Theme} from 'layout/theme'
import React from 'react'
import {LinkType} from 'types/basictypes'
import {styler} from 'util/style'

const styles = styler('herohome')

export type HeroHomeType = {
	description: string
	link?: Array<LinkType>
}

export const HeroHome: React.FC<HeroHomeType> = data => {
	const {description, link} = data

	return (
		<div className={styles()}>
			<Theme.Container>
				<div className={styles('content')}>
					<div className={styles('content-description')}>
						<Theme.Wysiwyg>{description}</Theme.Wysiwyg>
					</div>
					{link.map(
						(link, i) =>
							link &&
							link.url && (
								<HeroLink key={i} url={link.url} title={link.title} />
							)
					)}
				</div>
			</Theme.Container>
		</div>
	)
}
