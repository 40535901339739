import {graphql, useStaticQuery} from 'gatsby'

export const useLinks = () => {
  return useStaticLinksQuery()
}

export type LinkKey =
  'home' |
  'news' |
  'press' |
  'calendar' |
  'readingroom' |
  'contact' |
  'iconoverview' |
  'search' |
  'contactformsubmit' |
  'jobs'

export const useStaticLinksQuery = (): Record<LinkKey, {url: string, title: string}> => {
  const data = useStaticQuery(graphql`
		query LinksStatic {
      site {
        siteMetadata {
          links {
            home {url, title}
            news {url, title}
            press {url, title}
            calendar {url, title}
            readingroom {url, title}
            contact {url, title}
            iconoverview {url, title}
            search {url, title}
            contactformsubmit {url, title}
            jobs {url, title}
          }
        }
      }
		}
	`)

  const result = data.site.siteMetadata.links
  return result
}











