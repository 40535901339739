import { ImageMetaType, ImageType, WysiwygType } from 'types/basictypes'
import { GraphMedia, GraphText } from 'types/graphtypes'
import image from '*.png'
import { graphql } from 'gatsby'

export function getWysiwygFromField(field: GraphText): WysiwygType {
	if (!field) return null
	return field.processed
}

export function getImageFromField(field: GraphMedia, key = 'image'): ImageType {
	if (!field) return null

	const mediaRelationshipField = field.relationships.field_media_image
	if (!mediaRelationshipField) return null
	const file = mediaRelationshipField.localFile
	if (!file) return null
	const image = (file as any)[key]
	if (!image) return null

	if (!field?.field_media_image?.height) {
		throw new Error("Use the ...ImageMeta tag in your graphql query!")
	}

	const meta: ImageMetaType = {
		publicURL: field.relationships.field_media_image.localFile.publicURL,
		alt: field.field_media_image.alt,
		height: field.field_media_image.height,
		width: field.field_media_image.width,
		aspectRatio: field.field_media_image.width / field.field_media_image.height,
		overlayPercentage: field.field_overlay_percentage,
		useDarkColor: field.field_use_dark_colors
	}

	if (image.fixed) {
		return {
			...image,
			meta: {
				...meta,
				width: image.fixed.width,
				height: image.fixed.height,
				aspectRatio: image.fixed.width / image.fixed.height
			}
		}
	}

	return {
		...image,
		meta
	}
}

export const fragment = graphql`
	fragment ImageMeta on media__image {
		field_use_dark_colors
		field_overlay_percentage
		field_media_image {
			alt
			title
			width
			height
		}
		relationships {
			field_media_image {
				localFile {
					publicURL
				}
			}
		}
	}
`
