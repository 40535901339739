import './iconoverview.scss'
import {Column, Grid} from 'layout/grid'
import {Filters, FilterType} from 'layout/partials/filters'
import {Image} from 'layout/partials/image'
import {Theme} from 'layout/theme'
import React, {useEffect, useState} from 'react'
import {ImageMetaType, ImageType, WysiwygType} from 'types/basictypes'
import {t} from 'util/i18'
import {Link} from 'util/link'
import {styler} from 'util/style'
import {useSearchParams} from 'util/uselocation'
import {SearchresultsMore} from 'layout/search/searchresults'

const styles = styler('iconoverview', ['project'])

export const iconFilterKeys = ['market', 'research_project_type', 'status', 'year']
export type IconFilterKey = (typeof iconFilterKeys)[number]

export type IconOverviewData = {
	types: Record<IconFilterKey, IconTerm[]>
	items: IconItem[]
}

export type IconTerm = {
	name: string
	tid: number
}

export type IconItem = {
	title: string
	url: string
	description: WysiwygType
	image: ImageType
	terms: Array<number>
}

export const filterItems = (data: IconOverviewData, termsSet: Set<number>) => {
	const activeFilters = Object.keys(data.types)
		.map(key => {
			const ids = data.types[key].map(term => term.tid)
			return new Set(ids.filter(id => termsSet.has(id)))
		})
		.filter(filter => filter.size > 0)

	const failedItems = new Set()
	activeFilters.forEach(filter => {
		data.items.forEach(item => {
			if(failedItems.has(item)) return
			if(!item.terms.find(term => filter.has(term))){
				failedItems.add(item)
			}
		})
	})

	return data.items.filter(item => !failedItems.has(item))
}

export const Iconoverview: React.FC = () => {
	const [data, setData] = useState<IconOverviewData>(null)
	useEffect(() => {
		// @ts-ignore
		import('./../../../public/icon.json').then(res => setData(res.default))
	}, [])
	const {params: {page}, termsSet, updateParams} = useSearchParams()

	if(!data) return null

	const items = filterItems(data, termsSet)
	const visible = items.slice(0, (page+1) * 10)
	const hasMore = visible.length < items.length
	const filters: Array<FilterType> = iconFilterKeys.map(key => ({
		title: key,
		key: key,
		options: data.types[key].map(term => ({id: term.tid, name: term.name}))
	}))

	return (
		<div className={styles()}>
			<Filters filters={filters} />
			<div className={styles('space')}></div>
			<div className={styles('content')}>
				{visible.map(item => (
						<IconoverviewProject item={item} key={item.url} />
				))}
			</div>
			{hasMore && <SearchresultsMore onClick={() => updateParams({page: page+1})} />}
		</div>
	)
}

export const IconoverviewProject: React.FC<{
	item: IconItem
}> = ({item}) => {

	return (
		<div className={styles.project()}>
			<Theme.Container>
				{
					<Grid columns={1} s={1} m={2} l={12} align="top">
						<Column l={3}>
							<div className={styles.project('image')}>
								{item.image && (
									<Link to={item.url}>
										<Image image={item.image} aspectRatio={1} />
									</Link>
								)}
							</div>
						</Column>
						<Column l={9}>
							<div className={styles.project('content')}>
								<Link
									to={item.url}
									className={styles.project('content-title')}
								>
									{item.title}
								</Link>

								{item.description && (
									<Theme.Wysiwyg
										className={styles.project('content-description')}
									>
										{item.description}
									</Theme.Wysiwyg>
								)}
								<Link
									to={item.url}
									className={styles.project('content-link')}
								>
									{t('iconoverview.readmore')}
								</Link>
							</div>
						</Column>
					</Grid>
				}
			</Theme.Container>
		</div>
	)
}
