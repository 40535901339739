import './gallery.scss'

import {Carousel, useCarousel} from '@codeurs/carousel'
import {Icon} from 'assets/icon'
import {
	CarouselControlsNext,
	CarouselControlsPrevious
} from 'layout/carousel/carouselcontrols'
import {Bg} from 'layout/partials/bg'
import {Image} from 'layout/partials/image'
import {Theme} from 'layout/theme'
import React from 'react'
import {CarouselType, ImageType, WysiwygType} from 'types/basictypes'
import {styler} from 'util/style'
import {Trusted} from 'util/trusted'

const styles = styler('gallery', ['image', 'video', 'nav'])

type GalleryItemVideoType = {
	embed_code: string
	description: WysiwygType
	thumbnail: ImageType
}

type GalleryItemImageType = {
	image: ImageType
	thumbnail: ImageType
	description: WysiwygType
}

export type GalleryItemType =
	| ({type: 'image'} & GalleryItemImageType)
	| ({type: 'video'} & GalleryItemVideoType)

export type GalleryType = {
	items: Array<GalleryItemType>
}

export const Gallery: React.FC<GalleryType> = (data) => {
	const {items} = data
	const carousel = useCarousel()
	if (!items || items.length === 0) return null

	return (
		<div className={styles()}>
			<Theme.Container>
				<div className={styles('container')}>
					<div className={styles('content')}>
						<div className={styles('content-previous')}>
							<CarouselControlsPrevious carousel={carousel} />
						</div>
						<Carousel {...carousel}>
							{items.map((item, i) => switchType({item, i}))}
						</Carousel>
						<div className={styles('content-next')}>
							<CarouselControlsNext carousel={carousel} />
						</div>
					</div>
					<GalleryNav carousel={carousel} items={items} />
				</div>
			</Theme.Container>
		</div>
	)
}

function switchType({item, i}) {
	switch (item.type) {
		case 'image':
			return <GalleryItemImage key={i} {...item} />
			break

		case 'video':
			return <GalleryItemVideo key={i} {...item} />
			break

		default:
			return null
			break
	}
}

export const GalleryItemImage: React.FC<GalleryItemImageType> = (data) => {
	const {image, description} = data
	if (!image) return null
	return (
		<div className={styles.image().mod('image')}>
			<Bg image={image} mod="contain" />
			<div className={styles.image('icons')}>
				<a
					className={styles.image('icons-item')}
					href={image?.meta?.publicURL}
					target="_blank"
				>
					<span className={styles.image('icons-item-icon')}>
						<Icon icon="Download" />
					</span>
				</a>
			</div>
			{description && (
				<div className={styles.image('description')}>
					<Theme.Wysiwyg>{description}</Theme.Wysiwyg>
				</div>
			)}
		</div>
	)
}

export const GalleryItemVideo: React.FC<GalleryItemVideoType> = (data) => {
	const {embed_code, description} = data
	if (!embed_code) return null
	return (
		<div className={styles.video()}>
			<Trusted>{embed_code}</Trusted>
		</div>
	)
}

export const GalleryNav: React.FC<{
	carousel: CarouselType
	items: Array<GalleryItemType>
}> = (data) => {
	const {carousel, items} = data

	return (
		<div className={styles.nav()}>
			<div className={styles.nav('items')}>
				{items.map((item, i) => {
					const image = item.thumbnail
					return (
						<a
							key={i}
							className={styles.nav('item').is({
								active: carousel.isActive(i)
							})}
							onClick={() => carousel.goTo(i)}
						>
							<Image aspectRatio={200 / 180} image={image} />
						</a>
					)
				})}
			</div>
		</div>
	)
}
