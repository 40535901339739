import {RelatedType} from 'blocks/related/related'
import {graphql, useStaticQuery} from 'gatsby'
import React from 'react'
import {RelatedFragment} from 'types/graphtypes'
import {previewFragmentToPreviewItem} from 'layout/preview/preview_graph'
import {PreviewType} from 'layout/preview/previewtype'

export const getRelatedData: (
	frag: RelatedFragment
) => RelatedType = (frag) => {
	const {
		field_related_title,
		relationships: { fixedPreviews },
		fields
	} = frag

	const fixed: Array<PreviewType> = fixedPreviews
		.filter(page => page && page.fields && page.fields.preview)
		.map(page => previewFragmentToPreviewItem(page.fields.preview))

	return {
		title: field_related_title,
		link_title:
			fields &&
			fields.field_related_link_title &&
			fields.field_related_link_title.url,
		fixed: [...fixed]
	}
}

export const useStaticRelatedQuery = (): Array<PreviewType> => {
	const data = useStaticQuery(graphql`
		query RelatedStatic {
			allPagePreview(
				limit: 13
				sort: {fields: date, order: DESC}
				filter: {has_image: {eq: true}, appear_in_readingroom: {eq: true}}
			) {
				nodes {
					...Preview
				}
			}
		}
	`)

	return data.allPagePreview.nodes.map(
		previewFragmentToPreviewItem
	)
}

export const fragment = graphql`
	fragment Related on paragraph__related {
		id
		field_related_title
		fields {
			field_related_link_title {
				url
			}
		}
		relationships {
			fixedPreviews: field_related_fixed_links {
				... on node__page {
					id
					fields {
						preview {
							...Preview
						}
					}
				}
				... on node__case {
					id
					fields {
						preview {
							...Preview
						}
					}
				}
				... on node__job {
					id
					fields {
						preview {
							...Preview
						}
					}
				}
				... on node__research_project {
					id
					fields {
						preview {
							...Preview
						}
					}
				}
				... on node__event {
					id
					fields {
						preview {
							...Preview
						}
					}
				}
				... on node__press {
					id
					fields {
						preview {
							...Preview
						}
					}
				}
				... on node__article {
					id
					fields {
						preview {
							...Preview
						}
					}
				}
			}
		}
	}
`
