import './sponsors.scss'

import {GatsbyImageProps} from 'gatsby-image'
import {Theme} from 'layout/theme'
import {styler} from 'util/style'
import {Bg} from 'layout/partials/bg'

import {Link} from 'util/link'
import {ImageType} from 'types/basictypes'

const styles = styler('sponsors', ['logos'])

export type SponsorsType = {
	title?: string
	logos?: Array<LogosType>
}

type LogosType = {
	title?: string
	items?: Array<LogoType>
}

export type LogoType = {
	image: ImageType
	link?: string
}

export const Sponsors: React.FC<SponsorsType> = data => {
	const {title, logos} = data
	if (!logos || logos.length === 0) return null

	return (
		<div className={styles()}>
			<Theme.Container>
				{title && (
					<Theme.H2 mod={'blueline'} className={styles('title')}>
						{title}
					</Theme.H2>
				)}
				{logos.map((logoList, i) => (
					<SponsorsLogos {...logoList} key={i} />
				))}
			</Theme.Container>
		</div>
	)
}

const SponsorsLogos: React.FC<LogosType> = data => {
	const {title, items} = data
	if (!items || items.length === 0) return null

	return (
		<div className={styles.logos()}>
			{title && (
				<Theme.H4 mod={'blueline'} className={styles.logos('title')}>
					{title}
				</Theme.H4>
			)}
			<div className={styles.logos('container')}>
				{items.map((item, i) => (
					<Logo {...item} key={i} />
				))}
			</div>
		</div>
	)
}

const Logo: React.FC<LogoType> = data => {
	const {link, image} = data
	if (!image) return null

	const width = 150 / (image.meta.height / image.meta.width)

	return (
		<div>
			<div className={styles.logos('logo')} style={{width: width}}>
				{!!link && (
					<Link to={link}>
						<Bg image={image} />
					</Link>
				)}
				{!link && <Bg image={image} />}
			</div>
		</div>
	)
}
