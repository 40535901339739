import {ArticlesType} from 'blocks/articles/articles'
import {graphql, useStaticQuery} from 'gatsby'
import React from 'react'
import {ArticlesFragment} from 'types/graphtypes'
import {previewFragmentToPreviewItem} from 'layout/preview/preview_graph'
import {PreviewType} from 'layout/preview/previewtype'

export const getArticlesData: (
	frag: ArticlesFragment
) => ArticlesType = (frag) => {
	const {
		field_articles_title,
		field_articles_populair_label,
		relationships
	} = frag

	const tags =
		relationships &&
		relationships.field_articles_populair_tags.filter(t => t.name)

	return {
		title: field_articles_title,
		label: field_articles_populair_label,
		tags
	}
}

export const useStaticArticlesQuery = (): Array<PreviewType> => {
	const data = useStaticQuery(graphql`
		query ArticlesStatic {
			allPagePreview(
				limit: 13
				sort: {fields: date, order: DESC}
				filter: {
					type: {in: ["article", "press"]}
				}
			) {
				nodes {
					...Preview
				}
			}
		}
	`)
	return data.allPagePreview.nodes.map(previewFragmentToPreviewItem)
}

export const fragment = graphql`
	fragment Articles on paragraph__articles {
		id
		field_articles_title
		field_articles_populair_label
		relationships {
			field_articles_populair_tags {
				name
			}
		}
	}
`
