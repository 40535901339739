import './columns2.scss'

import {Column, Grid} from 'layout/grid'
import {Cardpreview} from 'layout/preview/cardpreview'
import {Theme} from 'layout/theme'
import React from 'react'
import {ImageType, LinkType, WysiwygType} from 'types/basictypes'
import {styler} from 'util/style'

const styles = styler('columns2')

export type Columns2Type = {
	image_left: ImageType
	text_left: WysiwygType
	link_left?: LinkType
	image_right: ImageType
	text_right: WysiwygType
	link_right?: LinkType
	display_square?: boolean
	display_background?: boolean
}

export const Columns2: React.FC<Columns2Type> = (data) => {
	const {
		image_left,
		text_left,
		link_left,
		image_right,
		text_right,
		link_right,
		display_square = false
	} = data

	return (
		<div className={styles()}>
			<Theme.Container>
				<Grid columns={1} xs={1} s={2} m={2} l={2}>
					<Column>
						<Cardpreview
							url={link_left?.url}
							image={image_left}
							text={text_left}
							display_square={display_square}
						/>
					</Column>
					<Column>
						<Cardpreview
							url={link_right?.url}
							image={image_right}
							text={text_right}
							display_square={display_square}
						/>
					</Column>
				</Grid>
			</Theme.Container>
		</div>
	)
}
