import { PreviewType } from 'layout/preview/previewtype'
import { graphql, useStaticQuery } from 'gatsby'
import { previewFragmentToPreviewItem } from 'layout/preview/preview_graph'

export const useStaticReadingroomItemsQuery = (): Array<PreviewType> => {
	const data = useStaticQuery(graphql`
		query ReadingroomItemsStatic {
			allPagePreview(
				limit: 15
				sort: {fields: date, order: DESC}
				filter: {has_image: {eq: true}, appear_in_readingroom: {eq: true}}
			) {
				nodes {
					...Preview
				}
			}
		}
	`)
	return data.allPagePreview.nodes.map(previewFragmentToPreviewItem)
}
