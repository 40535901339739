import './contact.scss'

import {Icon} from 'assets/icon'
import {Column, Grid} from 'layout/grid'
import {Theme} from 'layout/theme'
import React from 'react'
import {ImageType, WysiwygType} from 'types/basictypes'
import {Link} from 'util/link'
import {styler} from 'util/style'
import {useLocation} from 'util/uselocation'
import {Image} from 'layout/partials/image'
import {useLinks} from 'util/links'

const styles = styler('contact')

export type ContactType = {
	text: WysiwygType
	paragraphID: number | string
	avatar_image: ImageType
	link
}

export const Contact: React.FC<ContactType> = data => {
	const {text, avatar_image, link, paragraphID} = data
	const {contact} = useLinks()
	const {
		location: {pathname}
	} = useLocation()

	let contactUrl = (link && link.url) || contact.url
	if (contactUrl === contact.url) {
		contactUrl += '?origin_url=' + encodeURIComponent(pathname)
		contactUrl += '&origin=' + paragraphID
		contactUrl += '#contact'
	}

	return (
		<div className={styles()}>
			<Theme.Container>
				<Grid columns={1} l={12} align="middle">
					<Column l={8}>
						<Grid columns={1} s={12} m={12} l={12} align="middle">
							{avatar_image && (
								<Column span={4}>
									<Image aspectRatio='free' image={avatar_image} />
								</Column>
							)}
							<Column span={avatar_image ? 8 : 12}>
								{text && (
									<div className={styles('content')}>
										<Theme.Wysiwyg>{text}</Theme.Wysiwyg>
									</div>
								)}
							</Column>
						</Grid>
					</Column>
					<Column l={4}>
						<Link className={styles('cta')} to={contactUrl}>
							<p className={styles('cta-text')}>
								{(link && link.title) || contact.title}
							</p>
							<div className={styles('cta-circle')}>
								<span className={styles('cta-circle-icon')}>
									<Icon icon="ArrowRight" />
								</span>
							</div>
						</Link>
					</Column>
				</Grid>
			</Theme.Container>
		</div>
	)
}
