import {graphql, useStaticQuery} from 'gatsby'

let redirects = undefined
export const useRedirects = (): RedirectsMapping => {
  if(redirects === undefined){
    redirects = useStaticRedirectsQuery()
  }
  return redirects
}

export type RedirectsMapping = Record<string, string>

export const useStaticRedirectsQuery = () => {
  const data = useStaticQuery(graphql`
		query {
      site {
        siteMetadata {
          redirects
        }
      }
		}
	`)
  return JSON.parse(data.site.siteMetadata.redirects)
}











