import './agenda.scss'

import {styler} from 'util/style'
import React, {useState} from 'react'
import {Theme} from 'layout/theme'
import {useStaticAgendaQuery} from './agenda_graph'
import {Link} from 'gatsby'
import {WysiwygType} from 'types/basictypes'
import {t} from 'util/i18'

const styles = styler('agenda', ['empty', 'hero', 'events'])

export type AgendaType = {
	title: string
}

type EventInfoParams = {
	date: string
	endDate: string
	location: string
}

export type AgendaEventType = {
	title: string
	location?: string
	date: string
	endDate?: string
	slug: string
	description?: WysiwygType
	hideDescription?: boolean
}

export const Agenda: React.FC<AgendaType> = data => {
	const { title } = data
	const [moreCounter, updateCounter] = useState(1)

	const staticEvents = useStaticAgendaQuery()
	let events = staticEvents.slice(0, moreCounter * 10)

	return (
		<div className={styles()}>
			<AgendaEvents events={events} />
			{moreCounter < 5 && staticEvents.length != events.length && (
				<div className={styles('more')}>
					<div
						className={styles('more-button')}
						onClick={() => updateCounter(moreCounter + 1)}
					>
						SHOW MORE
					</div>
				</div>
			)}
		</div>
	)
}

export const AgendaEvents: React.FC<{
	events: Array<AgendaEventType>
}> = data => {
	const { events } = data
	if (!events || events.length === 0) {
		return <AgendaEmpty />
	}

	return (
		<div className={styles.events()}>
			{events.map(event => (
				<Event {...event} key={event.slug} />
			))}
		</div>
	)
}

export const AgendaEmpty: React.FC = () => {

	return <div className={styles.empty()}>
		<Theme.Container>
			<Theme.H3 className={styles.empty('title')}>
				{t('agenda.no_results')}
			</Theme.H3>
		</Theme.Container>
	</div>
}

export const Event: React.FC<AgendaEventType> = data => {
	const { title, location, date, endDate, slug, description, hideDescription } = data

	const eventInfo = createEventInfo({ date, endDate, location })

	return (
		<div>
			<div className={styles.events('space')}></div>
			<Theme.Container className={styles.events('event')}>
				<Link to={slug}>
					{date && (
						<h3 className={styles.events('event-date')}>{eventInfo}</h3>
					)}
					<h2 className={styles.events('event-title')}>{title}</h2>
					{description && !hideDescription && (
						<Theme.Wysiwyg className={styles.events('event-description')}>
							{description}
						</Theme.Wysiwyg>
					)}
				</Link>
			</Theme.Container>
		</div>
	)
}

export const createEventInfo = (params: EventInfoParams) => {
	const { date, endDate, location } = params

	let eventInfo = ''
	if (date) {
		eventInfo = eventInfo.concat(cleanDate(date, endDate))
		if (location) eventInfo = eventInfo.concat(' | ')
	}
	if (location) eventInfo = eventInfo.concat(location)

	return eventInfo
}

const cleanDate = (date, endDateInitial?) => {
	const options = { year: 'numeric', month: 'long', day: 'numeric' }
	const optionsMonth = { month: 'long' }

	const year = date.substring(0, 4)
	const month = date.substring(4, 6)
	const day = date.substring(6, 8)

	const startDate = new Date(year, month - 1, day)
	const startDateString = startDate.toLocaleDateString('nl-NL', options)

	if (!endDateInitial) {
		return startDateString
	}

	const endYear = endDateInitial.substring(0, 4)
	const endMonth = endDateInitial.substring(4, 6)
	const endDay = endDateInitial.substring(6, 8)

	const endDate = new Date(endYear, endMonth - 1, endDay)

	if (month === endMonth && year === endYear) {
		const monthString = startDate.toLocaleDateString('nl-NL', optionsMonth)
		return day + ' - ' + endDay + ' ' + monthString + ' ' + year
	}

	if (year === endYear) {
		const startMonthString = startDate.toLocaleDateString('nl-NL', optionsMonth)
		const endMonthString = endDate.toLocaleDateString('nl-NL', optionsMonth)
		return (
			day +
			' ' +
			startMonthString +
			' - ' +
			endDay +
			' ' +
			endMonthString +
			' ' +
			year
		)
	}

	return startDateString.concat(
		' - ',
		endDate.toLocaleDateString('nl-NL', options)
	)
}
