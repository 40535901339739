import './related.scss'

import {Carousel, useCarousel} from '@codeurs/carousel'
import {CarouselControls} from 'layout/carousel/carouselcontrols'
import {Relatedpreview} from 'layout/preview/relatedpreview'
import {Theme} from 'layout/theme'
import React, {useContext} from 'react'
import {CarouselType} from 'types/basictypes'
import {styler} from 'util/style'
import {useStaticRelatedQuery} from 'blocks/related/related_graph'
import {usePathname} from 'util/uselocation'
import {PreviewType} from 'layout/preview/previewtype'
import {BlocksContext} from 'blocks/blockscontext'
import {filterDoubles} from 'util/filterdoubles'
import {Link} from 'util/link'
import {useLinks} from 'util/links'

const styles = styler('related', ['items'])

export type RelatedType = {
	title: string
	fixed?: Array<PreviewType>
	link_title?: string
}

export const Related: React.FC<RelatedType> = data => {
	const {title, fixed = [], link_title} = data
	const carousel = useCarousel()
	const {readingroom} = useLinks()
	const pathname = usePathname()
	const {relatedContent} = useContext(BlocksContext)
	const staticItems = useStaticRelatedQuery()
	const items = filterDoubles([...fixed, ...relatedContent, ...staticItems])
		.filter(item => item.url !== pathname)
		.slice(0, 12)

	//Make an array where each element is an array of 4 articles
	const articleRows: Array<Array<PreviewType>> = []
	for (let i = 0; 0 < items.length && i < 3; i++) {
		articleRows[i] = items.splice(0, 4)
	}

	return (
		<div className={styles()}>
			<Theme.Container>
				<div className={styles('top')}>
					<Link to={link_title || readingroom.url}>
						<Theme.H2 mod={['blueline', 'arrow']}>{title}</Theme.H2>
					</Link>
					<div className={styles('top-nav')}>
						<CarouselControls carousel={carousel} />
					</div>
				</div>
			</Theme.Container>
			<RelatedItems carousel={carousel} items={articleRows} />
		</div>
	)
}

export const RelatedItems: React.FC<{
	carousel: CarouselType
	items: Array<Array<PreviewType>>
}> = data => {
	const {carousel, items} = data

	return (
		<div className={styles.items()}>
			<Carousel {...carousel}>
				{items &&
					items.map((articles, i) => (
						<div
							key={i}
							className={styles
								.items('block')
								.is({active: carousel.isActive(i)})}
						>
							<div className={styles.items('block-left')}>
								<Relatedpreview {...articles[0]} />
							</div>
							<div className={styles.items('block-right')}>
								<div className={styles.items('block-right-top')}>
									<div className={styles.items('block-right-top-item')}>
										<Relatedpreview {...articles[1]} />
									</div>
								</div>
								<div className={styles.items('block-right-bottom')}>
									<div className={styles.items('block-right-bottom-item')}>
										<Relatedpreview {...articles[2]} />
									</div>
									<div className={styles.items('block-right-bottom-item')}>
										<Relatedpreview {...articles[3]} />
									</div>
								</div>
							</div>
						</div>
					))}
			</Carousel>
		</div>
	)
}
