import './process.scss'

import {Theme} from 'layout/theme'
import React from 'react'
import {WysiwygType, LinkType} from 'types/basictypes'
import {Link} from 'util/link'
import {styler} from 'util/style'
import scrollTo from 'gatsby-plugin-smoothscroll'
import {url} from 'inspector'

const styles = styler('process')

export type ProcessType = {
	text: WysiwygType
	links?: Array<{
		url: string
		title: string
	}>
}

export const Process: React.FC<ProcessType> = data => {
	const {text, links} = data

	return (
		<div className={styles().mod({links: links || links.length !== 0})}>
			<Theme.Container>
				{text && (
					<div className={styles('text')}>
						<Theme.Wysiwyg>{text}</Theme.Wysiwyg>
					</div>
				)}
			</Theme.Container>
			{links && links.length !== 0 && (
				<div className={styles('links')}>
					<Theme.Container>
						<div className={styles('links-items')}>
							{links.map((link, i) => (
								<div key={i} className={styles('links-item')}>
									{
										<Link to={link.url} className={styles('links-link')}>
											{link.title}
										</Link>
									}
								</div>
							))}
						</div>
					</Theme.Container>
				</div>
			)}
		</div>
	)
}
