import './filters.scss'

import {styler} from 'util/style'
import {Theme} from 'layout/theme'
import {Link} from 'util/link'
import {t} from 'util/i18'
import React from 'react'
import {useSearchParams} from 'util/uselocation'
import {FilterTag} from 'layout/search/filtertag'

const styles = styler('filters', ['item'])

export type FiltersType = {
	filters: Array<FilterType>
	baseUrl: string
}

export type FilterType = {
	key: string
	title: string
	options: Array<OptionType>
}

export type OptionType = {
	name: string
	id: number
}

export const Filters: React.FC<{
	filters: Array<FilterType>
}> = ({filters}) => {

	let { params: {terms}, toggleTerm, createUrl} = useSearchParams()

	const termsSet = new Set<number>(terms)
	let allOptions = []
	filters.forEach(filter => {
		allOptions = [...allOptions, ...filter.options]
	})
	const selectedOption = allOptions.filter(option => termsSet.has(option.id))

	if (!filters || filters.length == 0) return null

	//TODO: loadertje tonen
	//TODO: clear positie in orde brengen (op mobiel)

	return (
		<div className={styles()}>
			<Theme.Container>
				{filters.map((item, i) => (
					<FiltersItem
						title={item.title}
						options={item.options.filter(option => !termsSet.has(option.id))}
						key={item.key}
					/>
				))}
				<div className={styles('clear')}>
					<Link to={createUrl({})} className={styles('filter-clear')}>
						{t('filters.clear')}
					</Link>
				</div>
				<div className={styles('tags')}>
					{selectedOption.map(option => (
						<FilterTag onClick={() => toggleTerm(option.id)} key={option.id} mod="white">
							{option.name}
						</FilterTag>
					))}
				</div>
			</Theme.Container>
		</div>
	)
}

export const FiltersItem: React.FC<{
	title: string
	options: Array<OptionType>
}> = ({title, options}) => {

	const {toggleTerm} = useSearchParams()

	return (
		<div className={styles.item()}>
			<select
				className={styles.item('select')}
				value={title}
				onChange={e => toggleTerm(e.target.value)}
			>
				<option disabled>{title}</option>
				{options.map(option => (
					<option value={option.id} key={option.id}>
						{option.name}
					</option>
				))}
			</select>
		</div>
	)
}
