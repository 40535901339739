import './search.scss'
import {Breadcrumbs} from 'layout/partials/breadcrumbs'
import {Theme} from 'layout/theme'
import React from 'react'
import useSWR from 'swr/esm/use-swr'
import {Link} from 'util/link'
import {styler} from 'util/style'
import {SEARCH_URL} from 'util/urls'
import {useSearchParams} from 'util/uselocation'
import {Column, Grid} from 'layout/grid'
import {SearchbarInput} from 'layout/search/searchbar'
import {useFetcher} from 'util/fetcher'
import {SearchresultsEmpty, SearchresultsLoader, SearchresultsMore} from 'layout/search/searchresults'

const styles = styler('search', ['query', 'results', 'item'])

export type SearchType = {
	button: string
	more: string
	placeholder: string
}

export const Search: React.FC<SearchType> = () => {
	const {params: {query}} = useSearchParams()

	return <div className={styles()}>
		<SearchQuery />
		{query && <SearchResults />}
	</div>
}

export const SearchQuery: React.FC = () => {
	const {params: {query}, updateParams} = useSearchParams()

	return <div className={styles.query()}>
		<Theme.Container>
			<Grid columns={1} s={1} m={12} l={12} align="top">
				<Column m={9} l={9}>
					<SearchbarInput
						onSearch={query => updateParams({query})}
						query={query}
						mod="dark"
					/>
				</Column>
				<Column m={3} l={3}>
				</Column>
			</Grid>
		</Theme.Container>
	</div>
}

export const SearchResults: React.FC = () => {
	const {params: {query, page}, updateParams} = useSearchParams()
	const url = query && SEARCH_URL({query, page})
	const { data, isLoading } = useFetcher(url)

	if (!data) return <SearchresultsLoader />
	if (!data.items.length) return <SearchresultsEmpty />

	const allItems = data?.items
	const nrOfItems = (page + 1) * 12
	const items = allItems.slice(0, nrOfItems)
	const showMore = allItems.length > nrOfItems

	return (
		<div className={styles.results()}>
			<div className={styles.results('items')}>
				{items.map((result, i) => (
					<SearchItem result={result} key={i} />
				))}
			</div>
			{showMore && !isLoading && <SearchresultsMore onClick={() => updateParams({ page: page + 1})} />}
			{isLoading && <SearchresultsLoader />}
		</div>
	)
}


export const SearchItem: React.FC<{
	result
}> = data => {
	const {result} = data

	return (
		<div className={styles.results('result')}>
			<Theme.Container>
				{result.title && (
					<div className={styles.results('result-title')}>
						<Theme.H4>
							<Link
								to={result.url}
								className={styles.results('result-title-link')}
							>
								{result.title}
							</Link>
						</Theme.H4>
					</div>
				)}
				{result.description && (
					<div className={styles.results('result-description')}>
						{result.description.replace(/<[^>]*>?/g, '')}
					</div>
				)}
				<div className={styles.results('result-breadcrumbs')}>
					<Breadcrumbs
						parents={result.parents}
						pageTitle={result.title}
						pageurl={result.url}
						mod="small"
					/>
				</div>
			</Theme.Container>
		</div>
	)
}
