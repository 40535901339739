import { ProductsFilterModel } from 'blocks/products/models/ProductsFilterModel'
import { ProductsSearchModel } from 'blocks/products/models/ProductsSearchModel'
import memoizeGetters from 'memoize-getters'
import { ProductModel } from 'blocks/products/models/ProductModel'
import { trimEnd } from 'lodash'

export type ProductsTermsState = {
	name: string
	sort: number
	tid: number
	children: Array<ProductsTermsState>
}

export type ProductsTermKey = string

export class ProductsTermModel {

	constructor(readonly parent: ProductsFilterModel | ProductsTermModel, private state: ProductsTermsState) { }

	get search(): ProductsSearchModel {
		return this.parent.search
	}

	get isActive(): boolean {
		if (this.search.termsSet.has(this.key)) return true
		return !!this.children.find(term => term.isActive)
	}

	get isSelected(): boolean {
		if (this.parent instanceof ProductsTermModel && this.parent.isSelected) return true
		return this.search.termsSet.has(this.key)
	}

	get label(): string {
		return this.state.name
	}

	get key(): ProductsTermKey {
		return `${this.id}`
	}

	get id(): number {
		return this.state.tid
	}

	get children(): Array<ProductsTermModel> {
		return this.state.children.map(state => new ProductsTermModel(this, state))
	}

	validateProduct(product: ProductModel): boolean {
		if (this.isSelected && product.termsSet.has(this.key)) return true
		return !!this.children.find(term => term.validateProduct(product))
	}
}
memoizeGetters(ProductsTermModel)
