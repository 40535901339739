import {nl} from 'locale/nl'

export const en: typeof nl = {
	read_more: 'Show more',
	agenda: {
		no_results: 'There are currently no events scheduled on our agenda'
	},
	presslabel: 'Press',
	iconproject: {
		read_more: 'Show more'
	},
	jobs: {
		periods: {
			past_day: 'Past day',
			past_3days: 'Past 3 days',
			past_week: 'Past week',
			past_month: 'Past month'
		},
		filters: {
			type: 'Category',
			categories: 'Application domain',
			job_title: 'Title',
			region: 'Location',
			employment_type: 'Employment Types',
			employer: 'Employer',
			promotor: 'Promotor',
			csr: 'CSR-label',
			competence: 'Competence',
			marketsegments: 'Market segments',
			date_posted: 'Date Posted'
		}
	},
	readingroom: {
		searchbar: 'Search by subject or theme',
		showmore: 'Subjects',
		showless: 'Show less',
		readmore: 'SHOW MORE',
		noResultsTitle: 'Shame!',
		noResultsPart1: 'We cant find any results for ',
		noResultsPart2:
			'. Please try again with a broader search query. Or press ‘subjects’ to filter based on a theme.',
		extraCategory: {
			name: 'Over Imec',
			items: [
				{
					type: 'tag' as const,
					name: 'General'
				},
				{
					type: 'pagetype' as const,
					pagetype: 'press'
				},
				{
					type: 'tag' as const,
					name: 'DUMMY [tag]'
				}
			]
		},
		textualTags: ['DUMMY [tag]'],
		types: {
			press: 'Press',
			case: 'Cases'
		},
		popular: {title: 'Most viewed'}
	},
	header: {
		request: 'Request more information',
		back: 'Back to overview'
	},
	footer: {
		follow: 'Follow imec on:',
		partof: 'is part of'
	},
	article: {
		back: 'Reading Room',
		short: 'Summary',
		readmore: 'Discover more'
	},
	press: {
		readmore: 'Read more'
	},
	sidebar: {
		tags: 'More about these topics',
		date: 'Published on'
	},
	form: {
		firstname: 'First name',
		lastname: 'Last name',
		email: 'E-mail',
		phone: 'Phone number',

		company: 'Company/organisation',
		address: 'Address company',
		zipcode: 'Zip code',
		city: 'City',

		message: 'Your message',
		submit: 'Send',
		thanks: {
			title: 'Thanks!',
			text: 'The form was submitted successfully'
		}
	},
	request: {
		title: 'How can we help you?',
		identification: 'Send us your request',
		topics: `I'm looking for`,
		contact: 'Contact us'
	},
	iconoverview: {
		startyear: 'Start year',
		market: 'Market',
		projectType: 'Research project type',
		status: 'status',
		readmore: 'read more'
	},
	filters: {
		clear: 'remove filters'
	},
	downloads: {
		title: 'Download',
		text: 'Download file'
	},
	iframe: {
		invisible:
			'This content is only visible on the desktop version of this website.'
	},
	researchproject: {
		about: 'About this project'
	},
	job: {
		apply: 'Apply'
	},
	words: {
		show_all: 'Show all'
	}
}
