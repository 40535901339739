import './header.scss'

import Dialog from '@material-ui/core/Dialog'
import {Location, navigate} from '@reach/router'
import {Icon} from 'assets/icon'
import logoImg from 'assets/images/imec-logo.svg'
import {useStaticHeaderQuery} from 'layout/partials/header/header_graph'
import {Theme} from 'layout/theme'
import React, {Fragment, useState} from 'react'
import {useTranslation} from 'util/i18'
import {Link} from 'util/link'
import {useLinks} from 'util/links'
import {styler} from 'util/style'
import {HeaderMobilenav} from './headermobilenav'
import {HeaderNav, HeaderNavDropdown} from './headernav'

export type HeaderLinkType = {
	title: string
	url: string
	id: string
	children: Array<{
		title: string
		url: string
		description: string
	}>
}

const styles = styler('header', ['home', 'logo', 'search', 'hamburger'])

export const Header = () => {
	const [searchisOpen, setSearchOpen] = useState<boolean>(false)
	const [hoveringID, setHoveringID] = useState<string>(null)
	const items = useStaticHeaderQuery()
	const hoveringItem = items.find((item) => item.id === hoveringID)
	const setHoveringItem = (item) => setHoveringID(item ? item.id : null)

	const {home, contact} = useLinks()
	const {
		header: {request}
	} = useTranslation()
	const envProjectKey = process.env.GATSBY_PROJECT_KEY
	const subsite = envProjectKey === 'hyperspectral'

	return (
		<header className={styles()} onMouseLeave={() => setHoveringItem(null)}>
			<div className={styles('rowcontainer')}>
				<Theme.OuterContainer>
					<div className={styles('row')}>
						<div className={styles('row-left')}>
							{subsite ? <HeaderHome /> : <HeaderLogo url={home?.url} />}
							<HeaderNav links={items} setHoveringItem={setHoveringItem} />
						</div>
						<div className={styles('row-right')}>
							{!subsite && !searchisOpen && (
								<Link to={contact.url} className={styles('row-right-link')}>
									<span className={styles('row-right-link-text')}>
										{contact.title}
									</span>
								</Link>
							)}
							{!subsite && (
								<HeaderSearch
									searchisOpen={searchisOpen}
									onSearchClick={(searchisOpen) => setSearchOpen(searchisOpen)}
								/>
							)}
							{subsite && <HeaderLogo />}
							{subsite && (
								<Theme.Button
									to={contact?.url}
									className={styles('button')}
									mod="rounded"
								>
									<Theme.ButtonText>{request}</Theme.ButtonText>
									<Theme.ButtonIcon />
								</Theme.Button>
							)}
							<HeaderHamburger links={items} />
						</div>
					</div>
				</Theme.OuterContainer>
			</div>
			{items.map((item) => (
				<div
					key={item.id}
					className={styles('dropdowncontainer').is({
						open: item === hoveringItem
					})}
					onMouseOver={() => setHoveringItem(item)}
					onMouseLeave={() => setHoveringItem(null)}
				>
					<HeaderNavDropdown item={item} isOpen={item === hoveringItem} />
				</div>
			))}
		</header>
	)
}

const HeaderHome: React.FC = () => {
	const {home} = useLinks()

	return (
		<Link to={home?.url} className={styles.home()}>
			<Icon icon="Home" />
		</Link>
	)
}

export const HeaderLogo: React.FC<{url?: string}> = ({url}) => {
	return (
		<Link className={styles.logo()} to={url || 'https://www.imec-int.com/'}>
			<img className={styles.logo('img')} src={logoImg} alt="imec logo" />
		</Link>
	)
}

export const HeaderSearch: React.FC<{
	searchisOpen: boolean
	onSearchClick: (searchisOpen: boolean) => void
}> = ({searchisOpen, onSearchClick}) => {
	const [suggestionText, setSuggestionText] = useState('')
	const {search} = useLinks()

	return (
		<Location>
			{({location}) => {
				const href = search.url + '?query=' + suggestionText
				return (
					<div className={styles.search()}>
						{!searchisOpen && (
							<button
								className={styles.search('button')}
								onClick={() => onSearchClick(true)}
							>
								<Icon icon="Search" />
							</button>
						)}
						{searchisOpen && (
							<form
								onSubmit={(e) => {
									e.preventDefault()
									navigate(href)
								}}
								onBlur={() =>
									setTimeout(() => {
										onSearchClick(false)
									}, 100)
								}
								className={styles.search('active')}
							>
								<input
									autoFocus
									className={styles.search('active-input')}
									name="search"
									type="text"
									onChange={(e) => setSuggestionText(e.target.value)}
								></input>

								<button
									className={styles.search('active-button')}
									type="submit"
									value="search"
								>
									<Icon icon="Search" />
								</button>
							</form>
						)}
					</div>
				)
			}}
		</Location>
	)
}

export const HeaderHamburger: React.FC<{
	links: Array<HeaderLinkType>
}> = ({links}) => {
	const [isOpenMenu, setMenu] = useState(false)

	return (
		<Fragment>
			<a className={styles.hamburger()} onClick={() => setMenu(!isOpenMenu)}>
				<span className={styles.hamburger('bar')} />
				<span className={styles.hamburger('bar')} />
				<span className={styles.hamburger('bar')} />
			</a>
			<Dialog open={isOpenMenu} onClose={() => setMenu(!isOpenMenu)}>
				<HeaderMobilenav links={links} onCloseMenu={() => setMenu(false)} />
			</Dialog>
		</Fragment>
	)
}
