import {nl} from 'locale/nl'
import * as langs from 'locale'
import toPath from 'lodash/toPath'
import React, {Component, createContext, useContext} from 'react'

export const I18nContext = createContext<{ language: string; data: any }>({
	language: process.env.GATSBY_DRUPAL_LANG,
	data: langs[process.env.GATSBY_DRUPAL_LANG]
})

const getIn = (
	obj: any,
	key: string | Array<string>,
	def?: any,
	p: number = 0
) => {
	const path = toPath(key)
	while (obj && p < path.length) obj = obj[path[p++]]
	return obj === undefined ? def : obj
}

export class I18n<T> extends Component<{
	language: string
	data: T
}> {
	render() {
		return (
			<I18nContext.Provider value={this.props}>
				{this.props.children}
			</I18nContext.Provider>
		)
	}
}

export const translate = (data: any, key: string) => {
	const res = getIn(data, key)
	if (res === undefined) console.error(`Undefined translation key: ${key}`)
	return res
}

export const useTranslation = () => {
	const { data } = useContext(I18nContext)
	return data as typeof nl
}

export const t = (key: string) => (
	<I18nContext.Consumer>
		{({ data }) => translate(data, key)}
	</I18nContext.Consumer>
)
