import 'blocks/readingroom/components/magazine.scss'

import {useReadingroomSearchParams} from 'blocks/readingroom/readingroom'
import {Column, Grid} from 'layout/grid'
import {Loader} from 'layout/loader'
import {Imagepreview} from 'layout/preview/imagepreview'
import {PreviewType} from 'layout/preview/previewtype'
import {Theme} from 'layout/theme'
import React from 'react'
import {t} from 'util/i18'
import {Link} from 'util/link'
import {styler} from 'util/style'

const styles = styler('magazine', ['items', 'textual'])

export const Magazine: React.FC = ({ children }) => {
	return (
		<div className={styles()}>
			{children}
		</div>
	)
}

export const MagazineItems: React.FC<{
	items: Array<PreviewType>
}> = data => {
	const { items } = data

	return (
		<div className={styles.items()}>
			<Theme.Container>
					<Grid columns={1} xs={2} s={2} m={3} l={4} gap={[30, 65]}>
						{items.map((item, i) => (
							<Column key={i}>
								<Imagepreview {...item} date={item.display_date} />
							</Column>
						))}
					</Grid>
			</Theme.Container>
		</div>
	)
}

export const MagazineTextualItems: React.FC<{
	items: Array<PreviewType>
}> = data => {
	const { items } = data

	return (
		<div className={styles.textual()}>
			{items.map((result, i) => (
				<Link
					key={i}
					to={result.url}
					className={styles.textual()}
				>
					<Theme.Container>
						<p className={styles.textual('date')}>
							{result.display_date}
						</p>
						<div className={styles.textual('title')}>
							<Theme.H4>{result.title}</Theme.H4>
						</div>
						{result.description && (
							<div className={styles.textual('description')}>
								<Theme.Wysiwyg>{result.description}</Theme.Wysiwyg>
							</div>
						)}
					</Theme.Container>
				</Link>
			))}
		</div>
	)
}
