import './searchbar.scss'

import {Icon} from 'assets/icon'
import React from 'react'
import AnimateHeight from 'react-animate-height'
import {useTranslation} from 'util/i18'
import {Mod, styler} from 'util/style'

const styles = styler('searchbar', ['input', 'toggler', 'more'])

export const SearchbarInput: React.FC<{
	query: string
	onSearch: (query: string) => void
	mod?: Mod<'dark'>
}> = ({query, mod, onSearch}) => {
	const {
		readingroom: {searchbar}
	} = useTranslation()

	return (
		<div className={styles.input().mod(mod)}>
			<form
				onSubmit={(e) => {
					e.preventDefault()
					onSearch((e.target as any).search.value)
				}}
				className={styles.input('container')}
			>
				<input
					name="search"
					type="text"
					className={styles.input('container-input').toString()}
					placeholder={searchbar}
					defaultValue={query}
				/>
				<button
					type="submit"
					value="search"
					className={styles.input('container-icon').toString()}
				>
					<Icon icon="Search" />
				</button>
			</form>
		</div>
	)
}

export const SearchbarToggler: React.FC<{
	open: boolean
	onToggle: () => void
}> = ({open, onToggle, children}) => {
	return (
		<div className={styles.toggler()}>
			<a className={styles.toggler('toggle').is({open})} onClick={onToggle}>
				<div className={styles.toggler('toggle-label')}>
					{children}
					<Icon icon="ChevronDown" className={styles.toggler('toggle-icon')} />
				</div>
			</a>
		</div>
	)
}

export const SearchbarMore: React.FC<{
	open: boolean
}> = ({open, children}) => {
	return (
		<div className={styles.more()}>
			<AnimateHeight height={open ? 'auto' : 0} animateOpacity={true}>
				{children}
			</AnimateHeight>
		</div>
	)
}
