import './hero.scss'

import {Icon} from 'assets/icon'
import {BlocksContext} from 'blocks/blockscontext'
import {SubsiteModel, useSubsiteForUrl} from 'blocks/hero/hero_graph_subsites'
import {GatsbyImageProps} from 'gatsby-image'
import {Mousescroll} from 'layout/mousescroll'
import {BgFixed} from 'layout/partials/bg'
import {Image} from 'layout/partials/image'
import {PreviewType} from 'layout/preview/previewtype'
import {Theme} from 'layout/theme'
import React, {Fragment, useContext} from 'react'
import {ButtonType, ImageType, LinkType, WysiwygType} from 'types/basictypes'
import {Link} from 'util/link'
import {useLinks} from 'util/links'
import {styler} from 'util/style'
import {useLocation} from 'util/uselocation'

const styles = styler('hero', [
	'back',
	'subtitle',
	'title',
	'info',
	'description',
	'buttons',
	'button',
	'link',
	'breadcrumbs',
	'subsiteinfo',
	'product'
])

export type HeroProductType = {
	url: string
	image: ImageType
}

export type HeroType = {
	background_image?: ImageType
	title?: string
	subtitle?: string
	description?: WysiwygType
	buttons?: Array<ButtonType>
	back?: ButtonType
	eventInfo?: string
	scrollicon?: boolean
	mp4: string
	product?: PreviewType
}

export const Hero: React.FC<HeroType> = (data) => {
	const {
		background_image,
		subtitle,
		title,
		description,
		buttons,
		back,
		eventInfo,
		scrollicon = false,
		mp4,
		product
	} = data
	const {pageTitle, url} = useContext(BlocksContext)

	const hasBg = !!background_image
	const isDark = !hasBg || background_image.meta.useDarkColor
	const subsite = useSubsiteForUrl(url)

	return (
		<div
			className={styles
				.mod({image: hasBg})
				.mod({darkcolors: isDark})
				.mod({product})}
		>
			{hasBg && <BgFixed image={background_image} mp4={mp4} />}
			{!subsite && <HeroBreadcrumbs pageTitle={title || pageTitle} />}
			{subsite && <HeroSubsiteInfo subsite={subsite} isDark={isDark} />}
			<div className={styles('content').mod({subsites: subsite})}>
				<Theme.Container>
					{subtitle && <HeroSubtitle>{subtitle}</HeroSubtitle>}
					<HeroTitle>{title || pageTitle}</HeroTitle>
					{eventInfo && <HeroInfo>{eventInfo}</HeroInfo>}
					{description && <HeroDescription>{description}</HeroDescription>}
					{buttons && <HeroButtons buttons={buttons} />}
					{back && <HeroBack {...back} />}
				</Theme.Container>
				{scrollicon && hasBg && (
					<div className={styles('scroll')}>
						<Theme.Container>
							<Mousescroll id="#content" />
						</Theme.Container>
					</div>
				)}
			</div>
			<HeroProduct product={product} />
		</div>
	)
}

export const HeroBack: React.FC<LinkType> = ({url, title}) => {
	const {
		location: {state}
	} = useLocation()

	return (
		<div className={styles.back()}>
			<Link
				className={styles.back('link')}
				to={url}
				onClick={(e) => {
					const referrer = state?.referrer
					const isEqual = referrer === url || referrer === url + '/'
					if (!isEqual) return
					window.history.back()
					e.preventDefault()
					return false
				}}
			>
				<div className={styles.back('link-circle')}>
					<Icon icon="ArrowLeft" />
				</div>
				<p className={styles.back('link-text')}>{title}</p>
			</Link>
		</div>
	)
}

export const HeroSubtitle: React.FC = ({children}) => {
	return <p className={styles.subtitle()}>{children}</p>
}

export const HeroTitle: React.FC = ({children}) => (
	<Theme.H1 className={styles.title()}>{children}</Theme.H1>
)

export const HeroInfo: React.FC = ({children}) => (
	<div className={styles.info()}>
		<p>{children}</p>
	</div>
)

export const HeroDescription: React.FC = ({children}) => (
	<div className={styles.description()}>
		<Theme.Wysiwyg mod="smallbtn">{children}</Theme.Wysiwyg>
	</div>
)

export const HeroButtons: React.FC<{buttons?: Array<ButtonType>}> = ({
	buttons
}) => {
	if (!buttons || buttons.length === 0) return null

	return (
		<div className={styles.buttons()}>
			{buttons.map((button, i) => (
				<HeroButton key={i} url={button?.url}>
					{button.title}
				</HeroButton>
			))}
		</div>
	)
}

export const HeroButton: React.FC<{
	url: string
}> = ({url, children}) => {
	if (!url) return null

	return (
		<div className={styles.button()}>
			<Theme.Button to={url} mod="rounded">
				<Theme.ButtonText>{children}</Theme.ButtonText>
				<Theme.ButtonIcon />
			</Theme.Button>
		</div>
	)
}

export const HeroLink: React.FC<LinkType> = (data) => {
	const {url, title} = data
	if (!url) return null

	return (
		<div className={styles.link()}>
			<Theme.Link to={url}>
				<Theme.LinkText>{title}</Theme.LinkText>
				<Theme.LinkIcon />
			</Theme.Link>
		</div>
	)
}

export const HeroBreadcrumbs: React.FC<{
	pageTitle: string
	pageurl?: string
}> = (data) => {
	const {pageTitle, pageurl} = data
	const {home} = useLinks()
	if (!pageTitle) return null

	const {parents} = useContext(BlocksContext)
	const reversedParents = parents ? [...parents] : []
	reversedParents.reverse()

	return (
		<div className={styles.breadcrumbs()}>
			<Theme.Container>
				<Link
					to={home.url}
					className={styles.breadcrumbs('icon')}
					title={home.title}
				>
					<Icon icon="Home" />
				</Link>
				{reversedParents.map((parent, i) => (
					<Fragment key={i}>
						<span className={styles.breadcrumbs('divider')}>/</span>
						<Link to={parent.url} className={styles.breadcrumbs('link')}>
							{parent.title}
						</Link>
					</Fragment>
				))}
				<span className={styles.breadcrumbs('divider')}>/</span>
				{!pageurl && (
					<span className={styles.breadcrumbs('link')}>{pageTitle}</span>
				)}
				{pageurl && (
					<Link to={pageurl} className={styles.breadcrumbs('link')}>
						{pageTitle}
					</Link>
				)}
			</Theme.Container>
		</div>
	)
}

export const HeroSubsiteInfo: React.FC<{
	subsite: SubsiteModel
	isDark: boolean
}> = ({subsite, isDark}) => {
	const {url} = useContext(BlocksContext)
	const logo = isDark ? subsite.logo_dark : subsite.logo_light
	const activeRoot = subsite.roots.find(
		(root) =>
			root.url === url || root.children.find((child) => child.url === url)
	)

	return (
		<div className={styles.subsiteinfo()}>
			<Theme.Container>
				{logo && (
					<div className={styles.subsiteinfo('logo')}>
						<Link
							to={subsite.url}
							className={styles.subsiteinfo('logo-image')}
							style={{width: 30 / (logo.meta.height / logo.meta.width)}}
						>
							<Image image={logo} aspectRatio="free" />
						</Link>
					</div>
				)}
				<div className={styles.subsiteinfo('subPages')}>
					{subsite.roots.map((root) => (
						<Link
							key={root.tid}
							to={root.url}
							className={styles
								.subsiteinfo('subPages-item')
								.mod({active: root === activeRoot})}
						>
							{root.name}
						</Link>
					))}
				</div>
			</Theme.Container>
		</div>
	)
}

const HeroProduct: React.FC<{product?: PreviewType}> = ({product}) => {
	if (!product || !product.image) return null

	return (
		<div className={styles.product()}>
			<Image
				aspectRatio="free"
				image={{
					...product?.image,
					objectFit: 'contain'
				}}
			/>
		</div>
	)
}
