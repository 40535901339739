import {graphql, useStaticQuery} from 'gatsby'
import {RequestStaticQuery} from 'types/graphtypes'

export type RequestStaticData = {
	identifications: Array<string>
	topics: Array<string>
}

export const useStaticRequestQuery = (): RequestStaticData => {
	const data = useStaticQuery(graphql`
		query RequestStatic {
			identifications: allTaxonomyTermRequestIdentifications {
				nodes {
					name
				}
			}
			topics: allTaxonomyTermRequestTopics {
				nodes {
					name
				}
			}
		}
	`)

	const identifications = data.identifications.nodes.map(item => item.name)
	const topics = data.topics.nodes.map(item => item.name)

	return {
		identifications,
		topics
	}
}
