import {NewsType} from 'blocks/news/news'
import {graphql, useStaticQuery} from 'gatsby'
import React from 'react'
import {NewsFragment} from 'types/graphtypes'
import {PreviewType} from 'layout/preview/previewtype'
import {previewFragmentToPreviewItem} from 'layout/preview/preview_graph'

export const getNewsData: (frag: NewsFragment) => NewsType = frag => {
	const {field_news_title} = frag

	return {
		title: field_news_title
	}
}

export const useStaticNewsQuery = (): Array<PreviewType> => {
	const data = useStaticQuery(graphql`
		query NewsStatic {
			allPagePreview(
				limit: 13
				sort: {fields: date, order: DESC}
				filter: {
					type: {in: ["press"]}
				}
			) {
				nodes {
					...Preview
				}
			}
		}
	`)
	return data.allPagePreview.nodes.map(previewFragmentToPreviewItem)
}

export const fragment = graphql`
	fragment News on paragraph__news {
		id
		field_news_title
	}
`
