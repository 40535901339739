//Note: de svg loader die we gebruiken is anders als in andere project waardoor de opsomming hier wat onhandiger is
//TODO: dit proberen in orde te krijgen

import Academic from 'assets/icons/academic.svg'
import ArrowDown from 'assets/icons/arrow-down.svg'
import ArrowLeft from 'assets/icons/arrow-left.svg'
import ArrowRight from 'assets/icons/arrow-right.svg'
import Bell from 'assets/icons/bell.svg'
import Book from 'assets/icons/book.svg'
import Bookmark from 'assets/icons/bookmark.svg'
import Career from 'assets/icons/career.svg'
import ChevronDown from 'assets/icons/chevron-down.svg'
import ChevronLeft from 'assets/icons/chevron-left.svg'
import ChevronRight from 'assets/icons/chevron-right.svg'
import Close from 'assets/icons/close.svg'
import Download from 'assets/icons/download.svg'
import Envelope from 'assets/icons/envelope.svg'
import Facebook from 'assets/icons/facebook.svg'
import File from 'assets/icons/file.svg'
import Flickr from 'assets/icons/flickr.svg'
import Fullscreen from 'assets/icons/fullscreen.svg'
import Home from 'assets/icons/home.svg'
import Instagram from 'assets/icons/instagram.svg'
import LinkedIn from 'assets/icons/linkedin.svg'
import Minus from 'assets/icons/minus.svg'
import Mobile from 'assets/icons/mobile.svg'
import Phone from 'assets/icons/phone.svg'
import Plus from 'assets/icons/plus.svg'
import Search from 'assets/icons/search.svg'
import Settings from 'assets/icons/settings.svg'
import Twitter from 'assets/icons/twitter.svg'
import Vimeo from 'assets/icons/vimeo.svg'
import YouTube from 'assets/icons/youtube.svg'

export default {
	Academic,
	ArrowDown,
	ArrowLeft,
	ArrowRight,
	Bell,
	Book,
	Bookmark,
	Career,
	ChevronDown,
	ChevronLeft,
	ChevronRight,
	Close,
	Download,
	Envelope,
	Facebook,
	File,
	Flickr,
	Fullscreen,
	Home,
	Instagram,
	LinkedIn,
	Minus,
	Mobile,
	Phone,
	Plus,
	Search,
	Settings,
	Twitter,
	Vimeo,
	YouTube
}
