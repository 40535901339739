import './job.scss'

import {Bg} from 'layout/partials/bg'
import {Theme} from 'layout/theme'
import {Blocks} from 'blocks/blocks'
import React from 'react'
import {ImageType, WysiwygType} from 'types/basictypes'
import {t} from 'util/i18'
import {styler} from 'util/style'
import {Layout} from 'layout/layout'
import {Block, BlockType} from 'blocks/block'
import {Textblock} from 'blocks/textblock/textblock'
import {HeroBreadcrumbs, HeroButton, HeroDescription, HeroSubtitle, HeroTitle} from 'blocks/hero/hero'
import {Share} from 'blocks/share/share'

const styles = styler('job', ['hero', 'apply'])

export type JobType = {
	header_image?: ImageType
	background_overlay?: number
	title: string
	date: string
	category: string
	location: string
	body: WysiwygType
	short_description?: WysiwygType
	applylink?: string
	blocks: Array<BlockType>
}

export type JobHeroType = {
	header_image?: ImageType
	background_overlay?: number
	title: string
	subtitle: string
	short_description?: WysiwygType
	applylink?: string
}

const getRelativeDate = (date: Date) => {
	if(!date) return null

	const today = new Date()
	const difference = (today.getTime() - date.getTime()) / (1000 * 60 * 60 * 24)

	switch (true) {
		case difference < 7:
			return 'Just now'
		case difference < 14:
			return 'About a week ago'
		default:
			return 'More than two weeks ago'
	}
}

export const Job: React.FC<JobType> = data => {
	const {
		header_image,
		background_overlay,
		title,
		date,
		short_description,
		applylink,
		body,
		category,
		location,
		blocks
	} = data

	const jsDate = new Date(Date.parse(date.replace(/-/g, '/')))

	return (
		<Layout>
			<div className={styles()}>
				<Blocks>
					<JobHero
						header_image={header_image}
						background_overlay={background_overlay}
						title={title}
						subtitle={getJobtitleString(category, location, jsDate)}
						short_description={short_description}
						applylink={applylink}
					/>
					<div className={styles('content')}>
						<Textblock text={body} />
						<JobApply link={applylink} />
						<Share />
					</div>
					{blocks.map((block, i) => (
						<Block {...block} blocks={blocks} key={i} />
					))}
				</Blocks>
			</div>
		</Layout>
	)
}

export const JobApply: React.FC<{
	link: string
}> = ({link}) => {
	if(!link) return null

	return <div className={styles.apply()}>
		<Theme.Container>
			<HeroButton url={link}>{t('job.apply')}</HeroButton>
		</Theme.Container>
	</div>
}

export function getJobtitleString(category: string, location: string, date: Date) {
	const relativeDate = getRelativeDate(date)

	if(!category && !location) return relativeDate
	if(!location || !location) return `${category || location} | ${relativeDate}`
	return `${category} - ${location} | ${relativeDate}`
}

export const JobHero: React.FC<JobHeroType> = data => {
	const {
		header_image,
		background_overlay = 50,
		title,
		subtitle,
		short_description,
		applylink
	} = data

	return (
		<div
			className={styles.hero().mod({
				image: header_image
			})}
		>
			{header_image && <Bg image={header_image} overlay={background_overlay} />}
			<HeroBreadcrumbs pageTitle={title} />
			<div className={styles('content')} >
				<Theme.Container>
					<HeroTitle>{title}</HeroTitle>
					<HeroSubtitle>{subtitle}</HeroSubtitle>
					<HeroDescription>{short_description}</HeroDescription>
					<HeroButton url={applylink}>{t('job.apply')}</HeroButton>
				</Theme.Container>
			</div>
		</div>
	)
}
