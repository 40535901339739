import {graphql, useStaticQuery} from 'gatsby'
import {HeaderLinkType} from 'layout/partials/header/header'

const compare = (item1, item2) => {
	if (item1.weight < item2.weight) return -1
	if (item1.weight > item2.weight) return 1
	return 0
}

export const useStaticHeaderQuery: () => Array<HeaderLinkType> = () => {
	const data = useStaticQuery(graphql`
		query {
			allTaxonomyTermMenu(sort: {order: ASC, fields: weight}) {
				items: nodes {
					id
					name
					weight
					parent {
						id
					}
					field_description
					fields {
						field_link {
							url
						}
					}
					relationships {
						parent {
							id
						}
					}
				}
			}
		}
	`)

	const items = data.allTaxonomyTermMenu.items.sort((item1, item2) => {
		if (item1.weight < item2.weight) return -1
		if (item1.weight > item2.weight) return 1
		return 0
	})

	const rootItems = items.filter(item => !item.relationships.parent.length)
	return rootItems.map(rootItem => {
		const children = items
			.filter(item => item.relationships.parent[0])
			.filter(item => rootItem.id === item.relationships.parent[0].id)
			.map(item => {
				return {
					title: item.name,
					description: item.field_description,
					url: item.fields ? item.fields.field_link.url : null
				}
			})

		return {
			title: rootItem.name,
			id: rootItem.id,
			url: rootItem.fields ? rootItem.fields.field_link.url : null,
			children
		}
	})
}
