import './articles.scss'

import {Carousel, useCarousel} from '@codeurs/carousel'
import {Icon} from 'assets/icon'
import {useStaticArticlesQuery} from 'blocks/articles/articles_graph'
import {BlocksContext} from 'blocks/blockscontext'
import {useStaticRelatedQuery} from 'blocks/related/related_graph'
import {Link} from 'gatsby'
import {CarouselControls} from 'layout/carousel/carouselcontrols'
import {Imagepreview} from 'layout/preview/imagepreview'
import {preview} from 'layout/preview/preview_graph'
import {PreviewType} from 'layout/preview/previewtype'
import {Theme} from 'layout/theme'
import React, {useContext} from 'react'
import {CarouselType} from 'types/basictypes'
import {filterDoubles} from 'util/filterdoubles'
import {t} from 'util/i18'
import {useLinks} from 'util/links'
import {styler} from 'util/style'

const styles = styler('articles', ['subjects', 'content', 'contentextra'])

export type ArticlesType = {
	title: string
	label?: string
	tags?: Array<{name?: string}>
}

export const Articles: React.FC<ArticlesType> = (data) => {
	const {title, label, tags} = data
	const carousel = useCarousel()
	const {readingroom} = useLinks()
	const {relatedContent, url} = useContext(BlocksContext)
	const staticItems = useStaticArticlesQuery()
	const items = filterDoubles([
		...relatedContent.filter((prev) => prev.type === 'article'),
		...staticItems
	])
		.filter((item) => item.url !== url)
		.slice(0, 12)

	return (
		<div className={styles()}>
			<Theme.Container>
				<div className={styles('top')}>
					<div className={styles('top-title')}>
						<Link to={readingroom.url}>
							<Theme.H2 mod={['blueline', 'arrow']}>
								{title || readingroom.title}
							</Theme.H2>
						</Link>
					</div>
				</div>
				<ArticlesSubjects label={label} tags={tags} />
				{items && items.length !== 0 && (
					<div className={styles('controls')}>
						<CarouselControls carousel={carousel} />
					</div>
				)}
			</Theme.Container>
			<ArticlesContent carousel={carousel} articles={items} />
		</div>
	)
}

export const ArticlesSubjects: React.FC<{
	label: string
	tags: Array<{name?: string}>
}> = (data) => {
	const {label, tags} = data
	if (!tags || tags.length === 0) return null

	const {readingroom} = useLinks()
	const tagUrl = readingroom.url + '?tags='

	return (
		<div className={styles.subjects()}>
			{label && <Theme.H5>{label}</Theme.H5>}
			<div className={styles.subjects('list')}>
				{tags.map((tag, i) => (
					<div key={i} className={styles.subjects('list-item')}>
						<div className={styles.subjects('list-item-tag')}>
							<Link
								to={tagUrl + tag.name}
								className={styles.subjects('list-item-tag-label')}
							>
								{tag.name}
							</Link>
						</div>
					</div>
				))}
				<div className={styles.subjects('list-item')}>
					<div className={styles.subjects('list-item-tag').mod('link')}>
						<Theme.Link to={readingroom.url + '?open=1'}>
							<Theme.LinkText>{t('words.show_all')}</Theme.LinkText>
							<Theme.LinkIcon />
						</Theme.Link>
					</div>
				</div>
			</div>
		</div>
	)
}

export const ArticlesContent: React.FC<{
	carousel: CarouselType
	articles: Array<PreviewType>
}> = (data) => {
	const {carousel, articles} = data
	if (!articles || articles.length === 0) return null

	return (
		<div className={styles.content()}>
			<div className={styles.content('background')}>
				<Theme.Container>
					<div className={styles.content('items')}>
						<Carousel {...carousel} full>
							{articles.map((item, i) => (
								<div
									key={i}
									className={styles
										.content('items-item')
										.is({active: carousel.isActive(i)})}
								>
									<Imagepreview {...item} date={item.display_date} />
								</div>
							))}
							<div className={styles.content('items-item')}>
								<ArticlesContentExtra />
							</div>
						</Carousel>
					</div>
				</Theme.Container>
			</div>
		</div>
	)
}

export const ArticlesContentExtra: React.FC = () => {
	const {readingroom} = useLinks()

	return (
		<Link to={readingroom.url} className={styles.contentextra()}>
			<div className={styles.contentextra('content')}>
				<div className={styles.contentextra('content-icon')}>
					<Icon icon="Book" />
				</div>
				<Theme.H4 className={styles.contentextra('content-title')} mod="light">
					{t('article.readmore')}
				</Theme.H4>
			</div>
		</Link>
	)
}
