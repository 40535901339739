import './layout.scss'

import {Footer} from 'layout/partials/footer/footer'
import {Header} from 'layout/partials/header/header'
import {Theme} from 'layout/theme'
import React from 'react'
import {Mod, styler} from 'util/style'
import {Cases, CasesType} from '../blocks/cases/cases'
import {GridProvider} from './grid'

const styles = styler('layout')

export const Layout: React.FC<{
	cases?: CasesType
	mod?: Mod<'preview'>
}> = ({cases, mod, children}) => {
	return (
		<GridProvider xxs={{gap: [5, 50]}} xs={{gap: [15, 50]}} gap={[30, 50]}>
			<div className={styles().mod(mod)}>
				<Header />
				<Theme.OuterContainer>
					{cases && <Cases mod="fullheight" {...cases} />}
				</Theme.OuterContainer>
				<span id="scroll" />
				<Theme.OuterContainer>
					<div className={styles('content')}>{children}</div>
				</Theme.OuterContainer>
				<Footer />
			</div>
		</GridProvider>
	)
}
