import {JobsSearchModel} from 'blocks/jobs/models/JobsSearchModel'
import {WysiwygType} from 'types/basictypes'
import memoizeGetters from 'memoize-getters'

export type JobState = {
	id: number
	title: string
	category: string
	location: string
	req: string
	date: string
	description?: WysiwygType
	url: string,
	is_academic: boolean,
	is_career: boolean,
	terms: Array<number>
}

export type JobActiveFilter = {
	name: string,
	tid: number
}

export class JobModel {
	state: JobState
	search: JobsSearchModel

	constructor(search: JobsSearchModel, state: JobState) {
		this.search = search
		this.state = state
	}

	get id() {
		return this.state.id
	}

	get category() {
		return this.state.category
	}

	get location() {
		return this.state.location
	}

	get date(): Date {
		return new Date(Date.parse(this.state.date.replace(/-/g, '/')));
	}

	get daysPosted(): number {
		const today = new Date()
		return (today.getTime() - this.date.getTime()) / (1000 * 60 * 60 * 24)
	}

	get title() {
		return this.state.title
	}

	get isValid(): boolean {
		if(this.search.category === 'academic' && !this.state.is_academic) return false
		if(this.search.category === 'career' && !this.state.is_career) return false
		return !this.search.filters.find(filter => !filter.validateJob(this))
	}

	get url() {
		return this.state.url
	}

	get description() {
		return this.state.description
	}

	get isBookmarked() {
		return this.search.state.bookmarked.find(id => id === this.id)
	}

	// ----- Update state ----- //
	update(newState: Partial<JobState>): JobsSearchModel {
		return this.search.update({
			jobs: this.search.state.jobs.map(j =>
				j !== this.state ? j : { ...this.state, ...newState }
			)
		})
	}

	toggleBookmark(): JobsSearchModel {
		const newBookmarked = this.isBookmarked ?
			this.search.bookmarked.filter(job => job !== this) :
			[...this.search.bookmarked, this]

		return this.search.update({
			bookmarked: newBookmarked.map(job => job.id)
		})
	}
}
memoizeGetters(JobModel)

