import {graphql, useStaticQuery} from 'gatsby'

export type TagModel = {
  parent: TagModel | null
  children: Array<TagModel>
  parent_tid: number
  name: string
  full_name: string
  tid: number
}

let tags: Array<TagModel> = null
export const useStaticReadingroomTagsQuery = (): Array<TagModel> => {
  const data = useStaticQuery(graphql`
		query ReadingroomTagsStatic {
			allTaxonomyTermTag {
        nodes {
          drupal_internal__tid
          name
          relationships {
            parent {
              drupal_internal__tid
            }
          }
        }
			}
		}
	`)
  if (tags) return tags

  tags = data.allTaxonomyTermTag.nodes.map(tagData => ({
    parent: null,
    children: [],
    name: tagData.name,
    full_name: tagData.name,
    tid: tagData.drupal_internal__tid,
    parent_tid: tagData.relationships.parent[0]?.drupal_internal__tid
  } as TagModel))

  tags.forEach(tag => {
    const parent = tags.find(t => t.tid === tag.parent_tid)

    if (parent) {
      tag.parent = parent
      tag.full_name = parent.name + ' > ' + tag.name
      parent.children.push(tag)
    }
  })

  return tags
}
