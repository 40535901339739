import {PreviewType} from 'layout/preview/previewtype'
import {graphql, useStaticQuery} from 'gatsby'
import {previewFragmentToPreviewItem} from 'layout/preview/preview_graph'

export const useStaticReadingroomPopulairQuery = (): Array<PreviewType> => {
  const data = useStaticQuery(graphql`
		query ReadingroomPopulairStatic {
			allPagePreview(
				limit: 4
				sort: {fields: popularity, order: DESC}
				filter: {has_image: {eq: true}, appear_in_readingroom: {eq: true}}
			) {
				nodes {
					...Preview
				}
			}
		}
	`)
  return data.allPagePreview.nodes.map(previewFragmentToPreviewItem)
}
