import {ComponentType, createContext, FunctionComponent} from 'react'

type DOMAttrs = any
type ThemeStore = Record<string, any>
type Component<A = DOMAttrs> = ComponentType<A>

const ThemeContext = createContext<ThemeStore>({})

const widget: <A = DOMAttrs>(
	view: Record<string, Component<A>>
) => FunctionComponent<A> = view => {
	const name = Object.keys(view)[0]
	const defaultView = Object.values(view)[0]
	return attrs => (
		<ThemeContext.Consumer>
			{(theme: ThemeStore) => {
				const {children, ...rest} = attrs
				const View = theme[name] || defaultView
				return <View {...rest}>{children} </View>
			}}
		</ThemeContext.Consumer>
	)
}

export const createTheme = <
	T extends {
		[key: string]: Component<any>
	}
>(
	views: T
): {[P in keyof T]: T[P]} &
	FunctionComponent<{extend: Partial<{[P in keyof T]: Component<any>}>}> =>
	Object.assign(
		({children, extend}) => (
			<ThemeContext.Consumer>
				{theme => (
					<ThemeContext.Provider value={{...theme, ...extend}}>
						{children}
					</ThemeContext.Provider>
				)}
			</ThemeContext.Consumer>
		),
		Object.entries(views).reduce(
			(acc, [name, value]) => ({
				...acc,
				[name]: widget({[name]: value})
			}),
			{}
		)
	) as any
