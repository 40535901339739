import './inpagenav.scss'

import {AnchorType} from 'blocks/anchor/anchor'
import {BlockType} from 'blocks/block'
import {Theme} from 'layout/theme'
import React from 'react'
import {LinkType} from 'types/basictypes'
import {Link} from 'util/link'
import {styler} from 'util/style'

const styles = styler('inpagenav')

export type InpagenavType = {
	displayDark?: boolean
	links: Array<{
		id: string
		label: string
	}>
	cta?: LinkType
}

export const Inpagenav: React.FC<InpagenavType & {blocks: Array<BlockType>}> = (
	data
) => {
	const {links, blocks, displayDark = false, cta} = data
	if (!links) return null

	const extraLinks = blocks
		.filter((bl) => bl && bl.type === 'anchor')
		.map((bl) => ({
			id: '#' + (bl as AnchorType).id,
			label: (bl as AnchorType).title
		}))

	return (
		<div className={styles().is({dark: displayDark})}>
			<div className={styles('row')}>
				<div className={styles('items')}>
					{[...links, ...extraLinks].map(
						(item, i) =>
							item.id &&
							item.label && (
								<div key={i} className={styles('items-item')}>
									<Link
										to={item.id}
										className={styles('items-item-link').is({
											dark: displayDark
										})}
									>
										{item.label}
									</Link>
								</div>
							)
					)}
				</div>
				{cta && cta.url && (
					<div className={styles('button')}>
						<Theme.Button to={cta.url} mod={['rounded', 'white']}>
							<Theme.ButtonText>{cta.title}</Theme.ButtonText>
							<Theme.ButtonIcon />
						</Theme.Button>
					</div>
				)}
			</div>
		</div>
	)
}
