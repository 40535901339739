import {Articles, ArticlesType} from 'blocks/articles/articles'
import {BackgroundBlock, BackgroundBlockType} from 'blocks/backgroundblock/backgroundblock'
import {Blocks2, Blocks2Type} from 'blocks/blocks2/blocks2'
import {Calendar, CalendarType} from 'blocks/calendar/calendar'
import {Cases, CasesType} from 'blocks/cases/cases'
import {Columns2, Columns2Type} from 'blocks/columns2/columns2'
import {Columns3, Columns3Type} from 'blocks/columns3/columns3'
import {Contact, ContactType} from 'blocks/contact/contact'
import {Contactform, ContactFormType} from 'blocks/contactform/contactform'
import {Gallery, GalleryType} from 'blocks/gallery/gallery'
import {Hero, HeroType} from 'blocks/hero/hero'
import {HeroHome, HeroHomeType} from 'blocks/herohome/herohome'
import {Imageblock, ImageblockType} from 'blocks/imageblock/imageblock'
import {Imagetext, ImagetextType} from 'blocks/imagetext/imagetext'
import {Inpagenav, InpagenavType} from 'blocks/inpagenav/inpagenav'
import {Links, LinksType} from 'blocks/links/links'
import {List, ListType} from 'blocks/list/list'
import {News, NewsType} from 'blocks/news/news'
import {Offices, OfficesType} from 'blocks/offices/offices'
import {Overview, OverviewType} from 'blocks/overview/overview'
import {Process, ProcessType} from 'blocks/process/process'
import {Profile, ProfileType} from 'blocks/profile/profile'
import {Readingroom, ReadingroomType} from 'blocks/readingroom/readingroom'
import {Related, RelatedType} from 'blocks/related/related'
import {Services, ServicesType} from 'blocks/services/services'
import {Testimonials, TestimonialsType} from 'blocks/testimonials/testimonials'
import {Textblock, TextblockType} from 'blocks/textblock/textblock'
import {VideoBlock, VideoblockType} from 'blocks/videoblock/videoblock'
import React from 'react'
import {IframeBlock, IframeblockType} from './iframeblock/iframeblock'
import {Search, SearchType} from './search/search'
import {Agenda, AgendaType} from './agenda/agenda'
import {Anchor, AnchorType} from './anchor/anchor'
import {Request} from './request/request'
import {Iconoverview} from './iconoverview/iconoverview'
import {Share} from './share/share'
import {Downloads, DownloadsType} from './downloads/downloads'
import {Sponsors, SponsorsType} from './sponsors/sponsors'
import {Centered, CenteredType} from './centered/centered'
import {Error, Error as ErrorBlock, ErrorType} from 'blocks/error/error'
import {Relatedjobs, RelatedjobsType} from 'blocks/relatedjobs/relatedjobs'
import {Iconintro, IconintroType} from 'blocks/iconintro/iconintro'
import {Jobs} from 'blocks/jobs/jobs'
import {Products, ProductsType} from 'blocks/products/products'
import {ProductsPreview, ProductsPreviewType} from 'blocks/productspreview/productspreview'
import {ProductHero, ProductHeroType} from 'blocks/producthero/producthero'
import {Faq, FaqType} from 'blocks/faq/faq'
import {Specifications, SpecificationsType} from 'blocks/specifications/specifications'

export type BlockType =
	| ({type: 'services'} & ServicesType)
	| ({type: 'textblock'} & TextblockType)
	| ({type: 'herohome'} & HeroHomeType)
	| ({type: 'articles'} & ArticlesType)
	| ({type: 'backgroundblock'} & BackgroundBlockType)
	| ({type: 'blocks2'} & Blocks2Type)
	| ({type: 'calendar'} & CalendarType)
	| ({type: 'columns3'} & Columns3Type)
	| ({type: 'hero'} & HeroType)
	| ({type: 'news'} & NewsType)
	| ({type: 'columns2'} & Columns2Type)
	| ({type: 'contact'} & ContactType)
	| ({type: 'contactform'} & ContactFormType)
	| ({type: 'imagetext'} & ImagetextType)
	| ({type: 'links'} & LinksType)
	| ({type: 'list'} & ListType)
	| ({type: 'offices'} & OfficesType)
	| ({type: 'overview'} & OverviewType)
	| ({type: 'process'} & ProcessType)
	| ({type: 'related'} & RelatedType)
	| ({type: 'testimonials'} & TestimonialsType)
	| ({type: 'imageblock'} & ImageblockType)
	| ({type: 'videoblock'} & VideoblockType)
	| ({type: 'cases'} & CasesType)
	| ({type: 'reading_room'} & ReadingroomType)
	| ({type: 'profile'} & ProfileType)
	| ({type: 'gallery'} & GalleryType)
	| ({type: 'inpagenav'} & InpagenavType)
	| ({type: 'iframeblock'} & IframeblockType)
	| ({type: 'search'} & SearchType)
	| ({type: 'agenda'} & AgendaType)
	| ({type: 'anchor'} & AnchorType)
	| ({type: 'icon_overview'})
	| ({type: 'downloads'} & DownloadsType)
	| ({type: 'sponsors'} & SponsorsType)
	| ({type: 'centered'} & CenteredType)
	| ({type: 'error'} & ErrorType)
	| ({type: 'relatedjobs' } & RelatedjobsType)
	| ({type: 'jobs'})
	| ({type: 'iconintro'} & IconintroType)
	| ({type: 'products'} & ProductsType)
	| ({type: 'producthero'} & ProductHeroType)
	| ({type: 'productspreview'} & ProductsPreviewType)
	| ({type: 'specifications'} & SpecificationsType)
	| ({type: 'faq'} & FaqType)
	| {type: 'request'}
	| {type: 'share'}

export const Block: React.FC<BlockType & {
	pageType?: string
	blocks: Array<BlockType>
	eventInfo?: string
}> = ({ pageType, blocks, eventInfo, ...bl }) => {

	switch (bl.type) {
		case 'services':
			return <Services {...bl} />
		case 'textblock':
			return <Textblock {...bl} />
		case 'herohome':
			return <HeroHome {...bl} />
		case 'articles':
			return <Articles {...bl} />
		case 'backgroundblock':
			return <BackgroundBlock {...bl} pageType={pageType} />
		case 'blocks2':
			return <Blocks2 {...bl} />
		case 'calendar':
			return <Calendar {...bl} />
		case 'columns3':
			return <Columns3 {...bl} />
		case 'hero':
			return <Hero {...bl} eventInfo={eventInfo} />
		case 'news':
			return <News {...bl} />
		case 'columns2':
			return <Columns2 {...bl} />
		case 'contact':
			return <Contact {...bl} />
		case 'contactform':
			return <Contactform {...bl} />
		case 'imagetext':
			return <Imagetext {...bl} />
		case 'links':
			return <Links {...bl} />
		case 'list':
			return <List {...bl} />
		case 'offices':
			return <Offices {...bl} />
		case 'overview':
			return <Overview {...bl} />
		case 'process':
			return <Process {...bl} />
		case 'related':
			return <Related {...bl} />
		case 'testimonials':
			return <Testimonials {...bl} />
		case 'imageblock':
			return <Imageblock {...bl} />
		case 'videoblock':
			return <VideoBlock {...bl} />
		case 'cases':
			return <Cases {...bl} />
		case 'gallery':
			return <Gallery {...bl} />
		case 'profile':
			return <Profile {...bl} />
		case 'reading_room':
			return <Readingroom {...bl} />
		case 'inpagenav':
			return <Inpagenav {...bl} blocks={blocks} />
		case 'iframeblock':
			return <IframeBlock {...bl} />
		case 'search':
			return <Search {...bl} />
		case 'agenda':
			return <Agenda {...bl} />
		case 'anchor':
			return <Anchor {...bl} />
		case 'icon_overview':
			return <Iconoverview />
		case 'request':
			return <Request />
		case 'share':
			return <Share />
		case 'downloads':
			return <Downloads {...bl} />
		case 'sponsors':
			return <Sponsors {...bl} />
		case 'centered':
			return <Centered {...bl} />
		case 'relatedjobs':
			return <Relatedjobs {...bl} />
		case 'iconintro':
			return <Iconintro {...bl} />
		case 'error':
			return <Error {...bl} />
		case 'jobs':
			return <Jobs {...bl} />
		case 'products':
			return <Products {...bl} />
		case 'producthero':
			return <ProductHero {...bl} />
		case 'productspreview':
			return <ProductsPreview {...bl} />
		case 'specifications':
			return <Specifications {...bl} />
		case 'faq':
			return <Faq {...bl} />
	}

	//return assertUnreachable(bl);
	return <p>Not yet supported type: {(bl as any).type}</p>
}

/*function assertUnreachable(type: never): never {
	throw new Error(`Unreachable code`)
}*/
