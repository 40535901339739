import './jobs.scss'

import {JobAlerts} from 'blocks/jobs/jobalerts'
import {JobsCategory, JobsSearchModel} from 'blocks/jobs/models/JobsSearchModel'
import {Column, Grid} from 'layout/grid'
import {SearchbarInput, SearchbarToggler} from 'layout/search/searchbar'
import {Theme} from 'layout/theme'
import React, {createContext, HTMLProps, useContext, useEffect, useState} from 'react'
import AnimateHeight from 'react-animate-height'
import {styler} from 'util/style'
import {useSearchParams} from 'util/uselocation'
import {Jobsfilters} from 'blocks/jobs/jobsfilters'
import {JobModel, JobState} from 'blocks/jobs/models/JobModel'
import {JobFilterKey} from 'blocks/jobs/models/JobFilterModel'
import {RegularJobTermState} from 'blocks/jobs/models/filters/TaxonomyFilterModel'
import {Icon} from 'assets/icon'
import {getJobtitleString} from 'layout/pages/job'
import {SearchresultsMore} from 'layout/search/searchresults'
import unfetch from 'unfetch'
import {Link} from 'util/link'

const styles = styler('jobs', ['bookmarkicon', 'item', 'tabs', 'search'])

export type JobsData = {
	types: Record<JobFilterKey, RegularJobTermState[]>
	items: JobState[]
}

export type JobsContextType = {
	search: JobsSearchModel,
	updateSearch: (newState: JobsSearchModel) => void
}

export const JobsContext = createContext<JobsContextType>(null)

let _data: JobsData = null
function fetchJobsData(): Promise<JobsData> {
	if(_data) {
		return Promise.resolve(_data)
	}
	return unfetch('/jobs.json')
		.then(response => response.json())
		.then(data => {
			_data = data
			return data
		})
}

export const Jobs: React.FC = () => {
	const { params, updateParams } = useSearchParams()
	const [search, setSearch] = useState<JobsSearchModel>(() => {
		if(!_data) return null
		return JobsSearchModel.createFromParams(_data, params)
	})
	const updateSearch = (newSearch: JobsSearchModel) => {
		setSearch(newSearch)
		localStorage.bookmarked_jobs = JSON.stringify(newSearch.bookmarked.map(job => job.id))
		const resetPage = search.state.bookmarked.length === newSearch.state.bookmarked.length
		updateParams({
			type: newSearch.state.category,
			filters: newSearch.state.terms,
			query: newSearch.state.query,
			page: resetPage ? 0 : params.page
		})
	}

	useEffect(() => {
		fetchJobsData().then(data  => {
			setSearch(JobsSearchModel.createFromParams(data, params))
		})
	}, [])

	if (!search) return null
	const nrOfItems = (params.page+1) * 12
	const visibleJobs = search.jobs.slice(0, nrOfItems)
	const showMore = search.jobs.length > nrOfItems

	return <div className={styles()}>
		<JobsContext.Provider value={{ search, updateSearch }}>
			<JobsTabs />
			<JobsSearch />
			<div className={styles('results')}>
				{visibleJobs.map(job => <JobsItem job={job} key={job.url} />)}
			</div>
			{showMore && <SearchresultsMore onClick={() => updateParams({ page: params.page + 1})}>
				LOAD MORE ({search.jobs.length - nrOfItems})
			</SearchresultsMore>}
		</JobsContext.Provider>
	</div >
}

export const JobsTabs: React.FC = () => {
	const { search, updateSearch } = useContext(JobsContext)
	const { params: {type}, createUrl } = useSearchParams()

	const tabs = [
		{key: 'career', icon: 'Career' as const, label: 'Career opportunities'},
		{key: 'academic', icon: 'Academic' as const, label: 'Academic opportunities'}
	]

	return <div className={styles.tabs()}>
		<Theme.Container>
			<div className={styles.tabs('tabs')}>
				<div className={styles.tabs('tabs-group')}>
					{tabs.map(tab => (
						<a
							key={tab.key}
							href={createUrl({type: tab.key})}
							onClick={e => {
								updateSearch(search.setCategory(tab.key as JobsCategory))
								e.preventDefault()
								return false
							}}
							className={styles.tabs('item').is({ active: search.category === tab.key })}
						>
							<Icon className={styles.tabs('item-icon')}  icon={tab.icon} />
							{tab.label}
						</a>
					))}
				</div>
				<div className={styles.tabs('tabs-group')}>
					<a
						href={createUrl({type: 'bookmarked'})}
						onClick={e => {
							updateSearch(search.setCategory('bookmarked'))
							e.preventDefault()
							return false
						}}
						className={styles.tabs('bookmark').is({selected: type === 'bookmarked'})}
					>
						<JobsBookmarkIcon active={search.bookmarked.length > 0} />
						{search.bookmarked.length} jobs saved
					</a>
				</div>
			</div>
		</Theme.Container>
	</div>
}

export const JobsSearch: React.FC = () => {
	const { search, updateSearch } = useContext(JobsContext)
	const [open, setOpen] = useState(false)

	return <div className={styles.search()}>
		<div className={styles.search('query')}>
			<Theme.Container>
				<Grid columns={1} s={1} m={12} l={12} align="top">
					<Column className={styles.search()} m={9} l={9}>
						<SearchbarInput
							onSearch={query => updateSearch(search.setQuery(query))}
							query={search.query}
							mod="dark"
						/>
					</Column>
					<Column m={3} l={3}>
						<SearchbarToggler
							open={open}
							onToggle={() => setOpen(!open)}
						>
							<Icon icon="Bell" inline />
							{open ? 'Hide criteria' : 'Notify me about new jobs'}
						</SearchbarToggler>
					</Column>
				</Grid>
				<div className={styles.search('criteria')}>
					<AnimateHeight height={open ? 'auto' : 0} animateOpacity={true}>
						<JobAlerts />
					</AnimateHeight>
				</div>
			</Theme.Container>
		</div>
		{search.category !== 'bookmarked' && <div className={styles.search('filters')}>
			<Theme.Container>
				<Jobsfilters />
			</Theme.Container>
		</div>}
	</div>
}

export const JobsBookmarkIcon: React.FC<{
	active: boolean
} & HTMLProps<HTMLDivElement>> = ({active, ...rest}) => {

	return <Icon
		{...rest}
		icon={'Bookmark'}
		className={styles.bookmarkicon().mergeProps(rest).is({active})}
	/>
}

export const JobsItem: React.FC<{
	job: JobModel
}> = ({job}) => {
	const {updateSearch} = useContext(JobsContext)

	return (
		<div className={styles.item()}>
			<Theme.Container>
				<div className={styles.item('title')}>
					<Theme.H4>
						<Link
							to={job.url}
							className={styles.item('title-link')}
						>
							{job.title}
						</Link>
					</Theme.H4>
					<JobsBookmarkIcon
						active={!!job.isBookmarked}
						onClick={() => updateSearch(job.toggleBookmark())}
						title="Bookmark this job"
					/>
				</div>
				<div className={styles.item('subtitle')}>
					{getJobtitleString(job.category, job.location, job.date)}
				</div>
				{job.description && (
					<div className={styles.item('description')}>
						{job.description.replace(/<[^>]*>?/g, '')}
					</div>
				)}
			</Theme.Container>
		</div>
	)
}
