import './theme.scss'

import {Icon} from 'assets/icon'
import {FunctionComponent, HTMLProps} from 'react'
import {createTheme} from 'util/createtheme'
import {Link as UtilLink} from 'util/link'
import {Mod, styler} from 'util/style'
import {Trusted} from 'util/trusted'
import {Column, Grid} from './grid'

type DOMAttrs = any
const appEnv: string = 'env'

const styles = styler('theme', [
	'outercontainer',
	'container',
	'h1',
	'h2',
	'h3',
	'h4',
	'h5',
	'p',
	'wysiwyg',
	'trustedtext',
	'button',
	'buttontext',
	'buttonicon',
	'link',
	'linktext',
	'linkicon'
])

const OuterContainer: FunctionComponent<
	{mod?: Mod<'big'>} & HTMLProps<HTMLDivElement>
> = ({mod, ...props}) => (
	<div
		{...props}
		className={styles.outercontainer.mod(mod).mergeProps(props)}
	/>
)

const Container: FunctionComponent<
	{fullwidth?: boolean} & HTMLProps<HTMLDivElement>
> = ({children, fullwidth = false, ...props}) => {
	return (
		<div
			{...props}
			className={styles.container.mod({fullwidth}).mergeProps(props)}
		>
			{children}
			{process.env.GATSBY_ENABLE_GRID && (
				<Grid className={styles.container('grid').toString()}>
					{Array.from(Array(12)).map((_, i) => (
						<Column
							className={styles.container('grid-item').toString()}
							key={i}
						/>
					))}
				</Grid>
			)}
		</div>
	)
}

type Header = FunctionComponent<
	{
		mod?: Mod<'light' | 'medium' | 'blueline' | 'arrow' | 'toggle'>
	} & HTMLProps<HTMLHeadingElement>
>

const H1: Header = ({as: Tag = 'h1', mod, children, ...props}) => (
	<div {...props}>
		<h1 className={styles.h1().mod(mod)}>{children}</h1>
	</div>
)
const H2: Header = ({as: Tag = 'h2', mod, children, ...props}) => (
	<div {...props}>
		<h2 className={styles.h2().mod(mod)}>{children}</h2>
	</div>
)
const H3: Header = ({as: Tag = 'h3', mod, children, ...props}) => (
	<div {...props}>
		<h3 className={styles.h3().mod(mod)}>{children}</h3>
	</div>
)
const H4: Header = ({as: Tag = 'h4', mod, children, ...props}) => (
	<div {...props}>
		<h4 className={styles.h4().mod(mod)}>{children}</h4>
	</div>
)
const H5: Header = ({as: Tag = 'h5', mod, children, ...props}) => (
	<div {...props}>
		<h5 className={styles.h5().mod(mod)}>{children}</h5>
	</div>
)

type P = FunctionComponent<HTMLProps<HTMLParagraphElement>>

const P: P = (props) => <p {...props} className={styles.p.mergeProps(props)} />

const Wysiwyg: FunctionComponent<
	{
		children: string
		mod?: Mod<'inherit'>
	} & DOMAttrs
> = (props) => (
	<Trusted
		{...props}
		className={styles.wysiwyg.mod(props.mod).mergeProps(props)}
	/>
)

const TrustedText: FunctionComponent<{children: string} & DOMAttrs> = (
	props
) => <Trusted {...props} className={styles.trustedtext.mergeProps(props)} />

type ButtonProps =
	| ({as: 'button'} & HTMLProps<HTMLButtonElement>)
	| HTMLProps<HTMLAnchorElement>

type Button = ButtonProps & {
	to?: string
	mod?: Mod<'outline' | 'rounded' | 'round' | 'white'>
}

const Button: FunctionComponent<Button> = (props) => {
	return (
		<UtilLink
			{...props}
			children={props.children}
			as={props.as}
			to={props.to}
			className={styles.button.mod(props.mod).mergeProps(props)}
		/>
	)
}

type Span = HTMLProps<HTMLSpanElement>

const ButtonText: FunctionComponent<Span> = (props) => (
	<span {...props} className={styles.buttontext.mergeProps(props)} />
)

const ButtonIcon: FunctionComponent<Span> = () => (
	<span className={styles.buttonicon()}>
		<Icon icon="ArrowRight" />
	</span>
)

export type ThemeLinkType = HTMLProps<HTMLAnchorElement> & {
	to?: string
	mod?: Mod<'inherit'>
}

const Link: FunctionComponent<ThemeLinkType> = ({
	children,
	to,
	target,
	mod,
	...rest
}) => {
	return (
		<UtilLink
			{...rest}
			to={to}
			className={styles.link.mod(mod).mergeProps(rest)}
		>
			{children}
		</UtilLink>
	)
}

const LinkText: FunctionComponent<Span> = (props) => (
	<span {...props} className={styles.linktext.mergeProps(props)} />
)

const LinkIcon: FunctionComponent<Span> = () => (
	<span className={styles.linkicon()}>
		<Icon icon="ArrowRight" />
	</span>
)

export const defaultTheme = {
	OuterContainer,
	Container,
	H1,
	H2,
	H3,
	H4,
	H5,
	P,
	Wysiwyg,
	TrustedText,
	Button,
	ButtonText,
	ButtonIcon,
	Link,
	LinkText,
	LinkIcon
}

export const Theme = createTheme(defaultTheme)
