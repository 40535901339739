import { graphql } from 'gatsby'
import { PreviewType } from 'layout/preview/previewtype'
import { PreviewFragment } from 'types/graphtypes'
import { getImageFromField } from 'util/drupal'

export const previewFragmentToPreviewItem: (
  frag: PreviewFragment
) => PreviewType = frag => {
  if (!frag) return null

  return {
    type: frag.type,
    url: frag.url,
    title: frag.title,
    node_id: frag.node_id,
    description: frag.description,
    date: frag.date,
    display_date: process.env.GATSBY_LANG === 'en' ? frag.date_en : frag.date_nl,
    parents: frag.parents,
    popularity: frag.popularity,
    tags: frag.tags,
    all_tags: frag.all_tags,
    label: frag.label,
    has_image: frag.has_image,
    appear_in_readingroom: frag.appear_in_readingroom,
    portrait_image: getImageFromField(frag.image, 'portrait'),
    image: getImageFromField(frag.image),
  }
}

export const preview = graphql`
	fragment Preview on PagePreview {
	  node_id
		title
		parents {
        type
        key
        title
        url
		}
		appear_in_readingroom
		has_image
		popularity
	  type
    url
		date(formatString: "YYYY-MM-DD")
		date_nl: date(formatString: "D MMMM YYYY", locale: "nl")
		date_en: date(formatString: "D MMMM YYYY", locale: "en")
		tags
		label
		image {
      ...ImageMeta
      relationships {
        field_media_image {
          localFile {
            portrait: childImageSharp {
						  fixed(width: 375, height: 700, cropFocus: CENTER) {
                ...GatsbyImageSharpFixed
              }
            }
            image: childImageSharp {
						  fixed(width: 600, height: 600, cropFocus: CENTER) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
	}
`
