import React from 'react'
import {Highlightpreview, HighlightpreviewType} from 'layout/preview/highlightpreview'
import {Theme} from 'layout/theme'
import {styler} from 'util/style'

const styles = styler('highlighted')

export const Highlighted: React.FC<{
  highlighted: HighlightpreviewType
}> = data => {
  const {highlighted} = data
  if (!highlighted) return null

  return (
    <div className={styles()}>
      <Theme.Container>
          <Highlightpreview {...highlighted} />
      </Theme.Container>
    </div>
  )
}
