import './jobsfilters.scss'

import {JobsContext} from 'blocks/jobs/jobs'
import React, {useContext, useState} from 'react'
import {JobFilterModel} from 'blocks/jobs/models/JobFilterModel'
import {FilterTag} from 'layout/search/filtertag'
import AnimateHeight from 'react-animate-height'
import {styler} from 'util/style'
import {useTranslation} from 'util/i18'
import {TaxonomyTermModel} from 'blocks/jobs/models/filters/TaxonomyFilterModel'
import {DatePostedTermModel} from 'blocks/jobs/models/filters/DatePostedFilterModel'
import {Icon} from 'assets/icon'

const styles = styler('jobsfilters', ['settings', 'settings_alt', 'item'])

export const Jobsfilters: React.FC = () => {
  const { search, updateSearch } = useContext(JobsContext)
  const [filterKey, setFilterKey] = useState(null)
  const [open, setOpen] = useState(false)
  const filter = search.filters.find(filter => filter.key === filterKey)
  const {jobs: {periods}} = useTranslation()

  return <div className={styles()}>
    <div className={styles('items')}>
      <JobsfiltersSettings />
      {search.filters.map(filter =>
        <JobsfiltersItem
          key={filter.key}
          selected={filter.key === filterKey && open}
          onSelect={() => {
            setFilterKey(filter.key)
            setOpen(!open || filterKey !== filter.key)
          }}
          filter={filter}
        />
      )}
    </div>
    <div className={styles('tags')}>
      <AnimateHeight height={open ? 'auto' : 0} animateOpacity={true}>
        {filter && <FilterTag
            key={filter.key}
            locked={filter.selectedTerms.length == 0}
            inactive={filter.selectedTerms.length > 0}
            onClick={() => updateSearch(filter.enableAll())}
        >
          All
        </FilterTag>}
        {(filter?.relevantTerms || []).map(term =>
            <FilterTag
               key={term.id}
               inactive={!term.selected}
               onClick={() => updateSearch(term.toggle())}
               passive={term instanceof TaxonomyTermModel && term.hasSelectedParent}
            >
              {term instanceof TaxonomyTermModel && term.name}
              {term instanceof DatePostedTermModel && (periods[term.key] || term.key)}
            </FilterTag>
        )}
      </AnimateHeight>
    </div>
    <hr className={styles('line')}/>
  </div>
}
export const JobsfiltersSettings: React.FC = () => {
  const {search, updateSearch} = useContext(JobsContext)

  return <div
    onClick={() => updateSearch(search.clear())}
    className={styles.settings().is({active: search.numberOfSelectedTerms > 0})}
  >
    <Icon icon="Settings" />
    <div className={styles.settings('notice')}>
      <div className={styles.settings('notice-number')}>
        {search.numberOfSelectedTerms}
      </div>
      <div className={styles.settings('notice-clear')}>
        <Icon icon="Close" />
      </div>
    </div>
  </div>
}

export const JobsfiltersItem: React.FC<{
  filter: JobFilterModel
  selected: boolean
  onSelect: () => void
}> = ({ filter, selected, onSelect}) => {
  const {jobs: {filters}} = useTranslation()
  const active = filter.selectedTerms.length > 0

  return <div onClick={onSelect} className={styles.item().is({active, selected})}>
    {filters[filter.key]}
  </div>
}

//TODO: remove this if regular settings behaviour is OK
export const JobsfiltersSettingsAlt: React.FC = () => {
  const { search, updateSearch } = useContext(JobsContext)

  if(search.numberOfSelectedTerms > 0) {
    return <div onClick={() => updateSearch(search.clear())} className={styles.settings_alt().mod('number')}>
      <div className={styles.settings_alt('clear')} >
        <Icon icon="Close" />
      </div>
      <div className={styles.settings_alt('number')}>
        {search.numberOfSelectedTerms}
      </div>
    </div>
  }

  return <div className={styles.settings_alt()}>
    <Icon icon="Settings" />
  </div>
}
