import './backgroundblock.scss'

import {Column, Grid} from 'layout/grid'
import {Bg} from 'layout/partials/bg'
import {Theme} from 'layout/theme'
import React from 'react'
import {ImageType, WysiwygType} from 'types/basictypes'
import {styler} from 'util/style'

const styles = styler('backgroundblock')

export type BackgroundBlockType = {
	background?: ImageType
	overlay?: number
	text_left: WysiwygType
	text_right: WysiwygType
	pageType?: string
	dark: boolean
}

export const BackgroundBlock: React.FC<BackgroundBlockType> = data => {
	const { background, overlay, text_left, text_right, pageType, dark} = data

	const gradient = !background && !dark
	const darkText = background?.meta?.useDarkColor

	return (
		<div className={styles().mod({ pageType, dark: dark, gradient: gradient, darkText: darkText })}>
			<Bg image={background} overlay={overlay || 50} />
			<Theme.Container>
				<div className={styles('content')}>
					<Grid columns={1} xs={1} s={2} m={2} l={2}>
						<Column>
							<div className={styles('content-left')}>
								{text_left && (
									<Theme.Wysiwyg mod={pageType || 'inherit'}>
										{text_left}
									</Theme.Wysiwyg>
								)}
							</div>
						</Column>
						<Column>
							<div className={styles('content-right')}>
								{text_right && (
									<Theme.Wysiwyg mod={pageType || 'inherit'}>
										{text_right}
									</Theme.Wysiwyg>
								)}
							</div>
						</Column>
					</Grid>
				</div>
			</Theme.Container>
		</div>
	)
}
