import { BlockType } from 'blocks/block'
import { PreviewType } from 'layout/preview/previewtype'
import { RelatedjobItemType } from 'blocks/relatedjobs/relatedjobs'
import React, { createContext } from 'react'
import { LinkType } from 'types/basictypes'

export type BlocksContextData = {
  parents: Array<LinkType>,
  pageTitle: string,
  url: string,
  nodeID: number,
  pageType: 'page' | 'article' | 'press' | 'research_project' | 'case' | 'job' | 'event' | '404',
  relatedContent: Array<PreviewType>
  relatedJobs: Array<RelatedjobItemType>
  blocks: Array<BlockType>
}

export const BlocksContext = createContext<BlocksContextData>(null)
